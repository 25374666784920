import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    flag: false,
};

export const accountNotesSlice = createSlice({
    name: "accountNotes",
    initialState,
    reducers: {
        changeFlag: (state, action) => {
            state.flag = action.payload;
        },
    },
});

export const { changeFlag } = accountNotesSlice.actions;

export default accountNotesSlice.reducer;
