import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    newAccounts: [],
    noOrders: [],
    incomplete: [],
    imports: [],
    rows: [],
    columns: [],
    currentRegion: "",
    importRegion: "",
};

export const leadSlice = createSlice({
    name: "leads",
    initialState,
    reducers: {
        isLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        leadsArrays: (state, action) => {
            state.newAccounts = action.payload.isNew;
            state.noOrders = action.payload.noOrders;
            state.incomplete = action.payload.incomplete;
        },
        currentImports: (state, action) => {
            const isEqual = (a, b) => {
                // If length is not equal
                if (a.length !== b.length) return "False";
                else {
                    // Comapring each element of array
                    for (let i = 0; i < a.length; i++) if (a[i] !== b[i]) return "False";
                    return "True";
                }
            };
            let validate = [
                "First Name",
                "Last Name",
                "Email",
                "Phone",
                "Website",
                "Practitioner Type",
                "Street Address",
                "City",
                "State",
                "Zip",
                "Country",
            ];
            let header = action.payload[0].data;
            if (isEqual(validate, header) === "True") {
                state.isLoading = true;
                state.imports = action.payload;
                let newRows = [];

                action.payload.forEach((element, key) => {
                    if (element.data[0] === "First Name") {
                        let result = element.data.map((item) => {
                            let fieldItem =
                                item.split(" ").length > 1 ? item.replace(" ", "_").toLowerCase() : item.toLowerCase();
                            return {
                                field: fieldItem,
                                headerName: item,
                            };
                        });
                        state.columns = result;
                    }
                    if (element.data[0] !== "First Name") {
                        let returnObject = { id: key };
                        element.data.forEach((item, index) => {
                            returnObject[state.columns[index].field] = item;
                        });
                        newRows.push(returnObject);
                    }
                });

                state.rows = newRows;
                state.isLoading = false;
            }
        },
        clearImports: (state, action) => {
            state.imports = [];
            state.importRegion = "";
        },
        region: (state, action) => {
            state.currentRegion = action.payload;
        },
        salesRegion: (state, action) => {
            state.importRegion = action.payload;
        },
    },
});

export const { isLoading, leadsArrays, currentImports, clearImports, region, salesRegion } = leadSlice.actions;

export default leadSlice.reducer;
