import React from "react";
import { useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const TopBar = styled("div")(({ theme, open }) => ({
    background: `linear-gradient(to right, ${theme.palette.common.blue_dark} 100%, ${theme.palette.common.white})`,
    padding: "10px 12px 4px",
    height: 64,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "center",
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 2,
}));

const TopBarHeader = styled("h3")(({ theme, open }) => ({
    color: "#FFF",
    width: "100%",
    margin: "4px auto",
    fontSize: "1.2rem",
    fontWeight: 400,
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
        fontSize: 16,
    },
}));

const BackArrow = styled(ArrowBackIosIcon)(({ theme, open }) => ({
    margin: 0,
    color: theme.palette.common.white,
    cursor: "pointer",
}));

export default function TopBarInNav(props) {
    const globalState = useSelector((state) => state.global);

    const headerText = props.data?.routes?.title
        ? props.data?.routes?.title
        : props.data?.barData?.pageData?.title
        ? props.data?.barData?.pageData?.title
        : props.title;

    return (
        <TopBar>
            {globalState.isMobile && <BackArrow onClick={() => props.backToMain()}></BackArrow>}
            <TopBarHeader>{headerText}</TopBarHeader>
        </TopBar>
    );
}
