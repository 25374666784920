import React from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import TopBarInNav from "../../../common/SideBar/TopBarInNav";
import { useSelector, useDispatch } from "react-redux";
import { toggleHideOptionalNav } from "../../../common/globalSlice/globalSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionSummary, styled, Grid } from "@mui/material";
import {
    NavArea,
    NavEl,
    AccordianEl,
    AccordionDetailsEl,
    ListItemContainer,
    ListItemEl,
    ListItemTextEl,
} from "../../../common/SideBar/SideBar";

export const CustomAccordianEl = styled(Accordion)(({ theme }) => ({
    "&&": {
        margin: "0",
        paddingBottom: theme.spacing(2),
        boxShadow: "none",
    },
}));
export const CustomAccordionSummaryEl = styled(AccordionSummary)(({ theme }) => ({
    "&&": {
        color: theme.palette.text.grey,
        fontWeight: 700,
        margin: 0,
        padding: theme.spacing(1.5, 0.5, 1, 0.25),
        "& div": {
            margin: theme.spacing(0),
            padding: theme.spacing(0.5, 0),
        },
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(2, 1, 0.5),
        },
    },
}));

export const CustomAccordionSummaryDiv = styled("div")(({ theme }) => ({
    paddingLeft: "10px",
    color: theme.palette.text.grey,
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
    transition: theme.transitions.create(["background-color", "color"], {
        easing: theme.transitions.easing.easeOut,
        duration: ".4s",
    }),
}));
export const CustomGrid = styled(Grid, { shouldForwardProp: (prop) => prop !== "current" })(({ theme, current }) => ({
    ...(current && {
        fontWeight: 300,
        backgroundColor: `${theme.palette.common.blue}0d`,
    }),
    alignItems: "center",
    transition: theme.transitions.create(["background-color", "color"], {
        easing: theme.transitions.easing.easeOut,
        duration: ".4s",
    }),
}));
export const CustomGridIcon = styled(Grid)(({ theme }) => ({
    height: "67px",
    padding: "7.5px",
    "&:hover": {
        backgroundColor: theme.palette.common.blue,
        "& *": {
            transition: theme.transitions.create(["background-color", "color"], {
                easing: theme.transitions.easing.easeOut,
                duration: ".4s",
            }),
            color: theme.palette.common.white,
        },
    },
}));
export const CustomGridInner = styled(Grid)(({ theme }) => ({
    height: "100%",
    padding: theme.spacing(3, 0),
    cursor: "pointer",
    "&:hover": {
        backgroundColor: theme.palette.common.blue,
        "& *": {
            transition: theme.transitions.create(["background-color", "color"], {
                easing: theme.transitions.easing.easeOut,
                duration: ".4s",
            }),
            color: theme.palette.common.white,
        },
    },
}));

export default function SideBar(props) {
    const dispatch = useDispatch();
    const globalState = useSelector((state) => state.global);
    const location = useLocation();
    const history = useHistory();

    const checkForOptionalLinks = (section) => {
        if (Object.keys(section).includes("isOptional")) {
            return !globalState.nav.hideOptionalLinks ? true : false;
        } else {
            return true;
        }
    };

    const path = location.pathname.split("/");
    const endPath = path[path.length - 1];

    const resetOptionalLinks = (e) => {
        if (e?.currentTarget?.href && e.currentTarget.href.replace(/[^-]/g, "").length <= 3) {
            dispatch(toggleHideOptionalNav(true));
        }
        props.onSecondaryNavClick();
    };

    const checkURL = (e) => {
        if (e.currentTarget.textContent.toLowerCase() === "courses") {
            history.push("/learningcenter/courses");
        }
        return null;
    };

    return (
        <NavArea routes={props.routes} key={globalState.nav.hideOptionalLinks}>
            <NavEl>
                <TopBarInNav data={props} backToMain={props.backToMain}></TopBarInNav>
                {props.routes?.sideBar?.sections &&
                    props.routes?.sideBar?.sections?.length >= 1 &&
                    props.routes.sideBar.sections.map((element, key) => {
                        if (checkForOptionalLinks(element) && element.links.length > 0) {
                            const linkEnd = element.isOptional ? `/` + endPath : ``;
                            if (element.title === "null") {
                                return (
                                    <AccordianEl defaultExpanded={true} square={true} key={key + element.title}>
                                        <AccordionDetailsEl>
                                            {element.links?.map((route, k) => {
                                                return (
                                                    <ListItemContainer
                                                        key={k + key}
                                                        onClick={(e) => resetOptionalLinks(e)}
                                                        to={{
                                                            pathname: route.path + linkEnd,
                                                            hash: route.hash,
                                                        }}
                                                        current={
                                                            route.path === location.pathname ||
                                                            route?.other_paths?.includes(location.pathname) ||
                                                            location.pathname
                                                                .toLowerCase()
                                                                .includes(route?.keyName?.toLowerCase())
                                                        }
                                                    >
                                                        <ListItemEl open={props.open} button>
                                                            <ListItemTextEl>{route.title}</ListItemTextEl>
                                                        </ListItemEl>
                                                    </ListItemContainer>
                                                );
                                            })}
                                        </AccordionDetailsEl>
                                    </AccordianEl>
                                );
                            } else {
                                return (
                                    <AccordianEl
                                        defaultExpanded={false}
                                        square={true}
                                        key={key + element.title}
                                        sx={{
                                            paddingBottom: "0px !important",
                                        }}
                                    >
                                        <CustomGrid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            current={location.pathname.includes("courses")}
                                        >
                                            <CustomGridInner onClick={(e) => checkURL(e)} item xs={10}>
                                                <CustomAccordionSummaryDiv>{element.title}</CustomAccordionSummaryDiv>
                                            </CustomGridInner>

                                            <CustomGridIcon item xs={2}>
                                                <CustomAccordionSummaryEl
                                                    aria-controls="panel1a-content"
                                                    id={element.title}
                                                >
                                                    <ExpandMoreIcon></ExpandMoreIcon>
                                                </CustomAccordionSummaryEl>
                                            </CustomGridIcon>
                                        </CustomGrid>
                                        <AccordionDetailsEl>
                                            {element.links?.map((route, k) => {
                                                return (
                                                    <ListItemContainer
                                                        key={k + key}
                                                        onClick={(e) => resetOptionalLinks(e)}
                                                        to={{
                                                            pathname: route.path + linkEnd,
                                                            hash: route.hash,
                                                        }}
                                                        current={
                                                            route.path === location.pathname ||
                                                            route?.other_paths?.includes(location.pathname) ||
                                                            location.pathname
                                                                .toLowerCase()
                                                                .includes(route?.keyName?.toLowerCase())
                                                        }
                                                    >
                                                        <ListItemEl open={props.open} button>
                                                            <ListItemTextEl>{route.title}</ListItemTextEl>
                                                        </ListItemEl>
                                                    </ListItemContainer>
                                                );
                                            })}
                                        </AccordionDetailsEl>
                                    </AccordianEl>
                                );
                            }
                        } else {
                            return null;
                        }
                    })}
            </NavEl>
        </NavArea>
    );
}
