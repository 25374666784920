import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import TopBarInNav from "./../SideBar/TopBarInNav";
import { useSelector, useDispatch } from "react-redux";
import { toggleHideOptionalNav } from "./../../common/globalSlice/globalSlice";

import { styled } from "@mui/material/styles";
import { Accordion, AccordionSummary, AccordionDetails, ListItem, ListItemText, Icon } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const NavArea = styled("div")(({ theme }) => ({
    "&&": {
        height: "100%",
        overflow: "hidden",
        backgroundColor: theme.white,
        paddingTop: theme.spacing(0),
        [theme.breakpoints.down("md")]: {
            paddingTop: 0,
        },
    },
}));

export const NavEl = styled("nav")(({ theme }) => ({
    width: "100%",
    maxHeight: "calc(100vh - 84px)",
    overflowX: "scroll",
    [theme.breakpoints.down("md")]: {
        maxHeight: "calc(100vh - 4px)",
    },
}));

export const AccordianEl = styled(Accordion)(({ theme }) => ({
    "&&": {
        margin: "0",
        paddingBottom: theme.spacing(2),
        boxShadow: "none",
    },
}));

export const AccordionSummaryEl = styled(AccordionSummary)(({ theme }) => ({
    "&&": {
        color: theme.palette.text.grey_dark,
        fontWeight: 700,
        margin: 0,
        minHeight: 34,
        padding: theme.spacing(2.5, 0.5, 1, 0.25),
        "& div": {
            margin: theme.spacing(0),
            padding: theme.spacing(0.5, 0),
        },
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(2, 1, 0.5),
        },
    },
}));

export const AccordionSummarySpan = styled("span")(({ theme }) => ({
    alignSelf: "center",
    whiteSpace: "normal",
}));

export const AccordionDetailsEl = styled(AccordionDetails)(({ theme }) => ({
    "&&": {
        padding: theme.spacing(0, 0, 1),
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0),
        },
    },
}));

export const ListItemContainer = styled(NavLink, { shouldForwardProp: (prop) => prop !== "current" })(
    ({ theme, current }) => ({
        ...(current && {
            fontWeight: 300,
            backgroundColor: `${theme.palette.common.blue}0d`,
        }),
    }),
);

export const ListItemEl = styled(ListItem, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    "&&": {
        padding: theme.spacing(1.5),
        marginBottom: 0,
        cursor: "pointer",
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        color: theme.palette.text.grey_dark,
        [theme.breakpoints.down("md")]: {
            margin: theme.spacing(0),
            padding: theme.spacing(1),
        },
        "&:hover": {
            backgroundColor: theme.palette.common.blue,
            color: theme.palette.common.white,
        },
    },
}));

export const navIcon = styled(Icon)(({ theme }) => ({
    marginRight: theme.spacing(1),
    width: 24,
    display: "none",
    [theme.breakpoints.down("md")]: {
        display: "block",
    },
}));

export const ListItemTextEl = styled(ListItemText)(({ theme }) => ({
    "&&": {
        lineHeight: "1rem",
        marginTop: 0,
        marginBottom: 0,
        whiteSpace: "pre-wrap",
        [theme.breakpoints.down("md")]: {
            "& > span": {
                fontSize: 15,
            },
        },
    },
}));

export default function SideBar(props) {
    const dispatch = useDispatch();
    const globalState = useSelector((state) => state.global);
    const auth = useSelector((state) => state.auth);
    //For Top Bar that gives news and what not
    const location = useLocation();

    const checkForOptionalLinks = (section) => {
        if (Object.keys(section).includes("isOptional")) {
            return !globalState.nav.hideOptionalLinks ? true : false;
        } else {
            return true;
        }
    };

    const path = location.pathname.split("/");
    const endPath = path[path.length - 1];

    const resetOptionalLinks = (e) => {
        if (e?.currentTarget?.href && e.currentTarget.href.replace(/[^-]/g, "").length <= 3) {
            dispatch(toggleHideOptionalNav(true));
        }
        props.onSecondaryNavClick();
    };

    if (auth.user?.access) {
        return (
            <NavArea routes={props.routes} key={globalState.nav.hideOptionalLinks}>
                <NavEl>
                    <TopBarInNav data={props} backToMain={props.backToMain}></TopBarInNav>
                    {props.routes?.sideBar?.sections &&
                        props.routes?.sideBar?.sections?.length >= 1 &&
                        props.routes.sideBar.sections.map((element, key) => {
                            if (checkForOptionalLinks(element) && element.links.length > 0) {
                                const linkEnd = element.isOptional ? `/` + endPath : ``;
                                return (
                                    <AccordianEl defaultExpanded={true} square={true} key={key + element.title}>
                                        <AccordionSummaryEl aria-controls="panel1a-content" id={element.title}>
                                            <ExpandMoreIcon></ExpandMoreIcon>
                                            <AccordionSummarySpan>{element.title}</AccordionSummarySpan>
                                        </AccordionSummaryEl>
                                        <AccordionDetailsEl>
                                            {element.links?.map((route, k) => {
                                                return (
                                                    <ListItemContainer
                                                        key={k + key}
                                                        onClick={(e) => resetOptionalLinks(e)}
                                                        to={{
                                                            pathname: route.path + linkEnd,
                                                            hash: route.hash,
                                                        }}
                                                        data-nav
                                                        current={route.path === location.pathname}
                                                    >
                                                        <ListItemEl open={props.open} button>
                                                            <ListItemTextEl>{route.title}</ListItemTextEl>
                                                        </ListItemEl>
                                                    </ListItemContainer>
                                                );
                                            })}
                                        </AccordionDetailsEl>
                                    </AccordianEl>
                                );
                            } else {
                                return null;
                            }
                        })}
                </NavEl>
            </NavArea>
        );
    } else {
        return null;
    }
}
