import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    changeFlag: true,
    activeView: 1,
    activeSection: null,
    activeQuestion: null,
    isPublished: null,
    assessmentFlag: 1,
};

export const toolsAdminSlice = createSlice({
    name: "toolsAdmin",
    initialState,
    reducers: {
        changeFlagAction: (state, action) => {
            state.changeFlag = action.payload;
        },
        viewStateAction: (state, action) => {
            state.activeView = action.payload.activeView;
            state.activeSection = action.payload.activeSection;
            state.activeQuestion = action.payload.activeQuestion;
        },
        isPublishedAction: (state, action) => {
            state.isPublished = action.payload;
        },
        assessmentFlagAction: (state, action) => {
            state.assessmentFlag = action.payload;
        },
    },
});

export const { changeFlagAction, viewStateAction, isPublishedAction, assessmentFlagAction } = toolsAdminSlice.actions;

export default toolsAdminSlice.reducer;
