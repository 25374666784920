import { useEffect, useState } from "react";

// dev option
const printPreview = false;
// set to true when viewing what the site looks like
// when its in print mode, resize ur viewport, and enable
// print media query in devtools :) :) :) :) :) :) :)
// US Letter 1" -> 720px x 960px

export const usePrintCheck = () => {
    const [printing, setPrinting] = useState(false);

    useEffect(() => {
        const before = window.addEventListener("beforeprint", (e) => {
            setPrinting(true);
        });
        const after = window.addEventListener("afterprint", (e) => {
            setPrinting(false);
        });
        return () => {
            window.removeEventListener("beforeprint", before);
            window.removeEventListener("afterprint", after);
        };
    });

    return printPreview || printing;
};
