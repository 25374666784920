import React from "react";

import "emoji-mart/css/emoji-mart.css";
import { Emoji } from "emoji-mart";

import { styled } from "@mui/material/styles";

import { convertTimestamp } from "../../common/Functions/utilities";
import { communityStore } from "./communitySlice";

import * as globalUtils from "../../common/Functions/utilities";

/**
 * @description - Checks if user should be allowed to post messages in Channel
 * @author Mike Roberts
 * @param {Array} conversations - list of conversations to check permissions for
 * @param {Object} selected - currently selected channel object
 * @param {Boolean} isAdmin - if user is an Admin
 * @returns {Boolean} - True if user should see message bar, false if not
 */
export const checkRestrictMessageSending = (conversations, selected, isAdmin) => {
    //Find conversation currently selected
    if (isAdmin) return true;
    const foundConversation = conversations.filter((c) => {
        return c.id === selected.conversation_id;
    })[0];
    if (!isAdmin && foundConversation?.permissions?.restrictPostingToAdmin) return false;
    return true;
};

/**
 * @description - Checks if user input should be allowed in Message Bar
 * @author Mike Roberts
 * @param {Array} conversations - list of conversations to check permissions for
 * @param {Object} selected - currently selected channel object
 * @param {Boolean} isAdmin - if user is an Admin
 * @returns {Boolean} - True if user should see message bar, false if not
 */
export const checkShowMessageBar = (conversations, selected, isAdmin) => {
    if (!checkRestrictMessageSending(conversations, selected, isAdmin)) return false;
    //Hide message input bar for System and Admin sections - it's not needed for these sections
    if (["system", "admin"].includes(selected.messageType)) return false;
    //Always show message input bar for directe messages
    if (selected.section === "direct_messages") return true;
    //Default to always showing the bar
    return true;
};

/**
 * @description - Reads the file prefix on the end of a URL string
 * @author Mike Roberts
 * @param {String}
 * @returns {String} - String of the type of file it is, changes it to plain english
 */
export const checkLinkTypes = (string) => {
    const lowerString = string.toLowerCase();
    const linkTypes = [
        { image: /(\.jpg|\.jpeg|\.gif|\.png|\.tiff)$/.test(lowerString) },
        { pdf: /(\.pdf)$/.test(lowerString) },
        { audio: /(\.aif|\.midi|\.mp3|\.mpa|\.wav|\.wma)$/.test(lowerString) },
        { spreadsheet: /(\.xls|\.xlsm|\.xlsx)$/.test(lowerString) },
        { "video-url": /youtube|vimeo|dailymotion/.test(lowerString) },
        { "video-file": /(\.avi|\.h264|\.m4v|\.mkv|\.mov|\.mp4|\.mpg|\.mpeg|\.wmv)$/.test(lowerString) },
    ];
    const linkMatches = linkTypes.filter((link) => Object.values(link).includes(true));
    if (linkMatches.length === 0) return "general";
    return Object.keys(linkMatches[0])[0];
};

/**
 * @description -
 * @author Mike Roberts
 * @param {Array} messageArr - Array of Messages
 * @returns {Array} -
 */
export const changeMentions = (messageArr) => {
    let mentionsArr = [];
    messageArr.forEach((contents) => {
        if (typeof contents !== "string") return [];
        const mentions = contents.match(/@[A-Za-z0-9._-]*@/g);
        if (!mentions) return [];
        let contentString = contents;
        mentions.forEach((m) => {
            const mention = m;
            if (communityStore.allKnownCommunityUsers) {
                const mentionReplace = mention.replace(/@/g, "");
                if (communityStore.allKnownCommunityUsers[mentionReplace]) {
                    let foundUser = JSON.parse(JSON.stringify(communityStore.allKnownCommunityUsers[mentionReplace]));
                    if (foundUser) {
                        foundUser.mentionName = `@` + foundUser.first_name + `.` + foundUser.last_name;
                        foundUser.mentionUuid = `@` + foundUser.uuid + `@`;
                        contentString = contentString.replaceAll(
                            mention,
                            `@` + foundUser.first_name + `.` + foundUser.last_name,
                        );
                        mentionsArr.push(foundUser);
                    }
                }
            }
        });
    });
    return mentionsArr;
};

/**
 * @description - Search through Array of Strings to find if a specific string exists within it
 * @author Mike Roberts
 * @param {Array} arr - Array of Strings
 * @param {String} searchString - String to search for
 * @returns {Boolean} - True if Array contains String
 */
export const checkArrForString = (arr, searchString) => {
    if (Array.isArray(arr)) {
        const match = arr.find((arrElement) => {
            if (arrElement?.length > 0 && arrElement?.indexOf(searchString) >= 0) return true;
            return null;
        });
        return match?.length > 0 ? true : false;
    }
};

const MessageMention = styled("span")(({ theme }) => ({
    "&&": {
        fontWeight: 600,
        color: theme.palette.common.blue,
    },
}));
/**
 * @description -
 * @author Mike Roberts
 * @param {Object} messageContents - Message Contents
 * @param {String} replace -
 * @param {String} element - Element type to make
 * @param {String} extraData - Message Contents
 * @returns {Array} - Array of links in the message text
 */
export const useStringReplace = (messageContents, replace, element, extraData) => {
    const reactStringReplace = require("react-string-replace");
    if (!reactStringReplace) return [];
    return reactStringReplace(messageContents, replace, (match, i) => {
        if (element === `link`) {
            return (
                <a key={match + i} href={match} target="_blank" rel="noreferrer">
                    {match}
                </a>
            );
        }
        if (element === `mention`) {
            return (
                <MessageMention key={match + i}>
                    {`@` + extraData.first_name + `.` + extraData.last_name}
                </MessageMention>
            );
        }
        if (element === `emoji`) {
            return (
                <span key={match + i}>
                    <Emoji emoji={`:${match}::skin-tone-1:`} size={16} />
                </span>
            );
        }
    });
};

/**
 * @description - Find all links in message that start with https - Used with convertMessageBasic function below
 * @author Mike Roberts
 * @param {String} message - Message Text
 * @returns {Array} - Array of links in the message text
 */
const checkForLinksBasic = (message) => {
    //If no message then return blank array
    if (message?.length <= 0) return [];
    //ADD MORE REGEX, ETC. HERE IF OTHER MATCHES ARE DESIRED
    const links = message.match(/\bhttps?:\/\/\S+/gi);
    //If no links in message then return blank array
    if (!links) return [];
    //Make new link out of links array
    const link = new URL(links[0]);
    return [link.href];
};

/**
 * @description - Converts message to a most basic message format, used with Pinned Messages, etc.
 * @author Mike Roberts
 * @param {String} message - Message Text
 * @returns {Array} - Array of links in the message text
 */
export const convertMessageBasic = (message, users) => {
    const reactStringReplace = require("react-string-replace");
    if (message?.length > 0 && reactStringReplace) {
        let messageContents = [message];
        //LOOK FOR EMOJIS IN MESSAGE
        if (checkArrForString(messageContents, "::")) {
            messageContents = reactStringReplace(messageContents, /::\s*(.*?)\s*::/g, (match, i) => {
                return (
                    <span key={match + i}>
                        <Emoji emoji={`:${match}::skin-tone-1:`} size={16} />
                    </span>
                );
            });
        }
        //LOOK FOR LINKS IN MESSAGE
        const links = checkForLinksBasic(message);
        if (links && links.length > 0) {
            links.forEach((link) => {
                messageContents = useStringReplace(messageContents, link, `link`);
            });
        }
        //LOOK FOR @ MENTIONS IN MESSAGE
        if (checkArrForString(messageContents, "@")) {
            //AT LEAST 1 @ SYMBOLS IN MESSAGE
            const mentions = changeMentions(messageContents, users);
            mentions.forEach((mention) => {
                messageContents = useStringReplace(messageContents, `@${mention.uuid}@`, `mention`, mention);
            });
        }
        const newMessage = <div>{messageContents}</div>;
        return newMessage;
    }
};

/**
 * @description - Returns string of a Users company name and location, or just location if company name isn't available
 * @author Mike Roberts
 * @param {Object} userObj - Object with User details
 * @returns {String} - String of Company/Location
 */
export const changeAbbreviation = (userObj) => {
    let string = ``;
    if (!userObj.company && !userObj.city) return string;
    if (userObj.company?.length > 0) {
        string += `${userObj.company} - `;
    }
    if (userObj.city?.length > 0 && userObj.state?.length > 0) {
        string += `${userObj.city}, ${userObj.state}`;
    }
    return string;
};

/**
 * @description - Go to a message based off of provided conditions
 * @author Mike Roberts
 * @param {String} type - Type of transfer ("old", "new", etc.)
 * @param {Array} conversationsByUser - Conversations that the User has had/opened
 * @param {Object} messageData - Object of message details
 * @param dispatch - Dispatch to State Function
 * @param updateNavigateToThisMessage - Dispatch to State Function
 * @returns {set} - Updates Community State with message to navigate to
 */
export const goToMessageWithTransfers = (
    type,
    conversationsByUser,
    messageData,
    dispatch,
    updateNavigateToThisMessage,
) => {
    if (!messageData.transfers || messageData.transfers.length === 0) return null;
    const transferData = messageData.transfers[0];
    let message = {};
    if (type === "old") {
        message = {
            ...message,
            conversation_id: transferData.conversation_id,
            id: transferData.parent_id,
            dateObj: convertTimestamp(transferData.ts),
            showExternal: false,
        };
    }
    if (type === "new") {
        message = {
            ...message,
            conversation_id: transferData.conversation_id,
            id: transferData.parent_id,
            dateObj: convertTimestamp(transferData.ts),
            showExternal: false,
        };
    }
    const foundChannel = conversationsByUser.filter((convo) => {
        return convo.id === message.conversation_id;
    });
    if (!foundChannel || foundChannel.length === 0) return null;
    message = {
        ...message,
        messageType: "message",
        channel: foundChannel[0].topic,
    };
    dispatch(updateNavigateToThisMessage(message));
};

/**
 * @description Loops through messages to returh all users/adds them to allKnownCommunityUsers Object
 * @param  {Object} data Messages Object with User Info OR Array of Users
 * @author Mike Roberts
 * @returns {set} Sets Object of All Known Users Throughout Community
 */
export const setAllInitialUsers = (data, currentUsers, dispatch, updateAllKnownCommunityUsers) => {
    let currentUsersObj = JSON.parse(JSON.stringify(currentUsers));
    //IF IS MESSAGES OBJECT
    if (data.messages) {
        data.messages.rows.forEach(function (message) {
            if (!currentUsersObj[message.user.uuid]) {
                currentUsersObj[message.user.uuid] = {};
                currentUsersObj[message.user.uuid] = message.user;
                //IF MESSAGE HAS TAGGED ATTRIBUTE (NOT USED OFTEN)
                if (message.tagged && message.tagged.length > 0) {
                    message.tagged.forEach((tag) => {
                        if (!currentUsersObj[tag.tagged_user.uuid]) {
                            currentUsersObj[tag.tagged_user.uuid] = tag.tagged_user;
                        }
                    });
                }
            }
        });
        //IF IS ARRAY OF USERS
    } else {
        data.forEach(function (d) {
            if (!currentUsersObj[d.user_id] && d.user_id !== 0) {
                currentUsersObj[d.user_id] = {};
                currentUsersObj[d.user_id] = d.user;
            }
        });
    }
    dispatch(
        updateAllKnownCommunityUsers({
            users: currentUsersObj,
        }),
    );
};

/**
 * @description ADDS ONE USER AT A TIME TO allKnownCommunityUsers OBJECT - USEFUL FOR SEARCH USERS CALL, ETC.
 * @param  {Object} obj Users Object
 * @author Mike Roberts
 * @returns {set} Adds Individual User To Object of All Known Users Throughout Community
 */
export const updateCurrentUsers = (obj, allKnownCommunityUsers, dispatch, updateAllKnownCommunityUsers) => {
    //IF USER DOESNT EXIST YET IN CURRENT USERS OBJECT
    if (!allKnownCommunityUsers[obj.user.uuid]) {
        dispatch(
            updateAllKnownCommunityUsers({
                users: obj,
            }),
        );
    }
};

/**
 * @description TAKES ARRAY OF USERS AND ADDS THEM TO THE COMMUNITY STATE OBJECTS LIST OF EXISTING USERS
 * @param  {Object} arr
 * @author Mike Roberts
 * @returns {set} - sets state with updated object of known community users
 */
export const updateCurrentUsersFromArr = (arr, allKnownCommunityUsers, dispatch, updateAllKnownCommunityUsers) => {
    let currentUsersObj = JSON.parse(JSON.stringify(allKnownCommunityUsers));
    arr.forEach((u) => {
        //IF USER DOESNT EXIST YET IN CURRENT USERS OBJECT
        if (!allKnownCommunityUsers[u.user.uuid]) {
            currentUsersObj[u.user.uuid] = {};
            currentUsersObj[u.user.uuid] = u.user;
        }
    });
    dispatch(
        updateAllKnownCommunityUsers({
            users: currentUsersObj,
        }),
    );
};

/**
 * @description TAKES A NEW TIMESTRING AND CHECKS IF IT'S BEFORE OF AFTER ANOTHER TIMESTRING
 * @param  {Object} newTime - New Timestring
 * @param  {Object} baseTime - Timestring To Check Against
 * @author Mike Roberts
 * @returns {Boolean} - true if new timestring is Before base timestring
 */
export const findIfIsBeforeOrAfter = (newTime, baseTime) => {
    if (newTime) {
        const lastActiveDate = new Date(`${newTime}`);
        const lastActiveNumber = lastActiveDate.getTime() / 1000;
        if (baseTime && lastActiveNumber && baseTime >= lastActiveNumber) {
            return true;
        }
    }
    return false;
};

/**
 * @description
 * @param  {Array} messageArr - Array of Messages
 * @param  {Object} parentMessage
 * @param  {Object} userData - Object of current user data
 * @author Mike Roberts
 * @returns {Object} - Object of messages sorted by date - added time details to each message too
 */
export const sortChannelMessages = (messageArr, parentMessage, userData) => {
    //LOOPS THROUGH ARRAY OF UNFORMATTED MESSAGES AND GIVES A USEABLE OBJECT WITH APPROPRIATE MESSAGES
    const newMessageObj = {};
    const lastActive = userData?.user_metadata?.last_active;
    if (!messageArr) return false;
    //IF A PARENT MESSAGE IS PRESENT, INDICATES THREAD, THIS ADDS PARENT MESSAGE TO OBJECT SO IT SHOWS AS FIRST MESSAGE
    if (parentMessage) messageArr.push(parentMessage);
    //LOOP THROUGH UNFORMATTED MESSAGES
    messageArr.forEach((oldMessage) => {
        //Make new message object so we can modify it
        let message = JSON.parse(JSON.stringify(oldMessage));
        //If no timestamp in message then skip
        if (!message.ts) return false;
        //Get message time and convert it into a date object
        const time = message.ts ? message.ts : message.time;
        const convertStamp = globalUtils.convertTimestamp(time);
        //Find out if this message is before or after the users last active time, shows indicator before message if it's the last active message, so user knows where to pick up reading
        if (lastActive) {
            convertStamp.isBeforeLastActive = findIfIsBeforeOrAfter(lastActive, convertStamp.timestamp);
        }
        message.dateObj = convertStamp;
        const dateName = `${convertStamp.year}${convertStamp.month}${convertStamp.date}`;
        //Add formatted message into appropritae date array for sorting
        if (dateName) {
            if (!newMessageObj[dateName]) {
                //If date doesn't exist, create property in Object and push message into it
                newMessageObj[dateName] = [];
                newMessageObj[dateName].push(message);
            } else {
                //If date does exist, push message into it
                newMessageObj[dateName].push(message);
            }
        }
    });
    //Sort each date by timestamp
    for (let d in newMessageObj) {
        const day = newMessageObj[d];
        day.sort(function (a, b) {
            return a.dateObj.timestamp - b.dateObj.timestamp;
        });
    }
    return newMessageObj;
};

/**
 * @description Formats names to make list of user readable names
 * @param  {Array} data Users Array
 * @author Mike Roberts
 * @returns {string} Cleaned name(s), or default fallback user name
 */
export const combineUserNames = (data) => {
    let nameString = ``;
    const defaultUserName = `CellCore User`;
    //If no data, returns default user name
    if (!data) return defaultUserName;
    data.forEach((user, index) => {
        if (user.user) {
            const userName = user.user?.first_name + ` ` + user.user?.last_name;
            const commaCheck = index >= 1 ? `, ` : ``;
            nameString += commaCheck + userName;
        }
    });
    return nameString.length > 0 ? nameString : defaultUserName;
};

/**
 * @description Shows and then hides a snackbar on the bottom of the screen
 * @author Mike Roberts
 * @param {*} message Text of the message
 * @param {*} severity Severity of message - success, error, info, etc.
 * @param {*} time Time until snackbar disappears
 */
export const changeAndShowSnackBar = (snackbarOptions, dispatch, changeSnackBar) => {
    dispatch(
        changeSnackBar({
            severity: snackbarOptions.severity,
            message: snackbarOptions.message,
            show: true,
        }),
    );
    setTimeout(
        () => {
            dispatch(
                changeSnackBar({
                    severity: `info`,
                    message: ``,
                    show: false,
                }),
            );
        },
        snackbarOptions.hasOwnProperty(`time`) ? snackbarOptions.time : 6000,
    );
};
