import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    change: {
        section: false,
        topic: false,
        module: false,
        article: false,
        resource: false,
    },
    deletedFlag: false,
    totalSections: 0,
    totalTopics: 0,
    totalModules: 0,
    totalResources: 0,
    totals: {
        sections: 0,
        topics: 0,
        modules: 0,
        articles: 0,
        resources: 0,
    },
    sections: [],
    ids: {
        sectionId: null,
        topicId: null,
        moduleId: null,
        articleId: null,
        resourceId: null,
    },
    currentView: "module",
    selected: 0,
    currentSection: {},
    currentTopic: {
        title: "",
        short_desc: "",
        long_desc: "",
        imagePreview: "",
    },
    currentModule: {
        title: "",
        desc: "",
        imagePreview: "",
    },
    currentResource: {},
    isLoading: false,
    adminRoutes: [],
    changedFlag: false,
    learnMore: "",
};

export const allSections = createAsyncThunk("learningAdmin/fetchSections", async () => {
    const query = `
                query {
                    sections(page: 0, size: 50 ) {
                        count
                        rows{
                        id
                        title
                        columns
                        order
                        ts_created
                        }
                        totalPages
                        currentPage
                    }
                }
            `;

    let getSections = await fetch(`https://cellcore-learning-center.azurewebsites.net/graphql`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            query: query,
        }),
    })
        .then((res) => res.json())
        .then((result) => {
            return result;
        })
        .catch((error) => console.error(error));

    if (getSections.data) {
        return getSections.data.sections;
    }
});

export const learningAdminSlice = createSlice({
    name: "learningAdmin",
    initialState,
    reducers: {
        newSection: (state, action) => {},
        setCurrentSection: (state, action) => {
            state.currentSection = action.payload;
        },
        currentTopic: (state, action) => {
            state.currentTopic = action.payload;
        },
        currentModule: (state, action) => {
            state.currentModule = action.payload;
        },
        currentResource: (state, action) => {
            state.currentResource = action.payload;
        },
        modulesNumber: (state, action) => {
            state.totalModules = action.payload;
        },
        learningCenterAdminRoutes: (state, action) => {
            state.adminRoutes = action.payload;
        },
        change: (state, action) => {
            state.changedFlag = action.payload;
        },
        ids: (state, action) => {
            state.ids = action.payload;
        },
        selected: (state, action) => {
            state.selected = action.payload;
        },
        view: (state, action) => {
            state.currentView = action.payload;
        },
        totalCount: (state, action) => {
            state.totals = action.payload;
        },
        deletedFlag: (state, action) => {
            state.deletedFlag = action.payload;
        },
        changeAll: (state, action) => {
            state.change = action.payload;
        },
        changeLearnMore: (state, action) => {
            state.learnMore = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(allSections.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(allSections.fulfilled, (state, action) => {
            state.isLoading = false;
            state.sections.push(action.payload.rows);
            state.totalSections = action.payload.count;
            state.totals.sections = action.payload.count;
        });
    },
});

export const {
    setCurrentSection,
    currentTopic,
    currentModule,
    modulesNumber,
    currentResource,
    learningCenterAdminRoutes,
    change,
    ids,
    selected,
    view,
    totalCount,
    deletedFlag,
    changeArticle,
    changeLearnMore,
    changeAll,
} = learningAdminSlice.actions;

export default learningAdminSlice.reducer;

export const createSection = () => (dispatch) => {};
