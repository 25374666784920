import React, { useState, useEffect } from "react";
import GlobalState from "../common/GlobalState";
import { toggleShowDevTools, toggleShowLoader } from "../common/globalSlice/globalSlice";
import { styled } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import GlobalStyle from "../theme/GlobalStyle";
import CustomTheme from "../theme/CustomTheme";
import { useSelector, useDispatch } from "react-redux";
import { storeUser } from "../features/auth/authSlice";
import { changeRoutes } from "../common/utils/RouteSlice";
// import { useAuth0 } from "@auth0/auth0-react";
import { AppRoutes } from "./routes/index.js";
import AppLayout from "../common/AppLayout";
import { AppProvider } from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json";
import { useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import TagManager from "react-gtm-module";
import CircularLoader from "../common/Loading/CircularLoader";
import routes from "./routes/static";

import TopBar from "./MainComponents/TopBar";

import { getCookie } from "../common/Functions/utilities";

import { getUserDataByUUID } from "../common/Functions/getUserData";

const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

const AppWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
});

function App(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const globalRouteData = useSelector((state) => state.routeData);
    const location = useLocation();
    const auth = useSelector((state) => state.auth);
    const query = useQuery();

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState({});
    const [cellcoreUUID, setcellcoreUUID] = useState(null);
    const [showNotMember, setShowNotMember] = useState(false);
    const [publicRoute, setPublicRoute] = useState(false);

    useEffect(() => {
        const cellcore_logged_in = getCookie("cellcore_logged_in");
        const cellcore_uuid = getCookie("cellcore_uuid");
        let isPublicRoute = false;
        if (cellcore_logged_in && cellcore_uuid) {
            setcellcoreUUID(cellcore_uuid);
        } else {
            routes.publicRoutes.forEach((route) => {
                if (route.path === location.pathname) {
                    isPublicRoute = true;
                    setPublicRoute(isPublicRoute);
                }
            });
        }
        if (!isPublicRoute && !cellcore_uuid) {
            window.location.href = `https://www.cellcore.com/account/login`;
        }
        return () => {};
    }, []);
    useEffect(() => {
        const getUserData = async () => {
            if (cellcoreUUID) {
                const newUserObj = await getUserDataByUUID(cellcoreUUID);
                if (!newUserObj) return;
                dispatch(storeUser(newUserObj));
                setUser(newUserObj);
                dispatch(toggleShowLoader({ boolean: false }));
                setShowNotMember(false);
                setIsAuthenticated(true);
            }
        };
        getUserData().catch(console.error);
    }, [cellcoreUUID]);

    useEffect(() => {
        TagManager.initialize({
            gtmId: "GTM-WDRQ8K7",
        });
        window.dataLayer.push({
            event: "pageview",
        });
        return () => {};
    }, []);

    useEffect(() => {
        dispatch(changeRoutes({ prevRoute: globalRouteData.routes.currentRoute, currentRoute: location.pathname }));
        return () => {};
    }, [location.pathname]);

    /**
     * @description - Check for idleness and refresh page after certain period - Goal is to eliminate issues with idle timeouts from several different services we're using - subscriptions in Community, etc.
     * @author Mike Roberts
     */
    useEffect(() => {
        let minutesToWait = 15;
        const refreshPage = () => {
            window.location.reload();
        };
        let timer;
        const resetTimer = () => {
            clearTimeout(timer);
            if (location.pathname.includes("/learning")) return;
            timer = setTimeout(refreshPage, minutesToWait * 60000);
        };
        window.onload = resetTimer;
        window.onmousemove = resetTimer;
        window.onmousedown = resetTimer; // Catches touchscreen presses as well
        window.ontouchstart = resetTimer; // Catches touchscreen swipes as well
        window.ontouchmove = resetTimer; // Required by some devices
        window.onclick = resetTimer; // Catches touchpad clicks as well
        window.onkeydown = resetTimer;
        window.addEventListener("scroll", resetTimer, true);
    }, [location.pathname]);

    const listenForDevCode = (isAdmin) => {
        const keySequence = ["ArrowDown", "ArrowDown", "ArrowLeft", "ArrowRight"];
        let userInput = new Array(keySequence.length);
        window.addEventListener("keydown", ({ key }) => {
            userInput = [...userInput.slice(1), key];
            if (keySequence.every((v, k) => v === userInput[k])) {
                dispatch(toggleShowDevTools(false));
            }
        });
    };

    useEffect(() => {
        const userIsAdmin =
            (parseInt(auth.user.access) === 5 || parseInt(auth.user.access) === 7) && parseInt(auth.user.status) === 3
                ? true
                : false;
        listenForDevCode(userIsAdmin);
    }, []);

    if (!isAuthenticated) {
        //Taking the pathname and query parameter and setting it to sessionStorage.
        Cookies.remove("user_picture");
        const returnTo = query.get("return_url");
        sessionStorage.setItem("path", history.location.pathname ? history.location.pathname : null);
        sessionStorage.setItem("return_to", returnTo ? returnTo : null);
    }

    return (
        <>
            {
                <AppProvider i18n={translations}>
                    <GlobalState>
                        <ThemeProvider theme={CustomTheme}>
                            <GlobalStyle />
                            <AppWrapper>
                                <div style={{ fontFamily: CustomTheme.typography.fontFamily }}>
                                    {isAuthenticated || publicRoute ? (
                                        <AppLayout user={user} propData={props} showNotMember={showNotMember}>
                                            <AppRoutes user={user} />
                                        </AppLayout>
                                    ) : (
                                        <CircularLoader show={true} />
                                    )}
                                </div>
                            </AppWrapper>
                        </ThemeProvider>
                    </GlobalState>
                </AppProvider>
            }
        </>
    );
}

export default App;
