import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    customers: [],
    tags: [],
};

export const customerSlice = createSlice({
    name: "practitionersCustomers",
    initialState,
    reducers: {
        changeCustomers: (state, action) => {
            state.customers = action.payload;
        },
        changeTags: (state, action) => {
            state.tags = action.payload;
        },
    },
});

export const { changeCustomers, changeTags } = customerSlice.actions;

export default customerSlice.reducer;
