import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";

import { shouldBeLive } from "./../../common/Functions/utilities";

const TopBarContainer = styled("div")(({ theme }) => ({
    backgroundColor: "#7dc8e3",
    color: "#FFF",
    textDecorationColor: "#FFF",
    minHeight: "26px",
    overflow: "hidden",
    width: "100%",
    margin: "0 auto",
    padding: "6px 3px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    zIndex: "1500",
    // position: "fixed",
    // top: 0,
    // left: 0,
}));

const TopBarTextContainer = styled("div")(({ theme }) => ({
    width: "100%",
    margin: "0 auto",
    padding: "0 3px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    position: "relative",
}));

const TopBarPara = styled("p")(({ theme }) => ({
    width: "100%",
    fontSize: "14px",
    lineHeight: "15px",
    margin: "0 auto",
    padding: "0",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    color: "#102348",
    textAlign: "center",
}));

const TopBarLink = styled(RouterLink)(({ theme }) => ({
    fontSize: "14px",
    lineHeight: "14px",
    margin: "0",
    padding: "0 5px",
    display: "block",
    color: "inherit",
    textDecorationColor: "inherit",
}));

const TopBar = (props) => {
    // const dispatch = useDispatch();
    const [barShown, setBarShown] = useState(false);
    //Set Top Bar Data Here
    const [barData, setBarData] = useState({
        content: {
            beforeLinkText: ``,
            link: {
                text: `Learn about the Patient Rewards Promotion by clicking here!`,
                url: `https://cellcore.com/pages/march-24-patient-promo`,
            },
            afterLinkText: ``,
        },
        schedule: {
            startDateAndTime: `Mar 06, 2024`,
            endDateAndTime: `Jun 02, 2024`,
        },
    });

    useEffect(() => {
        if (!props.barData) return;
        setBarData({
            ...barData,
            ...props.barData,
        });
    }, [props.barData]);

    useEffect(() => {
        if (!barData || barData?.schedule?.startDateAndTime?.length === 0) return;
        const barShouldShow = shouldBeLive(barData.schedule.startDateAndTime, barData.schedule.endDateAndTime);
        setBarShown(barShouldShow);
    }, [barData]);

    return (
        <>
            {barData &&
                barShown &&
                (barData?.content?.beforeLinkText?.length > 0 || barData?.content?.link?.text?.length > 0) && (
                    <TopBarContainer>
                        <TopBarTextContainer>
                            <TopBarPara>
                                {barData?.content?.beforeLinkText?.length > 0 && (
                                    <span>{barData.content.beforeLinkText}</span>
                                )}
                                {barData?.content?.link?.text?.length > 0 && (
                                    <TopBarLink
                                        to={{
                                            pathname: `${
                                                barData.content.link.url?.length > 0
                                                    ? barData.content.link.url
                                                    : "https://www.cellcore.com"
                                            }`,
                                        }}
                                        target="_blank"
                                    >
                                        {barData.content.link.text}
                                    </TopBarLink>
                                )}
                                {barData?.content?.afterLinkText?.length > 0 && (
                                    <span>{barData.content.afterLinkText}</span>
                                )}
                            </TopBarPara>
                        </TopBarTextContainer>
                    </TopBarContainer>
                )}
        </>
    );
};

export default TopBar;
