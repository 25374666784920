/**
 * Assessment Form Actions
 *
 * @author conorcook7
 */

const targetURL = {
    development: {
        // baseURL: "http://localhost:8080/graphql",
        baseURL: "https://assessment-forms.azurewebsites.net/graphql", //Change when database is connected
    },
    production: {
        baseURL: "https://assessment-forms.azurewebsites.net/graphql", //Change when database is connected
    },
}[process.env.NODE_ENV];

const headers = {
    "Content-Type": "application/json",
};

/**
 * Redux Action Get Assessments and all data
 */
export const getAssessments = async () => {
    const graphql = JSON.stringify({
        query: `{
        assessments {
          id
          desc
        }
      }`,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.assessments;
};

/**
 * Redux Action Fetch Single assessment
 */
export const getAssessment = async (assessmentId) => {
    const graphql = JSON.stringify({
        query: `
        {
          assessment(id: ${assessmentId}) {
            id
            desc
            published
            sections {
              id
              text
            }
            questions {
              id
              questionNumber
              text
              tf
              sectionQuestions {
                id
                sectionId
                zeroPosition
                onePosition
                twoPosition
                threePosition
                Section {
                  id
                  text
                  lowScore
                  midScore
                  highScore
                }
              }
            }
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.assessment;
};

/**
 * Redux Action Create Assessment
 */
export const createAssessment = async (desc) => {
    const graphql = JSON.stringify({
        query: `
        mutation {
          createAssessment(desc: "${desc}") {
            id
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.createAssessment;
};

/**
 * Redux Action Edit Assessment
 */
export const editAssessment = async (inputFields) => {
    const graphql = JSON.stringify({
        query: `
        mutation {
          updateAssessment(id: ${inputFields.id}, desc: "${inputFields.desc}", published: ${inputFields.published}) {
            id
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.updateAssessment;
};

/**
 * Redux Action Delete Assessment *This will be used when CASSCADE bug in database is fixed*
 */
export const deleteAssessment = async (id) => {
    const graphql = JSON.stringify({
        query: `
        mutation {
          deleteAssessment(id: ${id})
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.deleteAssessment;
};

/**
 * Redux Action Fetch Section
 */
export const getSection = async (sectionId) => {
    const graphql = JSON.stringify({
        query: `
        {
          section(id: ${sectionId}) {
            id
            text
            lowScore
            midScore
            highScore
            sectionQuestions {
              id
              zeroPosition
              onePosition
              twoPosition
              threePosition
              Question {
                text
                tf
              }
            }
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.section;
};

/**
 * Redux Action Fetch Sections
 */
export const getSections = async () => {
    const graphql = JSON.stringify({
        query: `
        {
          sections {
            id
            text
            lowScore
            midScore
            highScore
            sectionQuestions {
              id
              zeroPosition
              onePosition
              twoPosition
              threePosition
            }
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.sections;
};

/**
 * Redux Action Fetch Sections by Assessment
 */
export const getSectionsByAssessment = async (assessmentId) => {
    const graphql = JSON.stringify({
        query: `
        {
          sectionsByAssessment(assessmentId: ${assessmentId}) {
            id
            text
            lowScore
            midScore
            highScore
            sectionQuestions {
              id
            }
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.sectionsByAssessment;
};

/**
 * Redux Action Create Section
 */
export const createSection = async (inputField) => {
    const graphql = JSON.stringify({
        query: `
        mutation {
          createSection(text: "${inputField.text}", lowScore: ${inputField.lowScore}, midScore: ${inputField.midScore}, highScore: ${inputField.highScore}, assessmentId: ${inputField.assessmentId}) {
            id
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.createSection;
};

/**
 * Redux Action Edit Section
 */
export const editSection = async (inputFields) => {
    const graphql = JSON.stringify({
        query: `
        mutation {
          updateSection(id: ${inputFields.id}, text: "${inputFields.text}", lowScore: ${inputFields.lowScore}, midScore: ${inputFields.midScore}, highScore: ${inputFields.highScore}) {
            id
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.updateSection;
};

/**
 * Redux Action Delete Section
 */
export const deleteSection = async (id) => {
    const graphql = JSON.stringify({
        query: `
        mutation {
          deleteSection(id: ${id})
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.deleteSection;
};

/**
 * Redux Action Fetch Question
 */
export const getQuestion = async (questionId) => {
    const graphql = JSON.stringify({
        query: `
        {
          question(id: ${questionId}) {
            id
            questionNumber
            text
            tf
            depreciated
            sectionQuestions {
              id
              zeroPosition
              onePosition
              twoPosition
              threePosition
              Section {
                text
                tf
              }
            }
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.question;
};

/**
 * Redux Action Fetch Questions
 */
export const getQuestions = async () => {
    const graphql = JSON.stringify({
        query: `
        {
          questions {
            id
            questionNumber
            text
            tf
            depreciated
            sectionQuestions {
              id
              zeroPosition
              onePosition
              twoPosition
              threePosition
              Section {
                text
                tf
              }
            }
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.questions;
};

/**
 * Redux Action Create Question
 */
export const createQuestion = async (inputFields) => {
    const graphql = JSON.stringify({
        query: `
        mutation {
          createQuestion(text: "${inputFields.text}", tf: ${inputFields.tf}, assessmentId: ${inputFields.assessmentId}) {
            id
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.createQuestion;
};

/**
 * Redux Action Edit Question
 */
export const editQuestion = async (inputFields) => {
    const graphql = JSON.stringify({
        query: `
        mutation {
          updateQuestion(id: ${inputFields.id}, questionNumber: ${inputFields.questionNumber}, text: "${inputFields.text}", tf: ${inputFields.tf}) {
            id
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.updateQuestion;
};

/**
 * Redux Action Delete Question
 */
export const deleteQuestion = async (id) => {
    const graphql = JSON.stringify({
        query: `
        mutation {
          deleteQuestion(id: ${id})
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.deleteQuestion;
};

/**
 * Redux Action Fetch sectionQuestions
 */
export const getSectionQuestions = async () => {
    const graphql = JSON.stringify({
        query: `
        {
          sectionQuestions {
            id
            questionId
            sectionId
            zeroPosition
            onePosition
            twoPosition
            threePosition
            Section {
              id
              text
              lowScore
              midScore
              highScore
              assessmentId
              depreciated
            }
            Question {
              id
              questionNumber
              text
              tf
              assessmentId
              depreciated
            }
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.sectionQuestions;
};

/**
 * Redux Action Fetch sectionQuestion
 */
export const getSectionQuestion = async (id) => {
    const graphql = JSON.stringify({
        query: `
        {
          sectionQuestion(id: ${id}) {
            id
            questionId
            sectionId
            zeroPosition
            onePosition
            twoPosition
            threePosition
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.sectionQuestion;
};

/**
 * Redux Action Fetch sectionQuestion by question
 */
export const getSectionQuestionByQuestion = async (questionId) => {
    const graphql = JSON.stringify({
        query: `
        {
          sectionQuestionByQuestion(questionId: ${questionId}) {
            id
            questionId
            sectionId
            zeroPosition
            onePosition
            twoPosition
            threePosition
            Section {
              id
              text
              lowScore
              midScore
              highScore
              assessmentId
              depreciated
            }
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.getSectionQuestionByQuestion;
};

/**
 * Redux Action Fetch sectionQuestion by question
 */
export const getSectionQuestionBySection = async (sectionId) => {
    const graphql = JSON.stringify({
        query: `
        {
          sectionQuestionBySection(sectionId: ${sectionId}) {
            id
            Question {
              id
              questionNumber
              text
              tf
              sectionQuestions {
                id
              }
            }
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.sectionQuestionBySection;
};

/**
 * Redux Action Create SectionQuestion
 */
export const createSectionQuestion = async (inputFields) => {
    const graphql = JSON.stringify({
        query: `
        mutation {
          createSectionQuestion(questionId: ${inputFields.questionId}, sectionId: ${inputFields.sectionId}, zeroPosition: ${inputFields.zeroPosition}, onePosition: ${inputFields.onePosition}, twoPosition: ${inputFields.twoPosition}, threePosition: ${inputFields.threePosition}) {
            id
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.createSectionQuestion;
};

/**
 * Redux Action Edit SectionQuestion
 */
export const editSectionQuestion = async (inputFields) => {
    const graphql = JSON.stringify({
        query: `
        mutation {
          updateSectionQuestion(id: ${inputFields.id}, questionId: ${inputFields.questionId}, sectionId: ${inputFields.sectionId}, zeroPosition: ${inputFields.zeroPosition}, onePosition: ${inputFields.onePosition}, twoPosition: ${inputFields.twoPosition}, threePosition: ${inputFields.threePosition}) {
            id
          }
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.updateSectionQuestion;
};

/**
 * Redux Action Delete SectionQuestion
 */
export const deleteSectionQuestion = async (id) => {
    const graphql = JSON.stringify({
        query: `
        mutation {
          deleteSectionQuestion(id: ${id})
        }
      `,
    });

    const response = await fetch(targetURL.baseURL, {
        method: "POST",
        headers: headers,
        body: graphql,
    });
    const json = await response.json();
    return json.data.deleteSectionQuestion;
};
