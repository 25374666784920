import ReactGA from "react-ga";

const TRACKING_ID = "UA-106059202-6";

function init() {
    // Enable debug mode on the local development environment
    // ReactGA.initialize(TRACKING_ID, { debug: isDev });
    ReactGA.initialize(TRACKING_ID);
}

function sendEvent(payload) {
    ReactGA.event(payload);
}

function sendPageview(path) {
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
}

const analytics = {
    init,
    sendEvent,
    sendPageview,
};

export default analytics;
