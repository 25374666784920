import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TopBarInNav from "../../../../common/SideBar/TopBarInNav";
import { useHistory } from "react-router-dom";
import {
    selected,
    setCurrentSection,
    currentTopic,
    view,
    deletedFlag,
    changeLearnMore,
    changeAll,
} from "../learningAdminSlice";
import { getAllSections, topicsNoSections } from "../AdminLearningCalls";

const useStyles = makeStyles((theme) => ({
    navArea: {
        height: "100%",
        overflow: "hidden",
        backgroundColor: "#FFFFFF",
        paddingTop: theme.spacing(8),
        [theme.breakpoints.down("md")]: {
            paddingTop: 0,
        },
    },
    insideNav: {
        width: "100%",
        maxHeight: "calc(100vh - 64px)",
        overflowX: "scroll",
        [theme.breakpoints.down("md")]: {
            maxHeight: "calc(100vh - 4px)",
        },
    },
    drawer: {
        width: "100%",
        paddingTop: 0,
    },
    accordion: {
        margin: "0",
        boxShadow: "0px 1px 1px -1px rgb(0 0 0 / 14%), 0px 1px 1px 0px rgb(0 0 0 / 14%)",
        paddingBottom: 16,
    },
    summary: {
        color: theme.palette.text.grey,
        fontWeight: "700",
        margin: "0",
        minHeight: "34px !important",
        padding: "0 10px !important",
        "& div": {
            margin: "8px 0 !important",
        },
    },
    details: {
        padding: 0,
        flexDirection: "column",
    },
    listHeaders: {
        color: theme.palette.text.grey,
        fontWeight: "600",
        margin: "4px 0",
    },
    listItemsHeaders: {
        paddingLeft: 6,
        [theme.breakpoints.down("md")]: {
            paddingLeft: 12,
            marginTop: 8,
        },
    },
    listItemsContainer: {},
    listItems: {
        padding: "0 16px",
        marginBottom: 0,
        cursor: "pointer",
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        color: theme.palette.text.grey,
        [theme.breakpoints.down("md")]: {
            margin: "4px 0",
        },
        "&:hover": {
            backgroundColor: theme.palette.common.blue,
            color: theme.palette.common.white,
        },
    },
    listItemsBold: {
        fontWeight: 600,
    },
    listItemsNoBold: {
        fontWeight: 300,
    },
    selected: {
        backgroundColor: theme.palette.common.blue,
        color: theme.palette.common.white,
        fontWeight: 300,
    },
    listItemsText: {
        lineHeight: "1rem",
        margin: "4px 0",
    },
    listIcons: {
        fontSize: "1.4rem",
        marginRight: 8,
        minWidth: 30,
    },
    navIcons: {
        marginRight: theme.spacing(1),
        width: 24,
        display: "none",
        [theme.breakpoints.down("md")]: {
            display: "block",
        },
    },
}));

export default function SideBarLearningAdmin(props) {
    const learningState = useSelector((state) => state.adminLearning);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [topics, setTopics] = useState();
    const [sections, setSections] = useState();

    const handleListClick = (item, path) => {
        history.push(`/admin/learning/${path}/${item.id}`);
        if (path === "section") {
            dispatch(setCurrentSection(item));
        }

        if (path === "topic") {
            dispatch(currentTopic(item));
        }

        dispatch(selected(item.id));
        dispatch(view("module"));
        dispatch(changeLearnMore(""));
    };

    const handleOtherList = (path) => {
        history.push(`/admin/learning/${path}/`);
        if (
            path === "research" ||
            path === "live-qa" ||
            path === "best-of-live" ||
            path === "webinars" ||
            path === "additional" ||
            path === "textBlog"
        ) {
            dispatch(changeLearnMore(path));
        }
        dispatch(selected(0));
    };

    useEffect(async () => {
        let getTopics = await topicsNoSections();
        if (getTopics !== undefined) {
            setTopics(getTopics.data.topicsNoSection);
        }

        let getSections = await getAllSections();
        if (getSections !== undefined) {
            setSections(getSections.data.sections.rows);
        }

        if (learningState.deletedFlag) {
            dispatch(deletedFlag(false));
        }
        if (learningState.change.section || learningState.change.topic) {
            dispatch(changeAll({ ...learningState.change, section: false, topic: false }));
        }
        return () => {
            dispatch(changeAll({ ...learningState.change, section: false, topic: false }));
            dispatch(deletedFlag(false));
        };
    }, [learningState.deletedFlag, learningState.change.section, learningState.change.topic]);

    return (
        <div className={classes.navArea}>
            <nav className={classes.insideNav}>
                <TopBarInNav title={"Learning Center"} backToMain={props.backToMain} />
                <Accordion
                    defaultExpanded={true}
                    square={true}
                    className={classes.accordion}
                    onClick={() => props.onSecondaryNavClick()}
                >
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className={classes.summary}>
                        <ExpandMoreIcon></ExpandMoreIcon>
                        <span>Sections</span>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <List className={classes.drawer}>
                            {sections?.map((form, index) => {
                                return (
                                    <ListItem
                                        key={index}
                                        onClick={() => handleListClick(form, "section")}
                                        className={` ${classes.listItems} ${
                                            form.id === learningState.selected ? classes.selected : classes.notSelected
                                        } `}
                                    >
                                        <ListItemText className={classes.listItemsText}>{form.title}</ListItemText>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    defaultExpanded={true}
                    square={true}
                    className={classes.accordion}
                    onClick={() => props.onSecondaryNavClick()}
                >
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className={classes.summary}>
                        <ExpandMoreIcon></ExpandMoreIcon>
                        <span>Topics</span>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <List className={classes.drawer}>
                            {topics?.map((form, index) => {
                                return (
                                    <ListItem
                                        key={index}
                                        onClick={() => handleListClick(form, "topic")}
                                        className={` ${classes.listItems} ${
                                            form.id === learningState.selected ? classes.selected : classes.notSelected
                                        } `}
                                    >
                                        <ListItemText className={classes.listItemsText}>{form.title}</ListItemText>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    defaultExpanded={true}
                    square={true}
                    className={classes.accordion}
                    onClick={() => props.onSecondaryNavClick()}
                >
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className={classes.summary}>
                        <ExpandMoreIcon></ExpandMoreIcon>
                        <span>Learn More</span>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <List className={classes.drawer}>
                            <ListItem
                                onClick={() => handleOtherList("live-qa")}
                                className={` ${classes.listItems} ${
                                    learningState.learnMore === "live-qa" ? classes.selected : classes.notSelected
                                } `}
                            >
                                <ListItemText className={classes.listItemsText}>Live Q&amp;A</ListItemText>
                            </ListItem>
                            <ListItem
                                onClick={() => handleOtherList("best-of-live")}
                                className={` ${classes.listItems} ${
                                    learningState.learnMore === "best-of-live" ? classes.selected : classes.notSelected
                                } `}
                            >
                                <ListItemText className={classes.listItemsText}>Best of Live</ListItemText>
                            </ListItem>
                            <ListItem
                                onClick={() => handleOtherList("webinars")}
                                className={` ${classes.listItems} ${
                                    learningState.learnMore === "webinars" ? classes.selected : classes.notSelected
                                } `}
                            >
                                <ListItemText className={classes.listItemsText}>Webinars</ListItemText>
                            </ListItem>
                            <ListItem
                                onClick={() => handleOtherList("additional")}
                                className={` ${classes.listItems} ${
                                    learningState.learnMore === "additional" ? classes.selected : classes.notSelected
                                } `}
                            >
                                <ListItemText className={classes.listItemsText}>Additional Videos</ListItemText>
                            </ListItem>
                            <ListItem
                                onClick={() => handleOtherList("textBlog")}
                                className={` ${classes.listItems} ${
                                    learningState.learnMore === "textBlog" ? classes.selected : classes.notSelected
                                } `}
                            >
                                <ListItemText className={classes.listItemsText}>Articles</ListItemText>
                            </ListItem>
                            <ListItem
                                onClick={() => handleOtherList("research")}
                                className={` ${classes.listItems} ${
                                    learningState.learnMore === "research" ? classes.selected : classes.notSelected
                                } `}
                            >
                                <ListItemText className={classes.listItemsText}>Research</ListItemText>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
            </nav>
        </div>
    );
}
