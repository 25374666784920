import React from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid } from "@mui/material";
import CustomTheme from "../../theme/CustomTheme";

const ErrorDiv = styled("div")(() => ({
    border: `2px solid ${CustomTheme.palette.error.main}`,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    padding: "15px 0px",
}));

const InnerDiv = styled("div")(() => ({
    width: "75%",
    overflow: "hidden",
}));

const ErrorP = styled("p")(() => ({
    margin: "0 0 0 20px",
    padding: "5px 0px",
    color: CustomTheme.palette.error.main,
}));

const ErrorH3 = styled("h3")(() => ({
    margin: "0",
    padding: "5px 0px",
}));

const CustomButton = styled(Button)(() => ({
    border: "1px solid grey",
    backgroundColor: CustomTheme.palette.buttons.blue_dark,
    color: "white",
    marginTop: "15px",
}));

export const ErrorComponent = ({ error }) => {
    const refreshPage = () => {
        window.location.reload();
    };

    return (
        <ErrorDiv role="alert">
            <InnerDiv>
                <ErrorH3>Something went wrong:</ErrorH3>
                <Grid container>
                    <Grid item xs={6} sm={6} md={3} lg={2}>
                        <img
                            src="https://res.cloudinary.com/microbe/image/upload/v1657300166/Cross%20Site/Graphics/Web_Illustrations-01.svg"
                            alt="error"
                        />
                    </Grid>
                </Grid>
                <ErrorP>{error.message}</ErrorP>
                <ErrorH3>
                    Please screenshot this error and send it to your rep to be looked into, then refresh the page.
                </ErrorH3>
                <CustomButton size="medium" onClick={refreshPage}>
                    Refresh
                </CustomButton>
            </InnerDiv>
        </ErrorDiv>
    );
};
