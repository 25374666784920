import React from "react";
import { styled, Button, Grid } from "@mui/material";
import { ReactComponent as ReactFooter } from "../../assets/imgs/error-footer.svg";

const Footer = styled(ReactFooter)({
    backgroundColor: "transparent",
    backgroundImage: `url({{ 'cellcore-footer-border-top.svg'}})`,
    backgroundRepeat: "repeat",
    backgroundSize: "cover",
    content: "''",
    paddingTop: "0rem",
    width: "110%",
    display: "block",
    backgroundPosition: "left bottom",
    transform: "translate(-5%, 50%)",
});

const Container = styled("div")({
    padding: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "90vh",
});

const MessageDiv = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
});

const Error404 = () => {
    return (
        <Container>
            <MessageDiv>
                <h1>404</h1>
                <Grid container justifyContent="center">
                    <Grid item xs={6} sm={4} md={3} lg={2}>
                        <img
                            src="https://res.cloudinary.com/microbe/image/upload/f_auto,e_trim/Cross%20Site/Graphics/error_illustration_3.png"
                            alt=""
                        />
                    </Grid>
                </Grid>
                <h3>Page Not Found</h3>
                <Button variant="contained" href="/dashboard">
                    Return to Dashboard
                </Button>
            </MessageDiv>
            <Footer />
        </Container>
    );
};
export default Error404;
export { Error404 };
