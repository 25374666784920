import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    openAddTextSuccess: false,
    openEditTextSuccess: false,
    editTextFailMessage: "",
    openAddVideoSuccess: false,
    openEditVideoSuccess: false,
    flag: false,
    currentView: 0,
};

export const blogAdminSlice = createSlice({
    name: "blogAdmin",
    initialState,
    reducers: {
        changeOpenAddTextSuccess: (state, action) => {
            state.openAddTextSuccess = action.payload;
        },
        changeOpenEditTextSuccess: (state, action) => {
            state.openEditTextSuccess = action.payload;
        },
        changeEditTextFailMessage: (state, action) => {
            state.editTextFailMessage = action.payload;
        },
        changeOpenAddVideoSuccess: (state, action) => {
            state.openAddVideoSuccess = action.payload;
        },
        changeOpenEditVideoSuccess: (state, action) => {
            state.openEditVideoSuccess = action.payload;
        },
        changeFlag: (state, action) => {
            state.flag = action.payload;
        },
        changeCurrentView: (state, action) => {
            state.currentView = action.payload;
        },
    },
});

export const {
    changeOpenAddTextSuccess,
    changeOpenEditTextSuccess,
    changeEditTextFailMessage,
    changeOpenAddVideoSuccess,
    changeOpenEditVideoSuccess,
    changeFlag,
    changeCurrentView,
} = blogAdminSlice.actions;

export default blogAdminSlice.reducer;
