import { createTheme } from "@mui/material/styles";

const prevalantColors = {
    common: {
        black: "#000000",
        white: "#FFFFFF",

        blue: "#10527D", //CELLCORE APPROVED
        blue_light: "rgba(98,203,230,0.6)", //CELLCORE APPROVED
        blue_dark: "#102348", //CELLCORE APPROVED

        brown: "#966D48", //CELLCORE APPROVED
        brown_light: "#FBE7C9", //CELLCORE APPROVED
        brown_dark: "#331F0B", //CELLCORE APPROVED

        green: "#61BF51", //CELLCORE APPROVED
        green_light: "#C1DE9C", //CELLCORE APPROVED
        green_dark: "#123F15", //CELLCORE APPROVED

        grey: "#939598", //CELLCORE APPROVED
        grey_light: "#E6E7E8", //CELLCORE APPROVED
        grey_lightest: "#EEEEEE",
        grey_dark: "#5e5c61", //CELLCORE APPROVED

        orange: "#E88924", //CELLCORE APPROVED
        orange_light: "#F8B87C", //CELLCORE APPROVED
        orange_dark: "#772C06", //CELLCORE APPROVED

        purple: "#79499D", //CELLCORE APPROVED
        purple_light: "#D9BEEA", //CELLCORE APPROVED
        purple_dark: "28104F", //CELLCORE APPROVED

        red: "#E42627", //CELLCORE APPROVED
        red_light: "#F4B3BA", //CELLCORE APPROVED
        red_dark: "#600509", //CELLCORE APPROVED

        yellow: "#FCF3B1", //CELLCORE APPROVED
        yellow_light: "#E5C923", //CELLCORE APPROVED
        yellow_dark: "#BE892C", //CELLCORE APPROVED
    },
    cellcoreGradient: {
        leftToRight: "linear-gradient(155deg, rgba(98,203,230,1) 0%, rgba(16,20,32,1) 90%)",
        rightToLeft: "linear-gradient(315deg, rgba(98,203,230,1) 0%, rgba(16,20,32,1) 90%)",
        topToBottom: "linear-gradient(180deg, rgba(98,203,230,1) 0%, rgba(16,20,32,1) 100%)",
        bottomToTop: "linear-gradient(0deg, rgba(98,203,230,1) 0%, rgba(16,20,32,1) 100%)",
    },
};

const CustomTheme = createTheme({
    palette: {
        type: "light",
        common: prevalantColors.common,
        cellcoreGradient: prevalantColors.cellcoreGradient,
        text: {
            main: prevalantColors.common.blue_dark,
            light: prevalantColors.common.grey_lightest,
            dark: prevalantColors.common.black,
            grey: prevalantColors.common.grey_dark,
            grey_light: prevalantColors.common.grey_light,
            grey_lightest: prevalantColors.common.grey_lightest,
            grey_dark: prevalantColors.common.grey_dark,
            blue_light: prevalantColors.common.blue_light,
        },
        buttons: {
            main: prevalantColors.common.grey_lightest,
            light: prevalantColors.common.grey_lightest,
            dark: prevalantColors.common.grey_light,
            blue: prevalantColors.common.blue,
            blue_light: prevalantColors.common.blue_light,
            blue_dark: prevalantColors.common.blue_dark,
            grey: prevalantColors.common.grey,
            grey_light: prevalantColors.common.grey_light,
            grey_dark: prevalantColors.common.grey_dark,
        },
        error: {
            main: prevalantColors.common.red,
            light: prevalantColors.common.red_light,
            dark: prevalantColors.common.red_dark,
        },
        warning: {
            main: prevalantColors.common.orange,
            light: prevalantColors.common.orange_light,
            dark: prevalantColors.common.orange_dark,
        },
        info: {
            main: prevalantColors.common.blue_light,
            light: prevalantColors.common.blue_light,
            dark: prevalantColors.common.blue,
        },
        primary: {
            main: prevalantColors.common.blue_dark,
            light: prevalantColors.common.blue_light,
            dark: prevalantColors.common.blue_dark,
        },
        secondary: {
            main: prevalantColors.common.blue,
            light: prevalantColors.common.blue_light,
            dark: prevalantColors.common.blue_dark,
            contrastText: prevalantColors.common.white,
        },
    },
    typography: {
        fontFamily: ["gotham", "Montserrat", "roboto", "sans-serif", "gotham"],
        secondaryFontFamily: ["gotham", "Montserrat", "roboto", "sans-serif", "gotham"],
        fontSize: 14,
        lineHeight: 19,
        sizes: {
            small: {
                fontSize: 12,
                lineHeight: 15,
            },
            standard: {
                fontSize: 16,
                lineHeight: 19,
            },
            large: {
                fontSize: 20,
                lineHeight: 23,
            },
        },
        h1: {
            fontFamily: "gotham",
        },
        h2: {
            fontFamily: "gotham",
        },
        body1: {
            fontFamily: "gotham",
        },
        body2: {
            fontFamily: "gotham",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 450,
            md: 769,
            lg: 1280,
            xl: 1920,
            xxl: 2453,
        },
    },
    widths: {
        nav: {
            main: {
                desktop: 240,
                tablet: "80vw",
                mobile: "86vw",
            },
            secondary: {
                desktop: 240,
                tablet: "80vw",
                mobile: "86vw",
            },
        },
    },
    borders: {
        radius: {
            small: `3px%`,
            standard: `6px`,
            large: `10px`,
            xl: `20px`,
            xxl: `30px`,
        },
    },
});

export default CustomTheme;
