import React, { createContext, useReducer } from "react";
//USED FOR LARGER UPDATES THROUGHOUT THE DASHBOARD; SHOULD BE REPLACED WITH REDUX AS SOON AS RESONABLE

const initialGlobalState = {
    nav: {
        secondaryNavBar: {
            sideBar: {
                sections: {},
            },
        },
        states: {
            mainDrawerOpen: false,
            secondaryDrawerOpen: true,
            isMobile: false,
        },
    },
    showLoader: false,
    practitionersCustomers: [],
    learningTopics: {},
};

const Reducer = (currentState, actionData) => {
    const newGlobalData = JSON.parse(JSON.stringify(currentState));
    for (let a in actionData) {
        const action = actionData[a];
        if (action.type === "is-mobile") {
            newGlobalData.nav.states.isMobile = action.data;
        } else if (action.type === "nav-states") {
            if (action.subtype === "all") {
                const bool = action.data === "open" ? true : false;
                newGlobalData.nav.states.mainDrawerOpen = bool;
                newGlobalData.nav.states.secondaryDrawerOpen = bool;
            } else if (action.subtype === "main") {
                const bool = action.data === "open" ? true : false;
                newGlobalData.nav.states.mainDrawerOpen = bool;
            } else if (action.subtype === "secondary") {
                const bool = action.data === "open" ? true : false;
                newGlobalData.nav.states.secondaryDrawerOpen = bool;
            }
        } else if (action.type === "metadata") {
            newGlobalData.metadata = action.data;
        } else if (action.type === "set-current-user") {
            newGlobalData.currentUser = action.data;
        } else if (action.type === "nav-general-routes") {
            newGlobalData.nav.secondaryNavBar.title = action.title;
            newGlobalData.nav.secondaryNavBar.sideBar.sections = action.routes;
        } else if (action.type === "globalAdd") {
            newGlobalData[action.key] = action.data;
        }
    }
    return newGlobalData;
};

const GlobalState = ({ children }) => {
    const [globalState, dispatchGlobalState] = useReducer(Reducer, initialGlobalState);
    return <Context.Provider value={[globalState, dispatchGlobalState]}>{children}</Context.Provider>;
};

export const Context = createContext(initialGlobalState);
export default GlobalState;
