import React from "react";
import Loader from "../common/Loading/Loader";

const LoginLoading = () => {
    return (
        <div>
            <Loader />
        </div>
    );
};

export default LoginLoading;
