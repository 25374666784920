import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    routes: [],
    searchValue: "",
};

export const learningSlice = createSlice({
    name: "practitionersCustomers",
    initialState,
    reducers: {
        changeRoutes: (state, action) => {
            state.routes = action.payload;
        },
        newSearchState: (state, action) => {
            state.searchValue = action.payload;
        },
    },
});

export const { changeRoutes, newSearchState } = learningSlice.actions;

export default learningSlice.reducer;
