import loadable from "@loadable/component";

const wrapLoadable = (from) => {
    return loadable(() => from);
};

const practRoutes = [
    {
        title: "Dashboard",
        icon: "",
        main_menu: true,
        menu_icon: "house",
        menu_title: "Dashboard",
        exact: true,
        path: "/dashboard",
        component: wrapLoadable(import("../../Main")),
    },
    // Patient Routes
    {
        title: "Patients",
        menu_icon: "person",
        main_menu: true,
        menu_title: "Patients",
        // noContentAdmin: true,
        path: "/patients",
        exact: true,
        component: wrapLoadable(import("../../../features/customers")),
    },
    {
        title: "Patient Profile",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/patients/:patientId",
        component: wrapLoadable(import("../../../features/customers/customerProfile")),
    },
    {
        path: "/customers*",
        redirectBase: "/patients",
        component: wrapLoadable(import("../RedirectComponent.tsx")),
    },
    {
        path: "/customer*",
        redirectBase: "/patient",
        component: wrapLoadable(import("../RedirectComponent.tsx")),
    },
    // Reports Routes
    {
        title: "Reports",
        menu_icon: "pie_chart",
        main_menu: true,
        // noContentAdmin: true,
        menu_title: "Reports",
        path: "/reports/orders",
        exact: true,
        component: wrapLoadable(import("../../../features/reports/orders")),
    },

    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Orders",
        path: "/reports/orders/:patientId?",
        exact: true,
        component: wrapLoadable(import("../../../features/reports/orders")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reports/sales-over-time",
        exact: true,
        component: wrapLoadable(import("../../../features/reports/sales-over-time")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reports/sales-over-time/:patientId?",
        exact: true,
        component: wrapLoadable(import("../../../features/reports/sales-over-time")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reports/sales-by-product",
        exact: true,
        component: wrapLoadable(import("../../../features/reports/sales-by-product")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reports/sales-by-product/:patientId?",
        exact: true,
        component: wrapLoadable(import("../../../features/reports/sales-by-product")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reports/orders-over-time",
        exact: true,
        component: wrapLoadable(import("../../../features/reports/orders-over-time")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reports/orders-over-time/:patientId?",
        exact: true,
        component: wrapLoadable(import("../../../features/reports/orders-over-time")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reports/patients-over-time",
        exact: true,
        component: wrapLoadable(import("../../../features/reports/patients-over-time")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reports/first-time-vs-returning",
        exact: true,
        component: wrapLoadable(import("../../../features/reports/first-time-vs-returning")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reports/returning-patients",
        exact: true,
        component: wrapLoadable(import("../../../features/reports/returning-customers")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reports/at-risk-patients",
        exact: true,
        component: wrapLoadable(import("../../../features/reports/at-risk-customers")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reports/loyal-patients",
        exact: true,
        component: wrapLoadable(import("../../../features/reports/loyal-customers")),
    },
    // Tools Routes
    {
        title: "Practitioner Tools",
        menu_icon: "content_paste",
        main_menu: true,
        menu_title: "Practitioner Tools",
        path: "/tools/assessment-form",
        exact: true,
        component: wrapLoadable(import("../../../features/tools/assessment-form")),
    },
    {
        title: "Practitioner Tools",
        menu_title: "Protocol Guide",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/tools/protocol-guide",
        exact: true,
        component: wrapLoadable(import("../../../features/tools/protocol-guide")),
    },
    {
        title: "Practitioner Tools",
        menu_title: "Assessment Forms",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/tools/assessment-form",
        component: wrapLoadable(import("../../../features/tools/assessment-form")),
    },
    {
        title: "Practitioner Tools",
        menu_title: "Assessment Forms Results",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/tools/assessment-results/:userId/:assessmentRequestId",
        component: wrapLoadable(import("../../../features/tools/assessment-results")),
    },
    {
        title: "Practitioner Tools",
        menu_title: "Thyroid Charting",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/tools/thyroid-charting",
        exact: true,
        component: wrapLoadable(import("../../../features/tools/thyroid-charting")),
    },
    {
        title: "Practitioner Tools",
        menu_title: "New Thyroid Chart",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/tools/thyroid-charting/new-chart",
        exact: true,
        component: wrapLoadable(import("../../../features/tools/thyroid-charting/new-chart")),
    },
    {
        title: "Practitioner Tools",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/tools/thyroid-charting/new-chart/:user_id?",
        exact: true,
        component: wrapLoadable(import("../../../features/tools/thyroid-charting/new-chart")),
    },
    {
        title: "Practitioner Tools",
        menu_title: "Thyroid Chart Results",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/tools/thyroid-charting/results",
        exact: true,
        component: wrapLoadable(import("../../../features/tools/thyroid-charting/results")),
    },
    {
        title: "Practitioner Tools",
        menu_title: "All Thyroid Chart Results",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/tools/thyroid-charting/results-all",
        exact: true,
        component: wrapLoadable(import("../../../features/tools/thyroid-charting/results-all")),
    },
    // Learning Center
    {
        title: "Learning Center",
        menu_icon: "school",
        main_menu: true,
        menu_title: "Learning Center",
        path: "/learningcenter",
        exact: true,
        component: wrapLoadable(import("../../../features/learning-center/landingPage")),
    },

    {
        title: "Learning Center",
        menu_icon: "school",
        menu_title: "Courses",
        path: "/learningcenter/courses",
        exact: true,
        component: wrapLoadable(import("../../../features/learning-center")),
    },
    {
        title: "Learning Center",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Research",
        path: "/learningcenter/research",
        exact: true,
        component: wrapLoadable(import("../../../features/research/index.tsx")),
    },
    {
        title: "Learning Center",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Articles",
        path: "/learningcenter/articles",
        exact: true,
        component: wrapLoadable(import("../../../features/articles")),
    },
    {
        title: "Learning Center",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Live With the Docs",
        path: "/learningcenter/webinars",
        exact: true,
        component: wrapLoadable(import("../../../features/live-qa/webinars")),
    },
    {
        title: "Learning Center",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Webinars",
        path: "/learningcenter/webinars/:id",
        exact: true,
        component: wrapLoadable(import("../../../features/live-qa/LivePage")),
    },
    {
        title: "Learning Center",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Live With the Docs",
        path: "/learningcenter/additional",
        exact: true,
        component: wrapLoadable(import("../../../features/live-qa/additional")),
    },
    {
        title: "Learning Center",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Live With the Docs",
        path: "/learningcenter/liveqa",
        exact: true,
        component: wrapLoadable(import("../../../features/live-qa/lwtd.tsx")),
    },
    {
        title: "Learning Center",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Live With the Docs",
        path: "/learningcenter/liveqa/:id",
        exact: true,
        component: wrapLoadable(import("../../../features/live-qa/LivePage")),
    },
    {
        title: "Learning Center",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Live With the Docs",
        path: "/learningcenter/bestlive",
        exact: true,
        component: wrapLoadable(import("../../../features/live-qa/lwtd.tsx")),
    },
    {
        title: "Learning Center",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Live With the Docs",
        path: "/learningcenter/bestlive/:id",
        exact: true,
        component: wrapLoadable(import("../../../features/live-qa/LivePage")),
    },
    {
        title: "Learning Center",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Articles",
        path: "/learningcenter/articles/:id",
        exact: true,
        component: wrapLoadable(import("../../../features/articles/ArticleModule")),
    },
    {
        title: "Learning Center",
        menu_icon: "school",
        menu_title: "Learning Center",
        path: "/learningcenter/courses/:sectionId",
        exact: true,
        component: wrapLoadable(import("../../../features/learning-center")),
    },
    {
        title: "Learning Center",
        menu_icon: "school",
        inside_menu: true,
        menu_title: "Learning Center",
        path: "/learningcenter/about",
        exact: true,
        component: wrapLoadable(import("../../../features/learning-center/admin/components/Topics")),
    },
    {
        title: "Learning Center",
        menu_icon: "school",
        path: "/learningcenter/courses/topic/:topicId",
        component: wrapLoadable(import("../../../features/learning-center/learningTopics")),
    },
    {
        title: "Learning Center",
        menu_icon: "school",
        path: "/learningcenter/courses/module/:moduleId/:articleId?",
        component: wrapLoadable(import("../../../features/learning-center/LearningModules")),
    },
    {
        title: "Learning Center",
        menu_icon: "school",
        inside_menu: true,
        menu_title: "ECO",
        path: "/learningcenter/ECO",
        exact: true,
        component: wrapLoadable(import("../../../features/eco/EcoIndex.tsx")),
    },
    {
        title: "Learning Center",
        menu_icon: "school",
        inside_menu: true,
        menu_title: "ECO Resources",
        path: "/learningcenter/ECO/ECO_2022_resources",
        exact: true,
        component: wrapLoadable(import("../Error404")),
    },
    {
        title: "Learning Center",
        menu_icon: "school",
        inside_menu: true,
        menu_title: "ECO",
        path: "/learningcenter/ECO/:ecoTitle",
        exact: true,
        component: wrapLoadable(import("../../../features/eco/EcoVideos.tsx")),
    },
    {
        title: "Learning Center",
        menu_icon: "school",
        inside_menu: true,
        menu_title: "ECO",
        path: "/learningcenter/ECO/:eventTitle/:moduleTitle",
        exact: true,
        component: wrapLoadable(import("../../../features/eco/EcoVideo.tsx")),
    },
    // learning redirect to learningcenter
    {
        path: "/learning*",
        redirectBase: "/learningcenter",
        component: wrapLoadable(import("../RedirectComponent.tsx")),
    },

    // Events Routes
    {
        title: "Upcoming Events",
        menu_icon: "date_range",
        main_menu: true,
        menu_title: "Upcoming Events",
        path: "/events",
        component: wrapLoadable(import("../../../features/events")),
    },
    {
        title: "Events",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/events/eco",
        menu_title: "Eco",
        component: wrapLoadable(import("../../../features/events/eco")),
    },
    {
        title: "Events",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/events/live",
        component: wrapLoadable(import("../../../features/events/live")),
    },
    {
        title: "Events",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/events/muscle-testing",
        component: wrapLoadable(import("../../../features/events/muscle-testing")),
    },
    {
        title: "Events",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/events/see-us",
        component: wrapLoadable(import("../../../features/events/see-us")),
    },
    {
        title: "Events",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/events/webinars",
        component: wrapLoadable(import("../../../features/events/webinars")),
    },
    // Dashboard
    {
        title: "Practitioner Dashboard",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/practitioner",
        main_menu: false,
        menu_title: "Practitioner Dashboard",
        component: wrapLoadable(import("../../../features/dashboard/practitioner")),
    },
    {
        title: "Practitioner Dashboard",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/practitioner/dashboard",
        component: wrapLoadable(import("../../../features/dashboard/practitioner")),
    },

    // Community
    {
        title: "Community",
        main_menu: false,
        menu_title: "Community",
        nav_bar_title: "Community Center",
        menu_icon: "groups",
        path: "/community",
        component: wrapLoadable(import("../../../features/community")),
    },

    // Resources Routes
    {
        title: "Resources",
        menu_icon: "system_update_alt",
        main_menu: true,
        menu_title: "Resources",
        path: "/resources",
        component: wrapLoadable(import("../../../features/resources")),
    },
    // Practitioner Onboarding
    {
        title: "Onboarding",
        menu_icon: "book",
        main_menu: true,
        menu_title: "Onboarding",
        path: "/onboarding",
        externalPath: "https://onboarding.cellcore.com",
        exact: true,
        component: wrapLoadable(import("../RedirectExternalComponent.tsx")),
    },
    {
        title: "Onboarding Article",
        menu_icon: "school",
        menu_title: "Articles",
        path: "/onboarding/articles/:id",
        component: wrapLoadable(import("../../../features/onboarding/article")),
    },
    {
        title: "Onboarding Articles",
        menu_title: "Onboarding Articles",
        path: "/onboarding/articles",
        component: wrapLoadable(import("../../../features/onboarding/articles")),
    },
    {
        title: "Onboarding Video",
        menu_icon: "school",
        menu_title: "Video",
        path: "/onboarding/videos/:id",
        component: wrapLoadable(import("../../../features/onboarding/video")),
    },
    {
        title: "Onboarding Videos",
        menu_title: "Onboarding Videos",
        path: "/onboarding/videos",
        component: wrapLoadable(import("../../../features/onboarding/videos")),
    },
    {
        title: "Onboarding",
        menu_title: "Onboarding",
        path: "/onboarding/resources",
        component: wrapLoadable(import("../../../features/onboarding/resources")),
    },
    // {
    //     title: "Onboarding",
    //     main_menu: true,
    //     menu_title: "Onboarding",
    //     nav_bar_title: "Onboarding",
    //     menu_icon: "book",
    //     path: "/onboarding",
    //     externalPath: "https://onboarding.cellcore.com",
    //     component: wrapLoadable(import("../../../features/onboarding/index")),
    // },
    // Account Routes
    {
        title: "Account Settings",
        main_menu: true,
        // noContentAdmin: true,
        menu_title: "Account Settings",
        nav_bar_title: "Account Settings",
        menu_icon: "settings",
        path: "/account/profile/",
        component: wrapLoadable(import("../../../features/account/index")),
    },
    {
        title: "Passwords",
        main_menu: false,
        menu_title: "Passwords",
        path: "/account/passwords",
        component: wrapLoadable(import("../../../features/account/passwords")),
    },
    {
        title: "Personal Information",
        main_menu: false,
        menu_title: "Personal Information",
        path: "/account/personal",
        component: wrapLoadable(import("../../../features/account/personal")),
    },
    {
        title: "Account Settings",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/account/addresses",
        component: wrapLoadable(import("../../../features/account/addresses")),
    },
    {
        title: "Payout Preferences",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/account/payout-preferences",
        component: wrapLoadable(import("../../../features/account/payout")),
    },
    {
        title: "Tax Forms",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/account/tax-forms",
        component: wrapLoadable(import("../../../features/account/tax-forms")),
    },
    {
        title: "Marketing Center",
        main_menu: false,
        menu_title: "Marketing Center",
        path: "/account/marketing",
        component: wrapLoadable(import("../../../features/account/marketing")),
    },
    {
        title: "Print Order",
        main_menu: false,
        menu_title: "printing",
        path: "/printorder",
        component: wrapLoadable(import("../../../features/reports/PrintOrder/index.js")),
    },
    {
        title: "Dashboard",
        icon: "",
        menu_icon: "house",
        menu_title: "Dashboard",
        exact: true,
        path: "/",
        redirectBase: "/dashboard",
        component: wrapLoadable(import("../RedirectComponent.tsx")),
    },
];

export default practRoutes;
