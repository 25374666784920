import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { toggleShowLoader } from "../../common/globalSlice/globalSlice";

import PageOverlay from "../Loading/Overlay";

import { styled } from "@mui/material/styles";

const SiteLoader = styled("div")(({ theme }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    zIndex: 2001,
}));

const SiteLoaderInner = styled("div")(({ theme }) => ({
    margin: "30px auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    position: "relative",
    zIndex: 2001,
}));

const SiteLoaderImg = styled("img")(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 70,
    minWidth: 70,
}));

const Loader = (props) => {
    const dispatch = useDispatch();
    const globalState = useSelector((state) => state.global);
    const [showLoader, setShowLoader] = useState(false);

    const handleLoaderClick = () => {
        dispatch(toggleShowLoader(false));
    };

    useEffect(() => {
        setShowLoader(globalState.loader.showLoader);
    }, [globalState.loader.showLoader]);

    return (
        <div>
            {showLoader && (
                <SiteLoader>
                    <PageOverlay></PageOverlay>
                    <SiteLoaderInner onClick={handleLoaderClick}>
                        <SiteLoaderImg src={globalState.loader.iconImage} alt="CellCore Loading Icon" />
                    </SiteLoaderInner>
                </SiteLoader>
            )}
        </div>
    );
};

export default Loader;
