import React from "react";
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import CustomTheme from "../../theme/CustomTheme";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link, Button, Typography } from "@mui/material";

const ErrorDiv = styled(Box)(() => ({
    width: "100%",
    height: "100%",
    maxWidth: "600px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "0 auto",
}));

const ErrorDivInner = styled(Box)(() => ({
    border: `2px solid ${CustomTheme.palette.error.main}`,
    backgroundColor: "#FFF",
    width: "96%",
    maxWidth: "600px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "25px 15px",
    margin: "0 auto",
}));

const LinkComponent = styled(Link)(() => ({
    margin: "14px auto 0",
}));

export const ErrorComponentCustom = (props) => {
    return (
        <>
            {props.showNotMember && (
                <Modal
                    open={true}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ErrorDiv>
                        <ErrorDivInner>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Dashboard is for Practitioner use only.
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }} align="center">
                                If you are seeing this in error, please login through Shopify and navigate back to the
                                Dashboard.
                            </Typography>
                            <LinkComponent
                                underline="none"
                                component={RouterLink}
                                to={{
                                    pathname: "https://www.cellcore.com/account/login",
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button variant="text">Log In</Button>
                            </LinkComponent>
                        </ErrorDivInner>
                    </ErrorDiv>
                </Modal>
            )}
        </>
    );
};
