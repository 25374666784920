import loadable from "@loadable/component";

const wrapLoadable = (from) => {
    return loadable(() => from);
};

const patientRoutes = [
    {
        title: "Patient Dashboard",
        icon: "",
        path: "/patient/dashboard",
        component: wrapLoadable(import("../../../features/dashboard/patient")),
    },
];

export default patientRoutes;
