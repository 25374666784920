import loadable from "@loadable/component";
import patientRoutes from "./patient";
import practRoutes from "./practitioner";
import adminRoutes from "./admin";
import salesRoutes from "./sales";

const wrapLoadable = (from) => {
    return loadable(() => from);
};

export const publicRoutes = [
    // {
    //     title: "Dashboard",
    //     icon: "",
    //     main_menu: true,
    //     menu_icon: "house",
    //     // menu_title: "Dashboard",
    //     exact: true,
    //     path: "/dashboard",
    //     component: wrapLoadable(import("../../Main")),
    // },
    // {
    //     title: "logging in",
    //     exact: true,
    //     path: "/",
    // },
    {
        title: "International update",
        exact: true,
        path: "/01032023test",
        component: wrapLoadable(import("../../../features/international-update")),
    },
    {
        title: "International update success",
        exact: true,
        path: "/01032023confirm",
        component: wrapLoadable(import("../../../features/international-update/success")),
    },
    // {
    //     title: "Logout",
    //     exact: true,
    //     path: "/logout",
    //     component: wrapLoadable(import("../../Logout")),
    // },
];

// Do We want to bring these into the routes

// const routes = {
//   root: {
//     url: "/practitioner",
//     exact: true,
//     component: wrapLoadable(import("../../features/dashboard/Practitioner")),
//   },
// 404: {
//   url: "/err/404",
//   component: wrapLoadable(import("./404")),
// },
// 500: {
//   url: "/err/500",
//   component: wrapLoadable(import("./500")),
// },
// users: {
//   url: "/users",
//   component: wrapLoadable(import("./users")),
// },
const allRoutes = {
    patientRoutes,
    practRoutes,
    adminRoutes,
    salesRoutes,
    publicRoutes,
};

export default allRoutes;
