import { convertKVArray } from "./../../common/Functions/utilities";

export const makeNewUserObject = async (data, kvs, uuid) => {
    let newObj = {
        "https://cellcore.com/type": kvs.cellcore_type,
        "https://cellcore.com/shopify_id": data.customer_id,
        "https://cellcore.com/user_id": data.id,
        "https://cellcore.com/access": data.type,
        "https://cellcore.com/parent_id": data.parent_id,
        "https://cellcore.com/status": data.status,
        "https://cellcore.com/meta_data": {
            type: kvs.cellcore_type,
            shopify_id: uuid.toString(),
            parent_id: data.id,
            access: data.type,
            status: data.status,
            code: kvs.code,
            referrer_token: kvs.referrer_token,
            rep_id: data.rep_id,
        },
        given_name: data.first_name,
        family_name: data.last_name,
        nickname: data.email,
        name: data.fullname,
        picture: kvs.picture,
        updated_at: data.last_login,
        email: data.email,
        sub: data.id,
        shopify_id: data.customer_id,
        tags: kvs.tags,
    };
    return newObj;
};

/**
 * @description - Call to get specific user's data with Cellcore UUID
 * @param id - User's UUID
 * @param signal - Abort Signal
 * @returns {*} - User's Data
 */
export const getUserDataByUUID = async (uuid) => {
    let result = await fetch(
        `https://api.cellcorebiosciences.com/api/user/get?` +
            new URLSearchParams({
                id: uuid,
            }),
    ).catch((error) => console.error("error", error));
    const json = await result.json();
    const kvs = convertKVArray(json.kv);
    if (!json || !kvs) {
        console.error("No User JSON or KV Array Found By UUID/Unable To Make New User Object");
        return null;
    }
    const newUserObj = await makeNewUserObject(json, kvs, uuid);
    return newUserObj;
};

/**
 * @description - Call to get specific user's data with Shopify ID
 * @param id - User's Shopify ID
 * @param signal - Abort Signal
 * @returns {*} - User's Data
 */
export const getUserDataByShopifyID = async (id, signal) => {
    const formData = new FormData();
    formData.append("id", id);
    try {
        const response = await fetch("https://api.cellcorebiosciences.com/api/shopify/user.shopifyid.get", {
            method: "POST",
            body: formData,
            redirect: "follow",
            signal: signal,
        });
        if (!response) return;
        const result = await response.json();
        return result;
    } catch (error) {
        console.error("Error Getting User Information", error);
    }
};
