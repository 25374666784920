import React from "react";
import { Helmet } from "react-helmet";

export default function HelmetWrap(props) {
    const page = props.pageInfo;
    return (
        <Helmet>
            <title>{page && page.title ? page.title : "Cellcore Practitioner Dashboard"}</title>
            <meta name="description" content={page && page.description ? page.description : ``} />
        </Helmet>
    );
}
