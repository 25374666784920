// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createSlice, current } from "@reduxjs/toolkit";

import { convertTimestamp } from "./../../common/Functions/utilities";

const initialState = {
    user: {},
    loading: false,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        storeUser: (state, action) => {
            state.loading = true;
            const parsedUser = {};
            for (let key in action.payload) {
                if (key.includes("https://")) {
                    let newKey = key.split("cellcore.com/")[1];
                    if (newKey === "sub") {
                        newKey = "user_id";
                    }
                    parsedUser[newKey] = action.payload[key];
                }
                parsedUser[key] = action.payload[key];
            }
            if ("updated_at" in parsedUser && parsedUser?.updated_at?.length > 0) {
                parsedUser.last_known_activity_date = convertTimestamp(parsedUser.updated_at);
            }
            state.user = parsedUser;
            state.loading = false;
        },
        updateUser: (state, action) => {
            state.user = {
                ...current(state).user,
                ...action.payload,
            };
        },
        updateActivityDate: (state, action) => {
            if ("last_known_activity_date" in current(state).user) {
                state.user.last_known_activity_date = convertTimestamp(new Date());
            }
        },
        updateUserPicture: (state, action) => {
            state.user.picture = action.payload;
        },
        updateShopifyData: (state, action) => {
            state.user.shopifyData = action.payload;
        },
        updateUserKVs: (state, action) => {
            state.user = { ...current(state).user, kvs: action.payload };
        },
    },
});

export const { storeUser, updateUser, updateActivityDate, updateUserPicture, updateShopifyData, updateUserKVs } =
    authSlice.actions;

export default authSlice.reducer;
