import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    area: { id: "", name: "" },
    display_date: { start: "", end: "" },
    start_date: { start: "", end: "" },
    date: { start: "", end: "" },
    selected_date: {},
    month_display_date: { start: "", end: "" },
    month_start_date: {},
    month_date: { start: "", end: "" },
    month_selected_date: {},
    group_by: "month",
    switched: false,
};

export const salesReportSlice = createSlice({
    name: "sales_reports",
    initialState,
    reducers: {
        newArea: (state, action) => {
            state.area = action.payload;
        },
        newDate: (state, action) => {
            state.date = action.payload;
        },
        newGroupBy: (state, action) => {
            state.group_by = action.payload;
        },
        newMonthDate: (state, action) => {
            state.month_date = action.payload;
        },
        newMonthStartDate: (state, action) => {
            state.month_start_date = action.payload;
        },
        newDisplayDate: (state, action) => {
            state.display_date = action.payload;
        },
        newMonthDisplayDate: (state, action) => {
            state.month_display_date = action.payload;
        },
        newStartDate: (state, action) => {
            state.start_date = action.payload;
        },
        newSelectedDate: (state, action) => {
            state.selected_date = action.payload;
        },
        newMonthSelected: (state, action) => {
            state.month_selected_date = action.payload;
        },
        triggerSwitch: (state, action) => {
            state.switched = action.payload;
        },
    },
});

export const {
    newArea,
    newDate,
    newGroupBy,
    newMonthDate,
    triggerSwitch,
    newDisplayDate,
    newMonthDisplayDate,
    newStartDate,
    newSelectedDate,
    newMonthSelected,
    newMonthStartDate,
} = salesReportSlice.actions;

export default salesReportSlice.reducer;
