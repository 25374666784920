import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
// import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import staticRoutes from "./static";
import useGoogleAnalytics from "../utils/useGoogleAnalytics";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorComponent } from "../../common/ErrorComponent/ErrorComponent";
import { Error404 } from "./Error404";

export let AdminRoute = ({ component: Component, routes, ...rest }) => {
    const user = useSelector((state) => state.auth.user);
    return (
        <Route
            {...rest}
            render={(props) => {
                if (
                    (user["https://cellcore.com/access"] === "7" && user["https://cellcore.com/status"] === "3") ||
                    (user["https://cellcore.com/access"] === "5" && user["https://cellcore.com/status"] === "3")
                ) {
                    return (
                        <ErrorBoundary FallbackComponent={ErrorComponent}>
                            <Component {...props} routes={routes} {...rest} />
                        </ErrorBoundary>
                    );
                }
                return <Redirect to="/dashboard" />;
            }}
        />
    );
};

export let PractRoute = ({ component: Component, routes, ...rest }) => {
    const user = useSelector((state) => state.auth.user);
    return (
        <Route
            {...rest}
            render={(props) => {
                if (
                    (user["https://cellcore.com/access"] === "3" && user["https://cellcore.com/status"] === "3") ||
                    (user["https://cellcore.com/access"] === "5" && user["https://cellcore.com/status"] === "3") ||
                    (user["https://cellcore.com/access"] === "7" && user["https://cellcore.com/status"] === "3") ||
                    (user["https://cellcore.com/access"] === "6" && user["https://cellcore.com/status"] === "3") ||
                    (user["https://cellcore.com/access"] === "4" && user["https://cellcore.com/status"] === "3")
                ) {
                    return (
                        <ErrorBoundary FallbackComponent={ErrorComponent}>
                            <Component {...props} routes={routes} {...rest} />
                        </ErrorBoundary>
                    );
                }
                return <Redirect to="/dashboard" />;
            }}
        />
    );
};

export let PatientRoute = ({ component: Component, routes, ...rest }) => {
    const user = useSelector((state) => state.auth.user);
    return (
        <Route
            {...rest}
            render={(props) => {
                if (user["https://cellcore.com/access"] === "2") {
                    return (
                        <ErrorBoundary FallbackComponent={ErrorComponent}>
                            <Component {...props} routes={routes} {...rest} />
                        </ErrorBoundary>
                    );
                    // return <Redirect to="https://cellcore.com" />;
                }

                return <Redirect to="/dashboard" />;
                // return <Redirect to="https://cellcore.com" />;
            }}
        />
    );
};

export let SalesRoute = ({ component: Component, routes, ...rest }) => {
    const user = useSelector((state) => state.auth.user);
    return (
        <Route
            {...rest}
            render={(props) => {
                if (
                    (user["https://cellcore.com/access"] === "7" && user["https://cellcore.com/status"] === "3") ||
                    (user["https://cellcore.com/access"] === "6" && user["https://cellcore.com/status"] === "3") ||
                    (user["https://cellcore.com/access"] === "4" && user["https://cellcore.com/status"] === "3")
                ) {
                    return (
                        <ErrorBoundary FallbackComponent={ErrorComponent}>
                            <Component {...props} routes={routes} {...rest} />
                        </ErrorBoundary>
                    );
                }

                return <Redirect to="/dashboard" />;
            }}
        />
    );
};

// Need update the redirects to proper endpoint
// Need to create a route redirect for the "/" to the proper place when people login based of user permissions.

export const AppRoutes = () => {
    useGoogleAnalytics();
    return (
        <>
            <Switch>
                {staticRoutes.publicRoutes.map((element, key) => {
                    return <Route key={key} {...element} />;
                })}

                {/* {staticRoutes.patientRoutes.map((element, key) => {
                    return <PatientRoute key={key} {...element} />;
                })} */}

                {staticRoutes.adminRoutes.map((element, key) => {
                    return <AdminRoute key={key} {...element} />;
                })}

                {staticRoutes.practRoutes.map((element, key) => {
                    // console.log("element", element, key);
                    return <PractRoute key={key} {...element} />;
                })}

                {staticRoutes.salesRoutes.map((element, key) => {
                    return <SalesRoute key={key} {...element} />;
                })}
                <Route>
                    <Error404 />
                </Route>
            </Switch>
        </>
    );
};
