const fileUpload = (file, user_id, container) => {
    let formData = new FormData();
    formData.append("file", file);
    const url = `https://cellcore-reports.azure-api.net/api/file_upload?uuid=${user_id}&container=${container}&code=d9a365cc375e428ea3735c51ec8b703f`;
    const headers = {
        "Ocp-Apim-Subscription-Key": "d9a365cc375e428ea3735c51ec8b703f",
    };

    const createFile = fetch(url, {
        method: "POST",
        headers: headers,
        body: formData,
    })
        .then((res) => {
            return res.json();
        })
        .then((result) => {
            return result;
        })
        .catch((error) => console.error(error));
    return createFile;
};

export default fileUpload;
