const dateConverter = (isoDate) => {
    let date = new Date(`${isoDate}`);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
        dt = "0" + dt;
    }
    if (month < 10) {
        month = "0" + month;
    }
    if (!month || !dt || !year) {
        return `-`;
    }
    return `${month}/${dt}/${year}`;
};

export default dateConverter;
