import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    moderation: {
        //Alert keywords for our custom filter to find
        alertKeywords: [
            "vaccine",
            "vaccines",
            "vaccinated",
            "vaccination",
            "cure",
            "cured",
            "curing",
            "cancer",
            "jab",
            "covid",
        ],
        //Allowed profane words that the bad-words filter can bypass
        allowProfaneWords: [
            "anus",
            "butthole",
            "crap",
            "enema",
            "fart",
            "knob",
            "knobs",
            "oriface",
            "orifice",
            "orifiss",
            "penis",
            "rectum",
            "semen",
            "sex",
            "turd",
            "vagina",
            "vulva",
            "scrotum",
            "testicle",
            "breasts",
            "foreskin",
            "poop",
            "preteen",
            "screw",
        ],
        //Added profane words to the bad-words filter
        addedProfaneWords: ["wetback", "n1gg@", "nigg@", "fuckers"],
    },
    defaultPermissionsStructure: {
        //Permissions and what they mean are set here
        //Numerical permissons guide: 0000000000
        //digit 0 - admin only posting - 0/1 - false/true
        //digit 1 - admin only viewing - 0/1 - false/true
        //digit 2-10 - unused - 0/1 - false/true
        numerical: "0000000000",
        restrictPostingToAdmin: false,
        restrictViewingToAdmin: false,
    },
    nav: {
        conversation_ids: [],
        conversationsByUser: [],
        activeChannels: [],
        defaultChannels: [1, 2, 339],
        unreadMessages: {},
        secondaryNavBar: {
            title: "",
            sideBar: {
                sections: {},
            },
        },
        communityNavBar: {
            search: {
                users: [],
            },
            sideBar: {
                sections: {
                    admin: {
                        title: "Admin",
                        adminRequired: true,
                        messageType: "system",
                        children: {
                            "Reported Messages": {
                                topic: "Reported Messages",
                                description: "Reported Messages from the Community go here for approval.",
                                icon: "flag",
                                conversation_id: 0,
                                unread_messages: 0,
                                showExternal: false,
                            },
                            "Admin Tools": {
                                topic: "Admin Tools",
                                description: "Administrative Tools are here.",
                                icon: "handyman",
                                conversation_id: 0,
                                unread_messages: 0,
                                showExternal: true,
                            },
                        },
                    },
                    system: {
                        title: "System",
                        adminRequired: false,
                        messageType: "system",
                        children: {
                            "Saved Messages": {
                                topic: "Saved Messages",
                                description: "Any messages you save will show here.",
                                icon: "bookmark_border",
                                conversation_id: 0,
                                unread_messages: 0,
                            },
                            Mentions: {
                                topic: "Mentions",
                                description: "Anytime someone mentions you it will show here.",
                                icon: "alternate_email",
                                conversation_id: 0,
                                unread_messages: 0,
                            },
                            "New Message Feed": {
                                topic: "New Message Feed",
                                description: "Any messages you haven't seen will show here",
                                icon: "new_releases",
                                conversation_id: 0,
                                unread_messages: 0,
                            },
                        },
                    },
                    channels: {
                        title: "Channels",
                    },
                    direct_messages: {
                        title: "Direct Messages",
                        children: {},
                    },
                },
            },
            directMessagesNoStatus: [],
            channelsNoStatus: [],
        },
    },
    //Don't add anything into this default channel object - but keep it here
    //Data will be added on load for whatever is in is_default from API in conversation
    defaultChannelOnLoad: {
        section: "",
        channel: "",
        conversation_id: -1,
        messageType: "",
        showExternal: false,
        description: "",
    },
    //Selected is the default channel on load
    selected: {
        section: "",
        channel: "",
        conversation_id: -1,
        messageType: "normal",
        showExternal: false,
        description: "",
    },
    defaultNoMessages: {
        conversation_id: 0,
        id: 0,
        parent_id: null,
        status: 1,
        ts: new Date().toISOString(),
        dateObj: {},
        user_id: "0",
        text: "This will be the first message to this person or group.",
        type: "first",
    },
    createNewChat: false,
    newChatsCreated: 0,
    newChatUsers: [],
    practitionersCustomers: [],
    currentParentMessage: {},
    showThreadDrawer: false,
    showProfileDrawer: false,
    showLoader: false,
    cursorInWindow: true,
    showMessages: true,
    deleteFromSideBar: 0,
    chatWindowName: "",
    sideBarSelected: {
        boolean: false,
    },
    messageWindow: {
        children: {},
    },
    unformattedWindowMessages: [],
    threadWindow: {
        children: {},
    },
    unformattedThreadMessages: [],
    hasNavigated: false,
    hasNavigatedAway: false,
    //All known chat users that have DMs with current user
    knownChatUsers: [],
    //All known users that api has found info for - for finding user information
    allKnownCommunityUsers: {},
    chatConversations: [],
    savedMessages: {},
    messageStatuses: {
        deleted: {
            type: "deleted",
            statuses: [0],
        },
        approved: {
            type: "approved",
            statuses: [1, 3],
        },
        rejected: {
            type: "rejected",
            statuses: [4],
        },
        pending: {
            type: "pending",
            statuses: [5],
        },
    },
    scrollToMessage: 0,
    scrollToMessageMessageType: "message",
    scrollToMessageThread: 0,
    easterEggs: {
        useEggs: true,
        eggs: {
            replaceWords: [
                {
                    words: ["Voldemort", "voldemort", "VOLDEMORT"],
                    replaceWith: "He Who Must Not Be Named",
                },
            ],
        },
    },
    getUsersInThisConvo: 0,
    convosCalledAlready: [],
    channelsToRemoveFromActive: [],
    editMessage: {},
    editMessageCount: 0,
    unreadByConversation: {},
    unreadCallCount: 0,
    navigateToThisMessage: {},
    pinnedMessages: [],
    unpinCount: 0,
    snackbar: {
        show: false,
        message: "",
        severity: "info",
    },
};

export const communitySlice = createSlice({
    name: "community",
    initialState,
    reducers: {
        resetCommunity: (state, action) => {
            state.messageWindow.children = {};
            state.unformattedWindowMessages = [];
            state.threadWindow.children = {};
            state.unformattedThreadMessages = [];
            state.hasNavigated = false;
            state.nav.conversation_ids = [];
            state.showMessages = true;
            state.showThreadDrawer = false;
            state.showProfileDrawer = false;
            state.currentParentMessage = {};
            state.selected = current(state).defaultChannelOnLoad;
            state.newChatUsers = [];
            state.newChatsCreated = 0;
            state.chatWindowName = "";
            state.hasNavigatedAway = true;
            state.nav.unreadMessages = {};
            state.channelsToRemoveFromActive = [];
            state.unreadByConversation = {};
            state.unreadCallCount = 0;
            state.pinnedMessages = [];
        },
        changeMessageWindow: (state, action) => {
            if (Object.keys(action.payload).includes("channelMessages")) {
                state.messageWindow.children = action.payload.channelMessages;
            } else {
                state.messageWindow.children = action.payload;
            }
            if (Object.keys(action.payload).includes("pinnedMessages")) {
                state.pinnedMessages = action.payload.pinnedMessages;
            }
            state.showMessages = true;
            state.showLoader = false;
        },
        changeWindowUnformatted: (state, action) => {
            state.unformattedWindowMessages = action.payload;
        },
        changeWindowUnformattedAndCount: (state, action) => {
            state.unformattedWindowMessages = action.payload.children;
            const navBar = state.nav.communityNavBar.sideBar.sections;
            if (
                navBar &&
                navBar[action.payload?.indicators?.section] &&
                navBar[action.payload.indicators.section].children
            ) {
                let counter =
                    navBar[action.payload.indicators.section].children[action.payload.indicators.channel]
                        .unread_messages;
                if (counter && counter >= 0) {
                    counter = action.payload.indicators.unread_messages;
                }
            }
        },
        changeMessageWindowSelected: (state, action) => {
            state.selected = { ...state.selected, ...action.payload.selected };
            state.hasNavigated = true;
            state.unformattedWindowMessages = action.payload.children;
        },
        changeNewMessagesCountAndSelected: (state, action) => {
            const navBar = state.nav.communityNavBar.sideBar.sections;
            const child = navBar[action.payload.newMessages.section].children[action.payload.newMessages.channel];
            if (child && child.unread_messages && child.unread_messages >= 0) {
                child.unread_messages = action.payload.newMessages.unread_messages;
            }
            state.selected = { ...state.selected, ...action.payload.selected };
            state.hasNavigated = true;
            if (Object.keys(action.payload).includes("scrollToMessage")) {
                state.scrollToMessage = action.payload.scrollToMessage;
                state.scrollToMessageMessageType = action.payload.messageType;
                state.scrollToMessageThread = action.payload.scrollToMessageThread;
            } else {
                state.scrollToMessage = 0;
                state.scrollToMessageMessageType = "message";
                state.scrollToMessageThread = 0;
            }
            if (Object.keys(action.payload).includes("conversation_id")) {
                state.getUsersInThisConvo = action.payload.conversation_id;
            }
        },
        changeThreadWindow: (state, action) => {
            state.threadWindow.children = action.payload.formatted;
            state.unformattedThreadMessages = action.payload.unformatted;
        },
        changeThreadUnformatted: (state, action) => {
            state.unformattedThreadMessages = action.payload;
        },
        changeTargetedMessage: (state, action) => {
            if ("dateName" in action.payload) {
                const messages = state.messageWindow.children[action.payload.dateName];
                for (let m in messages) {
                    const message = messages[m];
                    if (message.id === action.payload.reaction.message_id) {
                        if (Object.keys(action.payload).includes("reaction")) {
                            const reactions = message.reactions;
                            const index = reactions.findIndex((r) => r.id === action.payload.reaction.id);
                            const reaction = reactions[index];
                            if (reaction) {
                                reaction.status = action.payload.status;
                            } else {
                                if (message.reactions.length === 0) {
                                    message.reactions = [action.payload.reaction];
                                } else if (message.reactions.length > 0) {
                                    message.reactions.push(action.payload.reaction);
                                }
                            }
                        }
                    }
                }
            }
        },
        updateConversationIds: (state, action) => {
            state.nav.conversation_ids = action.payload.ids;
            state.nav.conversationsByUser = action.payload.conversationsByUser;
            if (Object.keys(action.payload).includes("channelsActive")) {
                state.nav.activeChannels = action.payload.channelsActive;
            }
        },
        updateJustConversationsByUser: (state, action) => {
            state.nav.conversationsByUser = action.payload.conversationsByUser;
        },
        appendMessageToWindow: (state, action) => {
            if (typeof state.messageWindow?.children[Object.keys(action.payload)[0]] !== "undefined") {
                state.messageWindow.children[Object.keys(action.payload)[0]].push(
                    action.payload[Object.keys(action.payload)[0]][0],
                );
            } else {
                state.messageWindow.children[Object.keys(action.payload)[0]] =
                    action.payload[Object.keys(action.payload)[0]];
            }
        },
        changeNewMessagesCount: (state, action) => {
            const navBar = state.nav.communityNavBar.sideBar.sections;
            navBar[action.payload.section].children[action.payload.channel].unread_messages =
                action.payload.unread_messages;
            if (Object.keys(action.payload).includes("conversation_id")) {
                state.getUsersInThisConvo = action.payload.conversation_id;
            }
        },
        updateSideBar: (state, action) => {
            state.nav.communityNavBar.sideBar.sections = action.payload.sectionsData.sections;
            state.nav.communityNavBar.directMessagesNoStatus = action.payload.directMessagesNoStatus;
            state.nav.communityNavBar.channelsNoStatus = action.payload.channelsNoStatus;
        },
        basicFunction: (state, action) => {
            state = action.payload;
        },
        setNewChat: (state, action) => {
            state.newChatUsers = action.payload.newChatUsers;
            state.createNewChat = action.payload.createNewChat;
            state.cursorInWindow = action.payload.cursorInWindow;
            state.newChatsCreated = current(state).newChatsCreated + 1;
        },
        updateChatSelected: (state, action) => {
            state.selected = { ...state.selected, ...action.payload };
            state.sideBarSelected.boolean = false;
            state.hasNavigated = true;
            if (Object.keys(action.payload).includes("conversation_id")) {
                state.getUsersInThisConvo = action.payload.conversation_id;
            }
        },
        updateCurrentThreadParent: (state, action) => {
            if (Object.keys(action.payload).includes("parent")) {
                state.currentParentMessage = action.payload.parent;
            } else {
                state.currentParentMessage = action.payload;
            }
            if (Object.keys(action.payload).includes("toggle")) {
                if (action.payload.toggle === false) {
                    state.threadWindow.children = {};
                }
                state.showThreadDrawer = action.payload.toggle;
            }
        },
        eraseCurrentThreadParent: (state, action) => {
            state.currentParentMessage = {};
            if (Object.keys(action.payload).includes("toggle")) {
                if (action.payload.toggle === false) {
                    state.threadWindow.children = {};
                }
                state.showThreadDrawer = action.payload.toggle;
            }
        },
        showMessagesToggle: (state, action) => {
            if (Object.keys(action.payload).includes("boolean")) {
                state.showMessages = action.payload.boolean;
                if (!action.payload.boolean) {
                    state.sideBarSelected.boolean = true;
                }
            } else {
                state.showMessages = action.payload;
            }
        },
        showThreadDrawerToggle: (state, action) => {
            state.showThreadDrawer = action.payload;
        },
        showProfileDrawerToggle: (state, action) => {
            state.showProfileDrawer = action.payload;
        },
        changeThreadWindowAndParent: (state, action) => {
            if (Object.keys(action.payload).includes("parent")) {
                state.currentParentMessage = action.payload.parent;
            }
            if (Object.keys(action.payload).includes("unformatted")) {
                state.unformattedThreadMessages = action.payload.unformatted;
            }
            if (Object.keys(action.payload).includes("parentDetails")) {
                const correctDate = state.messageWindow.children[action.payload?.parentDetails?.dateName];
                if (correctDate) {
                    correctDate.forEach((child, childIndex) => {
                        if (child.id === action.payload.parent.id) {
                            const replies = child.replies + action.payload.parentDetails.change;
                            state.messageWindow.children[action.payload.parentDetails.dateName][childIndex].replies =
                                replies;
                            const mainWindowIndex = state.unformattedWindowMessages.findIndex(
                                (m) => m.id === action.payload.parent.id,
                            );
                            if (mainWindowIndex !== -1) {
                                state.unformattedWindowMessages[mainWindowIndex].replies = replies;
                            }
                        }
                    });
                }
            }
        },
        addToThreadMessages: (state, action) => {
            const messageAlreadyExists = state.unformattedThreadMessages.filter(function (m) {
                return action.payload?.newMessage?.id === m.id;
            });
            if (messageAlreadyExists.length === 0) {
                state.threadWindow.children = action.payload.formatted;
                state.unformattedThreadMessages = action.payload.unformatted;
            }
        },
        deleteFromSideBar: (state, action) => {
            state.deleteFromSideBar = action.payload;
        },
        updateDirectMessagesSidebar: (state, action) => {
            state.nav.communityNavBar.sideBar.sections.direct_messages.children = action.payload;
        },
        setChatWindowName: (state, action) => {
            state.chatWindowName = action.payload;
        },
        setHasNavigated: (state, action) => {
            state.hasNavigated = action.payload;
        },
        updateAllKnownCommunityUsers: (state, action) => {
            if (action.payload.users) {
                state.allKnownCommunityUsers = { ...action.payload.users, ...current(state).allKnownCommunityUsers };
            } else {
                if (action.payload.length >= 0) {
                    const convertArrayToObject = (array, key) => {
                        const initialValue = {};
                        return array.reduce((obj, item) => {
                            return {
                                ...obj,
                                [item[key]]: item,
                            };
                        }, initialValue);
                    };
                    state.allKnownCommunityUsers = {
                        ...convertArrayToObject(action.payload, "uuid"),
                        ...current(state).allKnownCommunityUsers,
                    };
                }
            }
        },
        updateKnownChatUsersAndConversations: (state, action) => {
            state.knownChatUsers = action.payload.users;
            state.chatConversations = action.payload.conversations;
        },
        addToAlertKeywords: (state, action) => {
            state.moderation.alertKeywords = [...current(state).moderation.alertKeywords, ...action.payload];
        },
        resetScrollToMessage: (state, action) => {
            state.scrollToMessage = action.payload.scrollToMessage;
            state.scrollToMessageMessageType = action.payload.scrollToMessageMessageType;
            state.scrollToMessageThread = action.payload.scrollToMessageThread;
        },
        changeGetUsersInThisConvo: (state, action) => {
            state.getUsersInThisConvo = action.payload;
        },
        updateConvosCalledAlready: (state, action) => {
            state.convosCalledAlready.push(action.payload);
        },
        updateUnreadMessages: (state, action) => {
            state.nav.unreadMessages = action.payload;
        },
        incrementIndividualUnreadMessages: (state, action) => {
            if (Object.keys(state.nav.unreadMessages).includes(action.payload.conversation_id)) {
                state.nav.unreadMessages[action.payload.conversation_id].unread_messages =
                    current(state).nav.unreadMessages[action.payload.conversation_id].unread_messages + 1;
            } else {
                state.nav.unreadMessages[action.payload.conversation_id] = {
                    unread_messages: 1,
                };
            }
        },
        resetIndividualUnreadMessages: (state, action) => {
            if (Object.keys(state.nav.unreadMessages).includes(action.payload.conversation_id)) {
                state.nav.unreadMessages[action.payload.conversation_id].unread_messages = 0;
            } else {
                state.nav.unreadMessages[action.payload.conversation_id] = { unread_messages: 0 };
            }
        },
        addToChannelsToBeRemovedFromActive: (state, action) => {
            state.channelsToRemoveFromActive = action.payload;
        },
        changeEditMessage: (state, action) => {
            state.editMessage = action.payload;
            state.editMessageCount = current(state).editMessageCount + 1;
        },
        updateUnreadByConversation: (state, action) => {
            state.unreadByConversation = action.payload;
            state.pinnedMessages = [];
        },
        updateNavigateToThisMessage: (state, action) => {
            state.unreadByConversation = {};
            state.navigateToThisMessage = action.payload;
        },
        updateDefaultChannelOnLoad: (state, action) => {
            state.defaultChannelOnLoad = action.payload;
            state.selected = {
                section: "channels",
                channel: action.payload.topic.toLowerCase(),
                conversation_id: action.payload.id,
                messageType: "normal",
                showExternal: false,
                description: "",
            };
            //UPDATING CURRENTLY SELECTED CHANNEL ON LOAD TO DEFAULT CHANNEL
            state.sideBarSelected.boolean = false;
            state.hasNavigated = true;
            state.getUsersInThisConvo = action.payload.id;
        },
        resetPinnedMessages: (state, action) => {
            state.pinnedMessages = [];
        },
        incrementPinnedCount: (state, action) => {
            state.unpinCount = current(state).unpinCount + 1;
        },
        incrementUnreadCallCount: (state, action) => {
            state.unreadCallCount = current(state).unreadCallCount + 1;
        },
        changeSnackBar: (state, action) => {
            state.snackbar = action.payload;
        },
    },
});

export const {
    changeWindowUnformatted,
    changeThreadUnformatted,
    updateConversationIds,
    changeMessageWindow,
    changeTargetedMessage,
    changeMessageWindowSelected,
    appendMessageToWindow,
    changeNewMessagesCount,
    changeWindowUnformattedAndCount,
    changeNewMessagesCountAndSelected,
    updateSideBar,
    setNewChat,
    updateChatSelected,
    updateCurrentThreadParent,
    eraseCurrentThreadParent,
    showThreadDrawerToggle,
    showMessagesToggle,
    changeThreadWindow,
    changeThreadWindowAndParent,
    deleteFromSideBar,
    updateDirectMessagesSidebar,
    addToThreadMessages,
    setChatWindowName,
    resetCommunity,
    updateKnownChatUsersAndConversations,
    showProfileDrawerToggle,
    addToAlertKeywords,
    resetScrollToMessage,
    changeGetUsersInThisConvo,
    updateConvosCalledAlready,
    updateUnreadMessages,
    incrementIndividualUnreadMessages,
    resetIndividualUnreadMessages,
    addToChannelsToBeRemovedFromActive,
    changeEditMessage,
    updateUnreadByConversation,
    updateJustConversationsByUser,
    updateNavigateToThisMessage,
    updateDefaultChannelOnLoad,
    resetPinnedMessages,
    incrementPinnedCount,
    incrementUnreadCallCount,
    updateAllKnownCommunityUsers,
    changeSnackBar,
} = communitySlice.actions;

export default communitySlice.reducer;

export const { communityStore } = communitySlice.actions;
