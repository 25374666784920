import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import SideBar from "./../../common/SideBar/SideBar";
import SideBarChat from "./../../features/community/components/SideBarChat";
import SideBarToolsAdmin from "../../features/tools/admin/components/SideBarToolsAdmin";
import SideBarLearningAdmin from "../../features/learning-center/admin/components/SideBarLearningAdmin";
import LearningCenterSideBar from "../../features/learning-center/components/LearningCenterSideBar";

import { styled } from "@mui/material/styles";
//import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MuiDrawer from "@mui/material/Drawer";

const openedSecondaryNavMixin = (theme, mainNavOpen) => ({
    width: theme.widths.nav.secondary.desktop,
    maxWidth: theme.widths.nav.secondary.desktop,
    [theme.breakpoints.down("md")]: {
        width: theme.widths.nav.secondary.tablet,
        maxWidth: theme.widths.nav.secondary.tablet,
    },
    [theme.breakpoints.down("sm")]: {
        width: theme.widths.nav.secondary.mobile,
        maxWidth: theme.widths.nav.secondary.mobile,
    },
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.common.white,
    overflowX: "hidden",
    borderRight: "none",
    boxShadow: "none",
});

const closedSecondaryNavMixin = (theme, mainNavOpen) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    backgroundColor: theme.palette.common.white,
    width: `calc(${theme.spacing(7)} + 1px)`,
    borderRight: "none",
    boxShadow: "none",
    [theme.breakpoints.up("md")]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
});

const SecondaryNavDrawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "drawerOpen" && prop !== "mainNavOpen",
})(({ theme, drawerOpen, mainNavOpen }) => ({
    "&&": {
        width: theme.widths.nav.secondary.desktop,
        [theme.breakpoints.down("md")]: {
            width: theme.widths.nav.secondary.tablet,
        },
        [theme.breakpoints.down("sm")]: {
            width: theme.widths.nav.secondary.mobile,
        },
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.blue_dark,
        position: "relative",
        flexDirection: "space-between",
        padding: 0,
        ...(drawerOpen && {
            ...openedSecondaryNavMixin(theme),
            "& .MuiDrawer-paper": openedSecondaryNavMixin(theme),
        }),
        ...(!drawerOpen && {
            ...closedSecondaryNavMixin(theme),
            "& .MuiDrawer-paper": closedSecondaryNavMixin(theme),
        }),
        "& > div": {
            transition: theme.transitions.create("all", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoints.up("md")]: {
                ...(mainNavOpen && {
                    left: theme.widths.nav.main.desktop,
                    [theme.breakpoints.down("md")]: {
                        left: theme.widths.nav.main.tablet,
                    },
                    [theme.breakpoints.down("sm")]: {
                        left: theme.widths.nav.main.mobile,
                    },
                }),
            },
            [theme.breakpoints.down("md")]: {
                left: 0,
            },
        },
    },
}));

const SecondNavBar = (props) => {
    const auth = useSelector((state) => state.auth);
    const globalState = useSelector((state) => state.global);

    const [currentBar, setCurrentBar] = useState("sidebar");

    const closeSecondNavDrawer = () => {
        props.navCloseSecondary();
    };

    const checkVariant = () => {
        if (globalState.nav.secondaryNavBar.title?.toLowerCase() === "community") {
            if (globalState.isMobile) {
                return "temporary";
            }
            return "permanent";
        } else {
            if (globalState.isMobile) {
                return "temporary";
            }
            return "permanent";
        }
    };

    const checkOpen = () => {
        if (globalState.nav.secondaryNavBar.title?.toLowerCase() === "community") {
            if (globalState.isMobile) {
                return props.secondNavState.open;
            }
            return true;
        } else {
            if (globalState.isMobile) {
                return props.secondNavState.open;
            }
            return true;
        }
    };

    useEffect(() => {
        const navTitle = globalState.nav?.secondaryNavBar?.title?.toLowerCase();
        if (navTitle !== currentBar) {
            setCurrentBar(navTitle);
        }
    }, [globalState.nav.secondaryNavBar.title]);

    if (auth.user?.access) {
        return (
            <SecondaryNavDrawer
                variant={checkVariant()}
                anchor="left"
                open={checkOpen()}
                drawerOpen={checkOpen()}
                mainNavOpen={props.mainNavState.open}
                ModalProps={{
                    keepMounted: true,
                }}
                onClose={closeSecondNavDrawer}
            >
                {currentBar !== "community" &&
                    currentBar !== "learning center admin" &&
                    currentBar !== "tools admin" &&
                    currentBar !== "learning center" && (
                        <SideBar
                            barData={props.data}
                            routes={globalState.nav.secondaryNavBar}
                            secondaryOpen={props.secondNavState.open}
                            backToMain={props.backToMain}
                            onSecondaryNavClick={props.onSecondaryNavClick}
                        />
                    )}
                {currentBar === "community" && (
                    <SideBarChat
                        barData={props.data}
                        routes={globalState.nav.secondaryNavBar}
                        secondaryOpen={props.secondNavState.open}
                        userData={auth.user}
                        isAdmin={
                            (parseInt(auth.user.access) === 5 || parseInt(auth.user.access) === 7) &&
                            parseInt(auth.user.status) === 3
                                ? true
                                : false
                        }
                        backToMain={props.backToMain}
                        onSecondaryNavClick={props.onSecondaryNavClick}
                    />
                )}
                {currentBar === "learning center admin" && (
                    <SideBarLearningAdmin
                        barData={props}
                        routes={props.routes}
                        secondaryOpen={props.secondNavState.open}
                        backToMain={props.backToMain}
                        onSecondaryNavClick={props.onSecondaryNavClick}
                    />
                )}
                {currentBar === "tools admin" && (
                    <SideBarToolsAdmin
                        barData={props.data}
                        routes={props.routes}
                        secondaryOpen={props.secondNavState.open}
                        backToMain={props.backToMain}
                        onSecondaryNavClick={props.onSecondaryNavClick}
                    />
                )}
                {currentBar === "learning center" && (
                    <LearningCenterSideBar
                        barData={props.data}
                        routes={globalState.nav.secondaryNavBar}
                        secondaryOpen={props.secondNavState.open}
                        backToMain={props.backToMain}
                        onSecondaryNavClick={props.onSecondaryNavClick}
                    />
                )}
            </SecondaryNavDrawer>
        );
    } else {
        return null;
    }
};

export default SecondNavBar;
