import React from "react";
import CardComponent from "../CardComponent/CardComponent";
//BASIC UTILITY FUNCTIONS THAT CAN BE USED THROUGHOUT THE SITE

//OBJECT FUNCTIONS
//OBJECT FUNCTIONS
//OBJECT FUNCTIONS

//CHECKS TO SEE IF OBJECT PASSED IS ACTUALLY AN OBJECT
const isObject = (object) => {
    return object != null && typeof object === "object";
};

//CHECK IF OBJECT IS THE SAME - SHALLOW CHECK
//ONLY CHECKS THAT NAMES MATCH AND IF SAME AMOUNT OF KEYS EXIST
//MUCH LESS EXPENSIVE THAN CHECKING ALL DATA IN OBJECT - USE IF POSSIBLE
export function isObjectEqualShallow(object1, object2) {
    if (object1 && object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (let key of keys1) {
            if (object1[key] !== object2[key]) {
                return false;
            }
        }
        return true;
    } else {
        console.error("isObjectEqualShallow - Did not provide object1, and object2 to function call");
    }
    return null;
}

//CHECK IF OBJECT IS THE SAME - DEEP CHECK
//GOES THROUGH ALL DATA IN OBJECT TO SEE IF IT'S THE SAME AS OTHER OBJECT
//EXPENSIVE COMPUTATIONALLY
export function isObjectEqualDeep(object1, object2) {
    if (object1 && object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = isObject(val1) && isObject(val2);
            if ((areObjects && !isObjectEqualDeep(val1, val2)) || (!areObjects && val1 !== val2)) {
                return false;
            }
        }
        return true;
    } else {
        console.error("isObjectEqualDeep - Did not provide object1, and object2 to function call");
    }
    return null;
}

//ARRAY FUNCTIONS
//ARRAY FUNCTIONS
//ARRAY FUNCTIONS

//CHECK IF ARRAYS ARE THE SAME LENGTH, TYPE, ETC.
//FAIRLY SHALLOW CHECK
export function isArrayOfObjectsEqual(arr1, arr2) {
    if (arr1 && arr2) {
        if (arr1 === arr2) return true;
        if (arr1 == null || arr2 == null) return false;
        if (arr1.length !== arr2.length) return false;
        const newArr1 = [...arr1].sort();
        const newArr2 = [...arr2].sort();
        if (Object.keys(arr1[0]).includes("id") && Object.keys(arr2[0]).includes("id")) {
            newArr1.sort((a, b) => {
                return a.id - b.id;
            });
            newArr2.sort((a, b) => {
                return a.id - b.id;
            });
        }
        for (let i = 0; i < newArr1.length; ++i) {
            if (newArr1[i] !== newArr2[i]) return false;
        }
        return true;
    } else {
        console.error("isArrayOfObjectsEqual - Did not provide arr1, and arr2 to function call");
    }
    return null;
}

export function arrayIsEqualLength(arr1, arr2) {
    //RETURNS TRUE IF ARRAYS ARE OF SAME LENGTH - GOOD FOR BASIC CHECKS
    return arr1 && arr2 && arr1.length === arr2.length ? true : false;
}

export function arrayEquals(arr1, arr2) {
    //ARRAY IS EXACTLY THE SAME
    return (
        Array.isArray(arr1) &&
        Array.isArray(arr2) &&
        arr1.length === arr2.length &&
        arr1.every((val, index) => val === arr2[index])
    );
}

//REMOVES DUPLICATES FROM ARRAY OF OBJECTS BASED ON PROPERTY NAME PASSED
//PROPNAME - EXAMPLE - "id" - WILL CHECK ALL "ID'S" AND REMOVE DUPLICATES
export function removeDuplicateObjectsFromArray(array, propName) {
    if (!array) return [];
    if (!propName) return [...new Set(array)];
    return array.filter((v, i, a) => {
        return a.findIndex((v2) => v2[propName] === v[propName]) === i;
    });
}

//SCROLLING FUNCTIONS
//SCROLLING FUNCTIONS
//SCROLLING FUNCTIONS

//PASS ELEMENT TO CHECK AND SEE IF IT'S SCROLLED TO THE BOTTOM OR NOT
export function isScrolledToBottom(container) {
    if (
        container &&
        container.scrollHeight &&
        container.scrollHeight - container.scrollTop === container.clientHeight
    ) {
        return true;
    }
    return false;
}

//SCROLLS TO BOTTOM OF CONTAINER PROVIDED
//OVERRIDE HEIGHT IS DISTANCE FROM BOTTOM AT WHICH IT WILL NOT SCROLL
//FOR OVERRIDE HEIGHT CAN JUST USE TRUE BOOLEAN FOR WINDOW HEIGHT OR PROVIDE A NUMBER OF PIXELS
//OVERRIDE IS USEFUL FOR NOT SCROLLING WHEN A USER COULD BE LOOKING AT SOMETHING FAR ABOVE IN A CONTAINER
export function scrollToBottomOfContainer(container, overrideHeight) {
    if (typeof container === "string") {
        container = document.querySelector("[data-scroll-window=" + container + `]`);
    }
    if (container && container.clientHeight) {
        const distanceFromBottom = container.scrollHeight - container.scrollTop - container.clientHeight;
        if (overrideHeight && typeof overrideHeight === "number" && distanceFromBottom >= overrideHeight) {
            container.scrollTop = container.scrollHeight;
        } else if (
            overrideHeight &&
            typeof overrideHeight === "boolean" &&
            distanceFromBottom <= container.clientHeight
        ) {
            container.scrollTop = container.scrollHeight;
        } else if (!overrideHeight) {
            container.scrollTop = container.scrollHeight;
        }
    }
}

//SCROLLS TO TOP OF CONTAINER PROVIDED
export function scrollToTopOfContainer(container, overrideHeight) {
    if (typeof container === "string") {
        container = document.querySelector("[data-scroll-window=" + container + `]`);
    }
    if (container) {
        container.scrollTop = 0;
    }
}

//GETS STRING AFTER LAST INSTANCE OF CHARACTER PROVIDED
//GREAT FOR GETTING LAST PART OF URL AFTER LAST BACKSLASH
export function getAfterLastCharacter(string, character) {
    let str = string.split(character);
    return str[str.length - 1];
}

//FORMAT A DATE OBJECT INTO A STRING OF TIME WITH AM/PM BEING CORRECT
//USED BELOW IN CONVERTTIMESTAMP
const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + amOrPm;
};

//CONVERT A TIMESTRING INTO A USEFUL OBJECT
export function convertTimestamp(stamp) {
    const now = new Date();
    const nowDate = (`0` + now.getDate()).slice(-2);
    const nowMonth = (`0` + (now.getMonth() + 1)).slice(-2);
    const nowYear = now.getFullYear();
    const yesterday = new Date(Date.now() - 864e5);
    const yesterdayDate = (`0` + yesterday.getDate()).slice(-2);
    const yesterdayMonth = (`0` + (yesterday.getMonth() + 1)).slice(-2);
    const yesterdayYear = yesterday.getFullYear();
    const d: any = new Date(stamp);
    const date = (`0` + d.getDate()).slice(-2);
    const month = (`0` + (d.getMonth() + 1)).slice(-2);
    const weekdays = [`Sunday`, `Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`, `Saturday`];
    const newDateObj = {
        timestamp: Math.floor(d / 1000),
        day: d.getDay(),
        date: date,
        month: month,
        year: d.getFullYear(),
        hour: d.getHours(),
        minute: d.getMinutes(),
        second: d.getSeconds(),
        weekday: weekdays[d.getDay()],
        string: ``,
        timeString: formatAMPM(d),
        windowFormat: d.getFullYear() + month + date,
        justDate: month + `/` + date + `/` + d.getFullYear(),
    };
    if (`${newDateObj.year}${month}${date}` === `${nowYear}${nowMonth}${nowDate}`) {
        newDateObj.string = `Today`;
    } else if (`${newDateObj.year}${month}${date}` === `${yesterdayYear}${yesterdayMonth}${yesterdayDate}`) {
        newDateObj.string = `Yesterday`;
    } else {
        newDateObj.string = weekdays[newDateObj.day] + ` - ` + month + `/` + date + `/` + newDateObj.year;
    }
    return newDateObj;
}

export const getDaysBetween = (data) => {
    const now = new Date();
    const now2 = new Date();
    const thenString = data;
    const then = new Date(thenString);
    const then2 = new Date(thenString);
    if (then2.setHours(0, 0, 0, 0) === now2.setHours(0, 0, 0, 0)) {
        return `Today at ${then.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })}`;
    }
    const differenceInTime = then.getTime() - now.getTime();
    const differenceInDays = Math.abs(Math.round(differenceInTime / (1000 * 3600 * 24)));
    if (typeof differenceInDays === "number") {
        const lastText = differenceInDays === 1 ? ` day ago` : ` days ago`;
        return `Last reply ` + differenceInDays + lastText;
    }
    return ``;
};

export const shouldBeLive = (start, end) => {
    const startDate = new Date(new Date(start).setHours(0, 0, 0, 0)).getTime();
    const todayDate = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
    const endDate = new Date(new Date(end).setHours(0, 0, 0, 0)).getTime();
    return todayDate >= startDate && todayDate <= endDate;
};

//Send report returns the text response. Will need to convert to json if desired
export const sendReportTextReturn = async (obj, url) => {
    const myHeaders = new Headers();
    myHeaders.append("Ocp-Apim-Subscription-Key", "d9a365cc375e428ea3735c51ec8b703f");
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify(obj);
    const requestOptions: any = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };
    const response = await fetch(url, requestOptions);
    const result = await response.text();
    return result;
};
export const sendReport = async (obj, url, signal) => {
    const myHeaders = new Headers();
    myHeaders.append("Ocp-Apim-Subscription-Key", "d9a365cc375e428ea3735c51ec8b703f");
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify(obj);
    const requestOptions: any = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
        signal: signal,
    };
    try {
        const response = await fetch(url, requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.error("Error getting report", error);
        return false;
    }
};
export const getCustomerData = async (id, signal) => {
    const obj =
        id.toString().length > 13
            ? { uuid: id }
            : {
                  shopifyId: id,
              };
    const url =
        "https://cellcore-reports.azure-api.net/api/shopify/customer/profile?code=d9a365cc375e428ea3735c51ec8b703f";
    const result = await sendReport(obj, url, signal);
    return [result.data.user, result.data.addresses];
};
export const sendGraphql = async (queryString, url, signal?) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const graphql = JSON.stringify({
        query: queryString,
    });
    const requestOptions: any = {
        method: "POST",
        signal: signal,
        headers: myHeaders,
        body: graphql,
        redirect: "follow",
    };
    try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
    }
};
export const createTempPass = () => {
    let lower = "abcdefghijklmnopqrstuvwxyz";
    let upper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let specialNum = "1234567890!@#$%&*";
    let chars;
    let pass = "";
    for (let x = 0; x <= 10; x++) {
        if (x % 2 === 0) {
            chars = lower;
        } else if (x % 3 === 0) {
            chars = upper;
        } else {
            chars = specialNum;
        }
        let i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
    }
    return pass;
};

//creates all the card components
export const listRender = (arr, title: string, link_text: string, tags: string[], page: string) => {
    let display_list = arr.map((element) => (
        <CardComponent
            type={title}
            link_text={link_text}
            key={element?.id}
            id={element?.id}
            title={element?.title}
            description={page === "research" ? element.desc : element?.short_desc}
            content={element?.long_desc}
            ts={element?.publish_date}
            url={element?.url}
            tags={element?.tags}
            img={element?.thumb_360}
            tag_box={tags}
        />
    ));
    return display_list;
};

export const updateTagsArr = (state) => {
    let newOptionsArr: string[] = [];
    if (state) {
        state.forEach((research) => {
            if (research.tags.length) {
                let tags_array = research.tags.split(",");
                tags_array.forEach((tag: string) => {
                    if (!newOptionsArr.includes(tag)) {
                        newOptionsArr = [...newOptionsArr, tag].sort((a, b) => a.localeCompare(b));
                    }
                });
            }
        });
    }
    return newOptionsArr;
};

interface filterBaseState {
    id: number;
    tags: string;
    title: string;
    ts_created: string;

    desc?: string;
    is_archived?: boolean;
    target?: boolean;
    url?: string;
    long_desc?: string;
    publish_date?: string;
    short_desc?: string;
    thumb_360?: string;
}

export const filterBySearchState = (baseState: filterBaseState[], search: string, searchDesc: string = "") => {
    // search function - if you would like to search descriptions specify the key in searchDesc
    if (!baseState) return;
    let filtered: filterBaseState[] = [];
    for (const obj of baseState) {
        if (obj.title.toUpperCase().includes(search)) {
            filtered.push(obj);
            continue;
        }
        if (searchDesc && obj[searchDesc].toUpperCase().includes(search)) filtered.push(obj);
    }
    return filtered;
};

const addDates = (tags) => {
    tags.forEach((tag) => {
        if (tag?.title?.length === 0) return;
        const checkIfDate = new Date(
            tag.title.replace("th", "").replace("nd", "").replace("rd", "").replace("st", ""),
        ).getTime();
        if (checkIfDate) {
            tag.dateMS = checkIfDate;
        } else {
            tag.dateMS = new Date().getTime();
        }
    });
    tags.sort((a, b) => b.dateMS - a.dateMS);
    return tags;
};
interface filterState {
    search: string;
    tag: string[];
}
export const filterByTagState = (search_state: filterBaseState[], filterState: filterState) => {
    let tags: string[] = filterState.tag;
    let post_search = JSON.parse(JSON.stringify(search_state));
    if (tags.length > 0 && tags[0] !== "") {
        tags.forEach((tag) => {
            const filtered = search_state.filter((research) => research.tags.includes(tag));
            search_state = filtered;
        });
        return search_state;
    } else {
        const addDatesToArr = addDates(post_search);
        return addDatesToArr;
    }
};

//REPLACES A CHARACTER IN A STRING
export const changeDigitInString = (index, character, fullString) => {
    if (index || index === 0) {
        if (index >= fullString.length) {
            return fullString;
        }
        return fullString.substring(0, index) + character + fullString.substring(index + 1);
    }
    return null;
};

//NOTE in order to getNextLiveDate you will need to pass in which week you need, typically 1, or 3
// export const getNextLiveDate = (thursdayToGet) => {
//     const daysToAdd = (thursdayToGet - 1) * 7;
//     const today = new Date(new Date().setHours(0, 0, 0, 0));
//     let workingDate = new Date(today.getUTCFullYear(), today.getMonth(), 1);
//     let foundDate = false;
//     while (!foundDate) {
//         if (workingDate.getDay() === 4) {
//             workingDate = new Date(
//                 workingDate.getUTCFullYear(),
//                 workingDate.getMonth(),
//                 workingDate.getDate() + daysToAdd,
//             );
//             if (
//                 today > workingDate ||
//                 // Add dates here to remove them from Upcoming Events on Dashboard Home
//                 workingDate.setHours(0, 0, 0, 0) === new Date("May 18 2023").setHours(0, 0, 0, 0) ||
//                 workingDate.setHours(0, 0, 0, 0) === new Date("Jun 15 2023").setHours(0, 0, 0, 0) ||
//                 workingDate.setHours(0, 0, 0, 0) === new Date("Jul 6 2023").setHours(0, 0, 0, 0)
//             ) {
//                 workingDate = new Date(workingDate.getUTCFullYear(), workingDate.getMonth() + 1, 1);
//             } else {
//                 foundDate = true;
//                 break;
//             }
//         } else {
//             workingDate = new Date(workingDate.getUTCFullYear(), workingDate.getMonth(), workingDate.getDate() + 1);
//         }
//     }
//     workingDate.toLocaleString("en-US", { timeZone: "America/Denver" });
//     workingDate.setHours(13);
//     return workingDate;
// };

export const secondsToHms = (seconds) => {
    if (!seconds || seconds < 0) return `00:00:00`;
    seconds = Number(seconds);
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let second = Math.floor((seconds % 3600) % 60);

    let hour_display = hours > 9 ? hours : `0${hours}`;
    let minute_display = minutes > 9 ? minutes : `0${minutes}`;
    let second_display = second > 9 ? second : `0${second}`;
    return `${hour_display}:${minute_display}:${second_display}`;
};

/**
 * @description Removes special characters from string so string can be used in a URL
 * @param value - String that should stripped of Special Characters
 * @returns {String} - Returns stripped string
 * @comment - Full URL should NOT be passed here, just parts of it that will be used to make up the URL
 */
export const removeSpecialCharsForUrls = (value) => {
    if (!value || typeof value !== "string") return ``;
    //Replace ? mark with ` -` is for formatting for existing articles with ? in them, will also not allow new/edited titles to add ? in them
    return value.replace(/\//g, ` `).replace(/&/g, `and`).replace("#", ` `).replace("?", ` -`);
};

export const convertPhoneNumber = (num) => {
    const stringNum = num.toString().trim();
    const arraynum = stringNum.split("");
    if (arraynum.length === 10 && !arraynum.includes("(")) {
        arraynum.splice(0, 0, "(");
        arraynum.splice(4, 0, ")");
        arraynum.splice(5, 0, " ");
        arraynum.splice(9, 0, "-");
        return arraynum.join("");
    }
    return num;
};

export const getCookie = (name) => {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) {
        const partsPopped = parts.pop();
        const partsSplit = partsPopped?.split(";");
        const partsShift = partsSplit?.shift();
        return partsShift?.toLowerCase();
    }
    return false;
};

/**
 * @description
 * @param array
 * @returns {Object}
 */
export const convertKVArray = (array) => {
    const newKVObject = {};
    if (!array || array.length === 0) return null;
    array.forEach((item) => (newKVObject[item.k] = item.v));
    return newKVObject;
};
