import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { styled } from "@mui/material/styles";

const LoaderContainer = styled("div", { shouldForwardProp: (prop) => prop !== "show" && prop !== "background" })(
    ({ theme, show, background }) => ({
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        display: "none",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        zIndex: 20,
        ...(show && {
            display: "flex",
        }),
        ...(background &&
            background.length > 0 && {
                ...(background.indexOf("#") >= 0 && {
                    "&&&&": {
                        backgroundColor: background,
                    },
                }),
                ...(background.indexOf("#") === -1 && {
                    ...(background === "primary" && {
                        "&&&&": {
                            backgroundColor: theme.palette[background].main,
                        },
                    }),
                    ...(background !== "primary" && {
                        ...(background === "secondary" && {
                            "&&&&": {
                                backgroundColor: theme.palette[background].main,
                            },
                        }),
                        display: background,
                        "&&&&": {
                            backgroundColor: theme.palette.common[background],
                        },
                    }),
                }),
            }),
    }),
);

export default function CircularLoader(props) {
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setShowLoader(props.show);
    }, [props.show]);

    return (
        <LoaderContainer show={showLoader} background={props.background}>
            <CircularProgress color={props.spinnerColor} />
        </LoaderContainer>
    );
}
