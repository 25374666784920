import loadable from "@loadable/component";

const wrapLoadable = (from) => {
    return loadable(() => from);
};
/**
 * Order for routes
 *
 * New
 * - Leads
 * - Practitioners
 * - Reports
 * - Sales Tools
 *
 * Shared
 * - Learning Center
 * - Events
 * - Community
 * - Resources
 * - Account Settings
 */

const salesRoutes = [
    // Patient Routes - Practitioner Routes
    {
        title: "Practitioners",
        menu_icon: "person",
        main_menu: true,
        menu_title: "Practitioners",
        path: "/reps/practitioners",
        exact: true,
        component: wrapLoadable(import("../../../features/practitioners")),
    },
    {
        title: "Practitioners",
        menu_icon: "",
        menu_title: "Follow Up List",
        path: "/reps/practitioners/follow-up-list",
        exact: true,
        component: wrapLoadable(import("../../../features/practitioners/follow-up-list")),
    },
    {
        title: "Practitioners",
        menu_icon: "",
        menu_title: "One-time Orders",
        path: "/reps/practitioners/one-time-patients",
        exact: true,
        component: wrapLoadable(import("../../../features/practitioners/one-time-customers")),
    },
    {
        title: "Practitioners",
        menu_icon: "",
        menu_title: "At-risk Practitioners",
        path: "/reps/practitioners/at-risk-practitioners",
        exact: true,
        component: wrapLoadable(import("../../../features/practitioners/at-risk-practitioners")),
    },
    {
        title: "Practitioners",
        menu_icon: "",
        menu_title: "Loyal Patients",
        path: "/reps/practitioners/loyal-practitioners",
        exact: true,
        component: wrapLoadable(import("../../../features/practitioners/loyal-practitioners")),
    },
    {
        title: "Practitioners",
        path: "/reps/practitioners/profile/:shopifyId/",
        exact: true,
        component: wrapLoadable(import("../../../features/practitioners/practitioner-profile")),
    },
    {
        title: "Practitioners",
        menu_title: "Practitioners Patients",
        path: "/reps/practitioners/patients/:uuid/",
        exact: true,
        component: wrapLoadable(import("../../../features/sales-reps/practitioners-customers")),
    },
    {
        title: "Practitioners",
        menu_title: "Practitioners Patients Profile",
        path: "/reps/practitioners/patients-profile/:uuid/",
        exact: true,
        component: wrapLoadable(import("../../../features/sales-reps/pracs-cust-profile")),
    },
    // Leads
    {
        title: "Leads",
        menu_icon: "person",
        main_menu: true,
        menu_title: "Leads",
        path: "/reps/leads",
        exact: true,
        component: wrapLoadable(import("../../../features/leads")),
    },

    {
        title: "Leads ",
        menu_icon: "",
        menu_title: "New Accounts",
        path: "/reps/leads/new-accounts",
        exact: true,
        component: wrapLoadable(import("../../../features/leads/new-accounts")),
    },
    {
        title: "Leads",
        menu_icon: "",
        menu_title: "No Orders",
        path: "/reps/leads/no-orders",
        exact: true,
        component: wrapLoadable(import("../../../features/leads/no-orders")),
    },
    {
        title: "Leads",
        menu_icon: "",
        menu_title: "Incomplete Accounts",
        path: "/reps/leads/incomplete-accounts",
        exact: true,
        component: wrapLoadable(import("../../../features/leads/incomplete")),
    },
    {
        title: "Leads",
        menu_icon: "",
        menu_title: "Imported",
        path: "/reps/leads/imported",
        exact: true,
        component: wrapLoadable(import("../../../features/leads/imported")),
    },
    {
        title: "Import Leads",
        menu_icon: "",
        menu_title: "Imported",
        path: "/reps/leads/import-new",
        exact: true,
        component: wrapLoadable(import("../../../features/leads/import-new")),
    },
    {
        title: "Review Import",
        menu_icon: "",
        menu_title: "Imported",
        path: "/reps/leads/import-review",
        exact: true,
        component: wrapLoadable(import("../../../features/leads/import-new/ImportReview")),
    },

    // Reports Routes
    {
        title: "Reports",
        menu_icon: "pie_chart",
        main_menu: true,
        menu_title: "Reports",
        path: "/reps/reports/orders",
        exact: true,
        component: wrapLoadable(import("../../../features/sales-reports/orders")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Orders",
        path: "/reps/reports/orders",
        exact: true,
        component: wrapLoadable(import("../../../features/sales-reports/orders")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Orders",
        path: "/reps/reports/orders/:patientId?",
        exact: true,
        component: wrapLoadable(import("../../../features/sales-reports/orders")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/reports/sales-by-product",
        exact: true,
        component: wrapLoadable(import("../../../features/sales-reports/sales-by-product")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Orders",
        path: "/reps/reports/sales-by-product/:patientId?",
        exact: true,
        component: wrapLoadable(import("../../../features/sales-reports/sales-by-product")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/reports/sales-over-time",
        exact: true,
        component: wrapLoadable(import("../../../features/sales-reports/sales-over-time")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/reports/sales-over-time/:patientId?",
        exact: true,
        component: wrapLoadable(import("../../../features/sales-reports/sales-over-time")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/reports/orders-over-time",
        exact: true,
        component: wrapLoadable(import("../../../features/sales-reports/orders-over-time")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/reports/orders-over-time/:patientId?",
        exact: true,
        component: wrapLoadable(import("../../../features/sales-reports/orders-over-time")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/reports/patients-over-time",
        exact: true,
        component: wrapLoadable(import("../../../features/sales-reports/patients-over-time")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/reports/first-time-vs-returning",
        exact: true,
        component: wrapLoadable(import("../../../features/sales-reports/first-time-vs-returning")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/reports/returning-patients",
        exact: true,
        component: wrapLoadable(import("../../../features/sales-reports/returning-customers")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/reports/at-risk-patients",
        exact: true,
        component: wrapLoadable(import("../../../features/sales-reports/at-risk-customers")),
    },
    {
        title: "Reports",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/reports/loyal-patients",
        exact: true,
        component: wrapLoadable(import("../../../features/sales-reports/loyal-customers")),
    },
    // // Tools Routes
    // {
    //     title: "Sales Tools",
    // menu_icon: "content_paste",
    //     main_menu: true,
    //     menu_title: "Sales Tools",
    //     path: "/sales-tools",
    //     exact: true,
    //     component: wrapLoadable(import("../../../features/sales-tools")),
    // },
    // {
    //     title: "Protocol Guide",
    //     menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
    //     path: "/tools/protocol-guide",
    //     exact: true,
    //     component: wrapLoadable(import("../../../features/tools/protocol-guide")),
    // },
    // {
    //     title: "Assessment Form",
    //     menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
    //     path: "/tools/assessment-form",
    //     component: wrapLoadable(import("../../../features/tools/assessment-form")),
    // },
    // {
    //     title: "Assessment Form Results",
    //     menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
    //     path: "/tools/assessment-results/:userId/:assessmentRequestId",
    //     component: wrapLoadable(import("../../../features/tools/assessment-results")),
    // },
    // {
    //     title: "Referal Program",
    //     menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
    //     path: "/tools/referral-program",
    //     exact: true,
    //     component: wrapLoadable(import("../../../features/tools/referral-program")),
    // },

    // Learning Center
    {
        title: "Learning Center",
        menu_icon: "school",
        main_menu: true,
        menu_title: "Learning Center",
        path: "/reps/learning",
        exact: true,
        component: wrapLoadable(import("../../../features/learning-center")),
    },
    {
        title: "Learning Center",
        menu_icon: "school",
        inside_menu: true,
        menu_title: "Learning Center",
        path: "/reps/learning/about",
        exact: true,
        component: wrapLoadable(import("../../../features/learning-center/admin/components/Topics")),
    },
    // Events Routes
    {
        title: "Events",
        menu_icon: "date_range",
        main_menu: true,
        menu_title: "Events",
        path: "/reps/events",
        exact: true,
        component: wrapLoadable(import("../../../features/events")),
    },
    {
        title: "Events",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/events/eco",
        exact: true,
        component: wrapLoadable(import("../../../features/events/eco")),
    },
    {
        title: "Events",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/events/live",
        exact: true,
        component: wrapLoadable(import("../../../features/events/live")),
    },
    {
        title: "Events",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/events/muscle-testing",
        exact: true,
        component: wrapLoadable(import("../../../features/events/muscle-testing")),
    },
    {
        title: "Events",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/events/see-us",
        exact: true,
        component: wrapLoadable(import("../../../features/events/see-us")),
    },
    {
        title: "Events",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/events/webinars",
        exact: true,
        component: wrapLoadable(import("../../../features/events/webinars")),
    },

    // Community
    {
        title: "Community",
        main_menu: true,
        menu_title: "Community",
        nav_bar_title: "Community Center",
        menu_icon: "groups",
        path: "/reps/community",
        exact: true,
        component: wrapLoadable(import("../../../features/community")),
    },

    // Resources Routes
    {
        title: "Resources",
        menu_icon: "system_update_alt",
        main_menu: true,
        menu_title: "Resources",
        path: "/reps/resources",
        exact: true,
        component: wrapLoadable(import("../../../features/resources")),
    },
    // Account Routes
    {
        title: "Addresses",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/account/addresses",
        exact: true,
        component: wrapLoadable(import("../../../features/account/addresses")),
    },
    {
        title: "Payout Preferences",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/account/payout-preferences",
        exact: true,
        component: wrapLoadable(import("../../../features/account/payout")),
    },
    {
        title: "Tax Forms",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/reps/account/tax-forms",
        exact: true,
        component: wrapLoadable(import("../../../features/account/tax-forms")),
    },
];

export default salesRoutes;
