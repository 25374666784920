import { useSelector } from "react-redux";

import NavLinkElements from "../Links/NavLinkElements";
import UserBlock from "../UserBlock";

import { styled } from "@mui/material/styles";
//import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MuiDrawer from "@mui/material/Drawer";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

const openedMainNavMixin = (theme) => ({
    width: theme.widths.nav.main.desktop,
    maxWidth: theme.widths.nav.main.desktop,
    backgroundColor: theme.palette.common.blue_dark,
    // overflowX: "hidden",
    padding: theme.spacing(0.5, 0, 1),
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
        width: theme.widths.nav.main.tablet,
        maxWidth: theme.widths.nav.main.tablet,
        padding: theme.spacing(0),
    },
    [theme.breakpoints.down("sm")]: {
        width: theme.widths.nav.main.mobile,
        maxWidth: theme.widths.nav.main.mobile,
    },
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
});

const closedMainNavMixin = (theme) => ({
    overflowX: "hidden",
    backgroundColor: theme.palette.common.blue_dark,
    width: `calc(${theme.spacing(7)} + 1px)`,
    padding: theme.spacing(0.5, 0, 1, 0),
    boxShadow: "none",
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
        padding: theme.spacing(0),
    },
});

const DrawerEl = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "isMobile" && prop !== "drawerOpen" && prop !== "secondaryNavOpen",
})(({ theme, isMobile, drawerOpen, secondaryNavOpen }) => ({
    "&&": {
        width: theme.widths.nav.main.desktop,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        backgroundColor: theme.palette.common.blue_dark,
        color: theme.palette.common.white,
        position: "relative",
        flexDirection: "space-between",
        boxShadow: "none",
        "& > div": {
            border: "none",
        },
        ...(drawerOpen && {
            ...openedMainNavMixin(theme),
            "& .MuiDrawer-paper": openedMainNavMixin(theme),
        }),
        ...(!drawerOpen && {
            ...closedMainNavMixin(theme),
            "& .MuiDrawer-paper": closedMainNavMixin(theme),
        }),
        [theme.breakpoints.down("md")]: {
            width: theme.widths.nav.main.tablet,
            padding: theme.spacing(0),
            "& > div": {
                transition: theme.transitions.create("all", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                ...(drawerOpen && {
                    left: 0,
                }),
            },
        },
        [theme.breakpoints.down("sm")]: {
            width: theme.widths.nav.main.mobile,
        },
    },
}));

const NavContentEl = styled("nav")(({ theme }) => ({
    height: "100%",
    // [theme.breakpoints.down("md")]: {
    //     paddingLeft: theme.spacing(4),
    //     paddingRight: theme.spacing(4),
    // },
}));

const DrawerHeader = styled("div", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2, 1, 1, 1),
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1, 3.75),
    },
    [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1, 3),
    },
}));

const DrawerHeaderImgContainer = styled("div", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    width: "100%",
    margin: theme.spacing(0, "auto", 1),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ...(open && {
        padding: theme.spacing(1.5),
    }),
    ...(!open && {
        padding: theme.spacing(1.5, 0.25),
    }),
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1, 0.5),
    },
}));

const DrawerHeaderPicture = styled("picture", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    "&&": {
        width: "100%",
        margin: "0 auto",
    },
}));

const DrawerHeaderImg = styled("img", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    width: "100%",
    margin: "0 auto",
    cursor: "pointer",
    maxHeight: 46,
    objectFit: "contain",
    transition: theme.transitions.create("maxWidth", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    // ...(!open && {
    //     maxHeight: 46
    // }),
}));

const DrawerButtonContainer = styled("div", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(2, 2, 1, 2),
    },
    ...(!open && {
        padding: theme.spacing(0, 0, 1, 0),
    }),
}));

const DrawerButtonIcon = styled(MenuOpenIcon, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    color: "#FFF",
    fontSize: "2rem",
    transformOrigin: "center",
    transition: theme.transitions.create("transform", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(!open && {
        transform: "rotate(180deg)",
    }),
}));

const MainNav = (props) => {
    const globalState = useSelector((state) => state.global);
    const auth = useSelector((state) => state.auth);

    const navLogo = {
        main: {
            desktop: {
                src: "https://res.cloudinary.com/microbe/image/upload/f_auto,w_300/cellcore/Icons/CellCore-Logo-Lockup-White.png",
            },
            mobile: {
                src: "https://res.cloudinary.com/microbe/image/upload/f_auto,w_300/cellcore/Icons/CellCore-Logo-Lockup-White.png",
            },
            closed: {
                src: "https://res.cloudinary.com/microbe/image/upload/f_auto,w_120/cellcore/Icons/CellCore-Brandmark-white.png",
            },
        },
    };
    if (auth.user?.access) {
        return (
            <DrawerEl
                key={globalState.isMobile}
                isMobile={globalState.isMobile}
                variant={globalState.isMobile ? "temporary" : "permanent"}
                anchor="left"
                open={props.mainNavState.open}
                drawerOpen={props.mainNavState.open}
                secondaryNavOpen={props.secondNavState.open}
                ModalProps={{
                    keepMounted: true,
                }}
                BackdropProps={{ invisible: false }}
                onClose={() => props.navCloseAll()}
                //onOpen={() => props.navOpenSecondary()}
            >
                <NavContentEl>
                    <DrawerHeader open={props.mainNavState.open}>
                        <DrawerHeaderImgContainer open={props.mainNavState.open} onClick={props.handleLogoClick}>
                            <DrawerHeaderPicture key={props.mainNavState.open} open={props.mainNavState.open}>
                                <source
                                    srcSet={navLogo.main.mobile.src}
                                    media={`(max-width: ${globalState.mobileAt}px)`}
                                />
                                <DrawerHeaderImg
                                    open={props.mainNavState.open}
                                    src={props.mainNavState.open ? navLogo.main.desktop.src : navLogo.main.closed.src}
                                    alt={"Cellcore Navigation Logo"}
                                />
                            </DrawerHeaderPicture>
                        </DrawerHeaderImgContainer>
                        <UserBlock view={props.view} setView={props} open={props.mainNavState.open} />
                    </DrawerHeader>
                    <NavLinkElements
                        key={props.view}
                        view={props.view}
                        routes={props.routes}
                        open={props.mainNavState.open}
                        navCloseMain={props.navCloseMain}
                        navCheckState={props.navCheckState}
                        secondNavState={props.secondNavState}
                        user={props.user}
                    ></NavLinkElements>
                </NavContentEl>
                {globalState.isMobile && (
                    <DrawerButtonContainer open={props.mainNavState.open} onClick={props.navCloseMain}>
                        <DrawerButtonIcon open={props.mainNavState.open} />
                    </DrawerButtonContainer>
                )}
            </DrawerEl>
        );
    } else {
        return null;
    }
};

export default MainNav;
