import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fileUpload } from "../../common/utils";

const initialState = {
    isLoading: false,
    salesRegionId: "",
    salesRep: [],
    selectedRep: {},
    picture: {},
};

export const pictureUpload = createAsyncThunk("practtitioners/fileUpload", async ({ file, user }) => {
    const uploadResult = await fileUpload(file, user.user_id, "resource-center").catch((err) => console.error(err));
    return uploadResult;
});

export const practitionersSlice = createSlice({
    name: "practitioners",
    initialState,
    reducers: {
        isLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        changeSalesRegionId: (state, action) => {
            state.salesRegionId = action.payload;
        },
        currentRep: (state, action) => {
            state.salesRep = action.payload;
        },
        selectRep: (state, action) => {
            state.selectedRep = action.payload;
        },
        globalPractitioner: (state, action) => {
            state.globalPractitioner = action.payload;
        },
        prevFilter: (state, action) => {
            state.prevFilter = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(pictureUpload.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(pictureUpload.fulfilled, (state, action) => {
            state.isLoading = false;
            state.picture = action.payload;
        });
    },
});

export const { isLoading, changeSalesRegionId, currentRep, selectRep, globalPractitioner, prevFilter } =
    practitionersSlice.actions;

export default practitionersSlice.reducer;
