const vimeoData = async (video_id, signal) => {
    let apiUrl = `https://api.vimeo.com/users/84187462/videos/${video_id}`;
    const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
            Authorization: "bearer 380c7899713018081dab892585c60d60",
        },
        signal: signal,
    });
    const data = await response.json();
    return data;
};

export default vimeoData;
