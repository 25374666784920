import loadable from "@loadable/component";

const wrapLoadable = (from) => {
    return loadable(() => from);
};

const adminRoutes = [
    {
        title: "Admin Dashboard",
        main_menu: false,
        menu_title: "Dashboard",
        icon: "zmdi zmdi-settings zmdi-hc-2x",
        path: "/admin/dashboard",
        component: wrapLoadable(import("../../../features/dashboard/admin")),
    },
    // Tools
    {
        title: "Tools Admin",
        menu_icon: "content_paste",
        main_menu: true,
        menu_title: "Practitioner Tools",
        path: "/admin/tools",
        component: wrapLoadable(import("../../../features/tools/admin")),
    },
    // Learning Center
    {
        title: "Learning Center Admin",
        menu_icon: "school",
        menu_title: "Learning Center",
        main_menu: true,
        exact: true,
        path: "/admin/learning/section/",
        component: wrapLoadable(import("../../../features/learning-center/admin/components/Sections")),
    },
    {
        title: "Learning Center Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Learning Center",
        path: "/admin/learning/section/:sectionId",
        exact: true,
        component: wrapLoadable(import("../../../features/learning-center/admin/components/Topics")),
    },
    {
        title: "Learning Center Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Learning Center",
        path: "/admin/learning/topic/:topicId",
        component: wrapLoadable(import("../../../features/learning-center/admin/components/Modules")),
    },
    {
        title: "Learning Center Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Learning Center",
        path: "/admin/learning/section/:sectionId/:topicId",
        component: wrapLoadable(import("../../../features/learning-center/admin/components/Modules")),
    },
    {
        title: "Learning Center Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Learning Center",
        path: "/admin/learning/research/",
        component: wrapLoadable(import("../../../features/research/admin/")),
    },
    {
        title: "Learning Center Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Learning Center",
        path: "/admin/learning/textBlog/",
        component: wrapLoadable(import("../../../features/blogs/admin/TextBlogAdmin")),
    },
    {
        title: "Learning Center Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Learning Center",
        path: "/admin/learning/live-qa/",
        component: wrapLoadable(import("../../../features/blogs/admin/VideoBlogAdmin")),
    },
    {
        title: "Learning Center Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Learning Center",
        path: "/admin/learning/best-of-live/",
        component: wrapLoadable(import("../../../features/blogs/admin/VideoBlogAdmin")),
    },
    {
        title: "Learning Center Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Learning Center",
        path: "/admin/learning/webinars/",
        component: wrapLoadable(import("../../../features/blogs/admin/VideoBlogAdmin")),
    },
    {
        title: "Learning Center Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Learning Center",
        path: "/admin/learning/additional/",
        component: wrapLoadable(import("../../../features/blogs/admin/VideoBlogAdmin")),
    },

    // Community
    {
        title: "Community",
        main_menu: true,
        menu_title: "Community",
        menu_icon: "groups",
        path: "/admin/community",
        component: wrapLoadable(import("../../../features/community")),
    },

    // Resources
    {
        title: "Resources Admin",
        menu_icon: "system_update_alt",
        main_menu: true,
        menu_title: "Resources",
        path: "/admin/resources/posters",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/posters")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/guides",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/guides")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/assessment-forms",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/assessment-forms")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/charts-and-graphs",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/charts-and-graphs")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/clinical-reference",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/clinical-reference")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/other",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/other")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/coas",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/coas")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/video-resources",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/video-resources")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/posters",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/posters")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/faqs",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/faqs")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/pricing",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/pricing")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/muscle-testing-forms",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/muscle-testing-forms")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/kit-and-product-info",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/kit-and-product-info")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Upcoming ECO Resources",
        path: "/admin/resources/upcoming-eco-resources",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/upcoming-eco-resources")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/marketing-toolkit",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/marketingToolkit")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/intake-paperwork",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/intake-paperwork")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/handouts",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/handouts")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/dosing-charts",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/dosing-charts")),
    },
    {
        title: "Resources Admin",
        menu_icon: "zmdi zmdi-settings zmdi-hc-2x",
        menu_title: "Resources",
        path: "/admin/resources/research",
        exact: true,
        component: wrapLoadable(import("../../../features/resources/admin/research")),
    },
];

export default adminRoutes;
