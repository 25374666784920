import React from "react";

import { styled } from "@mui/material/styles";

const Overlay = styled("div")({
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1000,
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    display: "block",
    transition: "all .3s",
});

const PageOverlay = () => {
    return <Overlay></Overlay>;
};

export default PageOverlay;
