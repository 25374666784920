import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    showDevTools: false,
    nav: {
        secondaryNavBar: {
            sideBar: {
                sections: {},
            },
            title: "",
        },
        hideOptionalLinks: true,
    },
    isMobile: false,
    mobileAt: 768,
    loader: {
        showLoader: false,
        iconImage: "https://res.cloudinary.com/microbe/image/upload/v1637601075/cellcore/Icons/CellCoreLoadingGif.gif",
    },
    practitioner: {
        currentCustomer: {},
    },
    metadata: {},
    salesReps: [],
    user: {},
};

export const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        toggleMobile: (state, action) => {
            if (Object.keys(action.payload).includes("boolean")) {
                state.isMobile = action.payload.boolean;
            } else {
                state.isMobile = action.payload;
            }
        },
        toggleShowLoader: (state, action) => {
            if (Object.keys(action.payload).includes("boolean")) {
                state.loader.showLoader = action.payload.boolean;
            } else {
                state.loader.showLoader = action.payload;
            }
        },
        changeCurrentPractitionerCustomer: (state, action) => {
            state.practitioner.currentCustomer = action.payload;
        },
        setMetaData: (state, action) => {
            state.metadata = action.payload;
        },
        setNavGeneralRoutes: (state, action) => {
            state.nav.secondaryNavBar.title = action.payload.title;
            state.nav.secondaryNavBar.sideBar.sections = action.payload.routes;
        },
        setNavGeneralRoutesAndMetaData: (state, action) => {
            state.nav.secondaryNavBar.title = action.payload.title;
            state.nav.secondaryNavBar.sideBar.sections = action.payload.routes;
            state.metadata = action.payload.metadata;
        },
        toggleHideOptionalNav: (state, action) => {
            state.nav.hideOptionalLinks = action.payload;
        },
        setSalesReps: (state, action) => {
            state.salesReps = action.payload;
        },
        toggleShowDevTools: (state, action) => {
            if (Object.keys(action.payload).includes("boolean")) {
                state.showDevTools = action.payload.boolean;
            } else {
                state.showDevTools = !current(state).showDevTools;
            }
        },
    },
});

export const {
    toggleMobile,
    toggleShowLoader,
    changeCurrentPractitionerCustomer,
    setMetaData,
    setNavGeneralRoutes,
    setNavGeneralRoutesAndMetaData,
    toggleHideOptionalNav,
    setSalesReps,
    toggleShowDevTools,
} = globalSlice.actions;

export default globalSlice.reducer;

export const { globalStore } = globalSlice.actions;
