import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../../app/routes/static";

import { toggleMobile } from "./../globalSlice/globalSlice";

import HelmetWrap from "./../../common/HelmetWrap";
import MainNav from "./../../common/MainNav/MainNav";
import SecondSideBar from "./../../common/SecondSideBar/SecondSideBar";
import SiteLoader from "./../Loading/Loader";
import { ErrorComponentCustom } from "../ErrorComponent/ErrorComponentCustom";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
// import { getNextLiveDate } from "../Functions/utilities";
import { DontPrint } from "../PrintUtils/DontPrint";
import { usePrintCheck } from "../PrintUtils/usePrintCheck";

const RootContent = styled("div")({
    display: "flex",
    overflowY: "hidden",
});

const MainContent = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    minHeight: "100vh",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(0),
        maxHeight: "100vh",
    },
}));

const MainContentGrid = styled(Grid, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    "&&": {
        transition: theme.transitions.create(["margin", "width", "padding"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
    },
}));

const ContentParent = styled(Grid, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    "&&": {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        boxSizing: "border-box",
        backgroundColor: theme.palette.common.grey_lightest,
        flexWrap: "nowrap",
        overflow: "hidden",
    },
}));

const AppBarEl = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    "&&": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "relative",
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: `${theme.palette.common.white}`,
        clipPath: "inset(-10px -10px -10px 0)",
        maxHeight: theme.spacing(8),
        ...(open && {
            width: `100%`,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    },
}));

const ToolBarEl = styled(Toolbar, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
        paddingRight: 8,
        paddingLeft: 8,
    },
}));

const IconButtonEl = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== "isMobile" && prop !== "showHamburgerOverride",
})(({ theme, isMobile, showHamburgerOverride }) => ({
    display: isMobile ? "none" : "flex", // Set 'none' explicitly for mobile
    color: theme.palette.common.grey_dark,
    paddingLeft: theme.spacing(2),
    visibility: "hidden",

    // Hide on mobile screens based on breakpoints
    [theme.breakpoints.down("md")]: {
        display: showHamburgerOverride ? "flex" : "none",
        visibility: "visible",
    },

    // Control visibility
    ...(!showHamburgerOverride && {
        visibility: "hidden", // Still apply visibility if needed
    }),
}));

const ContentArea = styled(Grid, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    height: "100%",
    padding: 20,
    overflow: "hidden",
    overflowY: "scroll",
    [theme.breakpoints.down("md")]: {
        padding: 6,
    },
}));
const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(2),
}));

const practitionerState = {
    admin: false,
    practitioner: true,
    patient: false,
    sales: false,
};

const adminState = {
    admin: true,
    practitioner: false,
    patient: false,
    sales: false,
};

const salesState = {
    admin: false,
    practitioner: false,
    patient: false,
    sales: true,
};

export default function AppLayout(props) {
    const dispatchState = useDispatch();
    const globalState = useSelector((state) => state.global);

    const history = useHistory();
    const location = useLocation();
    const auth = useSelector((state) => state.auth);
    const [view, setView] = useState(practitionerState);

    const hasInitialized = useRef(false);

    const [mainNavState, setMainNavState] = useState({
        open: true,
    });
    const [secondNavState, setSecondNavState] = useState({
        open: true,
    });
    const [hamburgerOverride, setHamburgerOverride] = useState(true);

    const printing = usePrintCheck();

    const handleLogoClick = () => {
        setView(practitionerState);
        history.push("/dashboard");
    };

    // Sets initial view state based on permissions
    useEffect(() => {
        if (auth.user) {
            if (
                (auth.user.access === "5" && auth.user.status === "3") ||
                (auth.user.access === "7" && auth.user.status === "3")
            ) {
                setView(adminState);
            }
            if (auth.user.access === "3" && auth.user.status === "3") {
                setView(practitionerState);
            }
            if (
                (auth.user.access === "4" && auth.user.status === "3") ||
                (auth.user.access === "6" && auth.user.status === "3")
            ) {
                setView(salesState);
            }
        }
    }, [auth.user.access]);

    // Sets sidebar on pathname and permissions on each reload
    useEffect(() => {
        if (auth.user) {
            if (location.pathname.includes("admin") && auth.user.access === "5") {
                setView(adminState);
            } else if (
                location.pathname.includes("reps") &&
                (auth.user.access === "4" || auth.user.access === "6" || auth.user.access === "7")
            ) {
                setView(salesState);
            } else if (
                !location.pathname.includes("reps") &&
                !location.pathname.includes("admin") &&
                (auth.user.access === "6" || auth.user.access === "5" || auth.user.access === "7")
            ) {
                setView(practitionerState);
            }
        }
    }, [auth.user, location.pathname]);

    //NAV FUNCTIONS BELOW
    const urlIsTopLevel = () => {
        return location.pathname.split("/").length > 2 ? false : true;
    };

    //MAIN NAV EVENTS
    //MAIN NAV EVENTS
    //MAIN NAV EVENTS
    const navToggle = () => {
        //TOGGLES TRUE/FALSE
        const bool = (mainNavState.open = !mainNavState.open);
        const secondaryBool = (secondNavState.open = !secondNavState.open);
        if (!globalState.isMobile) {
            //DESKTOP
            setMainNavState({ ...mainNavState, open: bool });
            setSecondNavState({ ...secondNavState, open: bool });
        } else {
            //MOBILE
            setMainNavState({ ...mainNavState, open: bool });
            setSecondNavState({ ...secondNavState, open: secondaryBool });
        }
    };

    const navCloseMain = (e) => {
        if (globalState.isMobile) {
            //MOBILE
            setMainNavState({ ...mainNavState, open: false });
        }
    };

    //SECONDARY NAV EVENTS
    //SECONDARY NAV EVENTS
    //SECONDARY NAV EVENTS
    const navCloseSecondary = (e) => {
        setSecondNavState({ ...secondNavState, open: false });
        if (globalState.isMobile) {
            //MOBILE
            setMainNavState({ ...mainNavState, open: false });
        }
    };
    const navOpenSecondary = (e) => {
        setSecondNavState({ ...secondNavState, open: true });
    };

    //ALL NAV EVENTS
    //ALL NAV EVENTS
    //ALL NAV EVENTS
    const navCloseAll = () => {
        setMainNavState({ ...mainNavState, open: false });
        setSecondNavState({ ...secondNavState, open: false });
    };
    const navCheckState = () => {
        if (!globalState.isMobile) {
            //DESKTOP
            setMainNavState({ ...mainNavState, open: true });
            setSecondNavState({ ...secondNavState, open: true });
        } else {
            //MOBILE
            setMainNavState({ ...mainNavState, open: false });
            if (
                globalState?.nav?.secondaryNavBar?.sideBar?.sections &&
                globalState.nav.secondaryNavBar.sideBar.sections.length > 0
            ) {
                setTimeout(() => {
                    setSecondNavState({ ...secondNavState, open: true });
                }, 200);
            }
        }
    };

    const backToMain = () => {
        setMainNavState({ ...mainNavState, open: true });
        setSecondNavState({ ...secondNavState, open: false });
    };

    //TOP BAR HAMBURGER BUTTON
    //TOP BAR HAMBURGER BUTTON
    //TOP BAR HAMBURGER BUTTON
    const handleDrawerIcon = () => {
        if (!globalState.isMobile) {
            //DESKTOP
            setSecondNavState({ ...secondNavState, open: true });
        } else {
            //MOBILE
            if (
                globalState.nav.secondaryNavBar?.sideBar?.sections ||
                globalState.nav.secondaryNavBar.title?.toLowerCase() === "community"
            ) {
                setMainNavState({ ...mainNavState, open: false });
                setSecondNavState({ ...secondNavState, open: true });
            } else {
                setMainNavState({ ...mainNavState, open: true });
                setSecondNavState({ ...secondNavState, open: false });
            }
        }
    };

    //SECONDARY NAV LINK CLICK
    //SECONDARY NAV LINK CLICK
    //SECONDARY NAV LINK CLICK
    const onSecondaryNavClick = () => {
        if (globalState.nav.secondaryNavBar.title?.toLowerCase() === "community") {
            if (globalState.isMobile) {
                navCloseSecondary();
            }
        } else {
            if (globalState.isMobile) {
                navCloseSecondary();
            }
        }
    };

    //NAV RESIZING
    //NAV RESIZING
    //NAV RESIZING
    useEffect(() => {
        if (!globalState.isMobile) {
            //DESKTOP
            if (urlIsTopLevel()) {
                setMainNavState({ ...mainNavState, open: true });
                setSecondNavState({ ...secondNavState, open: true });
            } else {
                setMainNavState({ ...mainNavState, open: true });
                setSecondNavState({ ...secondNavState, open: false });
            }
        }
    }, [globalState.isMobile]);
    useEffect(() => {
        if (globalState.isMobile) {
            //MOBILE
            if (globalState.nav.secondaryNavBar?.sideBar?.sections) {
                if (globalState.nav.secondaryNavBar.title?.toLowerCase() !== "learning center") {
                    setMainNavState({ ...mainNavState, open: false });
                    setSecondNavState({ ...secondNavState, open: true });
                } else if (globalState.nav.secondaryNavBar.title?.toLowerCase() === "learning center") {
                    setMainNavState({ ...mainNavState, open: false });
                    setSecondNavState({ ...secondNavState, open: false });
                }
            } else {
                setMainNavState({ ...mainNavState, open: false });
                setSecondNavState({ ...secondNavState, open: false });
            }
        }
    }, [globalState.nav.secondaryNavBar?.sideBar?.sections]);

    //INITIAL LOADING BEHAVIOR HERE AND DOWN
    const changeNavStatusOnResizeAndLoad = () => {
        const isMobile = window.innerWidth <= 768; //SMALLER THAN MOBILE BREAKPOINT
        if (!isMobile) {
            //DESKTOP
            setMainNavState({ ...mainNavState, open: true });
            setSecondNavState({ ...secondNavState, open: false });
        } else {
            //MOBILE
            setMainNavState({ ...mainNavState, open: false });
            setSecondNavState({ ...secondNavState, open: false });
        }
        dispatchState(
            toggleMobile({
                boolean: isMobile,
            }),
        );
        return;
    };
    useEffect(() => {
        //ON RESIZE ONLY
        if (!hasInitialized.current) {
            window.addEventListener("resize", changeNavStatusOnResizeAndLoad);
        }
    });
    useEffect(() => {
        if (!hasInitialized.current) {
            changeNavStatusOnResizeAndLoad();
        }
        hasInitialized.current = true;
    }, []);

    // useEffect(() => {
    //     if (!globalState.isMobile) {
    //         setHamburgerOverride(false);
    //     } else {
    //         setHamburgerOverride(true);
    //     }
    // }, [globalState.isMobile, globalState.nav.secondaryNavBar]);
    // const displayLiveButton = () => {
    //     const today = new Date();
    //     const firstThursdayLive = getNextLiveDate(1);
    //     const thirdThursdayLive = getNextLiveDate(3);
    //     let displayThursday = "";
    //     if (firstThursdayLive < thirdThursdayLive) {
    //         displayThursday = firstThursdayLive.toLocaleDateString();
    //     }
    //     //Taking this out to remove 3rd Thursday of every month CellCore Live - Keeping in case they want to bring it back, etc.
    //     // else {
    //     //     displayThursday = thirdThursdayLive.toLocaleDateString();
    //     // }
    //     return (
    //         <Button
    //             color="secondary"
    //             href="https://cellcore.zoom.us/j/82780325548?pwd=88fHntdbSbp4IkCDZDEzada8u0aw6X.1"
    //             target="_blank"
    //             disabled={today.toLocaleDateString() !== displayThursday}
    //             variant="contained"
    //         >
    //             {today.toLocaleDateString() !== displayThursday ? `Next Live on ${displayThursday}` : "Join us on Zoom"}
    //         </Button>
    //     );
    // };

    let backToUrl = `https://www.cellcore.com`;
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
        backToUrl = `https://www.cellcore.com`;
    } else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
        backToUrl = `https://staging.cellcore.com`;
    } else if (process.env.REACT_APP_ENVIRONMENT === "development") {
        backToUrl = `https://www.cellcore.com`;
    }

    return (
        <RootContent>
            <SiteLoader showLoader={globalState.loader.showLoader} />
            <HelmetWrap pageInfo={globalState.metadata} />
            <MainContent open={mainNavState.open}>
                <MainContentGrid container direction="row">
                    <DontPrint>
                        <MainNav
                            mainNavState={mainNavState}
                            secondNavState={secondNavState}
                            setView={setView}
                            view={view}
                            routes={routes}
                            open={mainNavState.open}
                            handleLogoClick={handleLogoClick}
                            navToggle={navToggle}
                            navCheckState={navCheckState}
                            navCloseMain={navCloseMain}
                            navCloseAll={navCloseAll}
                            navOpenSecondary={navOpenSecondary}
                            user={props.user}
                        />
                        {Object.keys(globalState.nav.secondaryNavBar?.sideBar?.sections || []).length > 0 && (
                            <SecondSideBar
                                secondNavState={secondNavState}
                                mainNavState={mainNavState}
                                data={props}
                                routes={props.routes}
                                navCloseAll={navCloseAll}
                                navCloseSecondary={navCloseSecondary}
                                navOpenSecondary={navOpenSecondary}
                                backToMain={backToMain}
                                onSecondaryNavClick={onSecondaryNavClick}
                            />
                        )}
                    </DontPrint>
                    <ContentParent style={printing ? { backgroundColor: "#FFF" } : {}} container direction="column">
                        <DontPrint>
                            <AppBarEl className="noprint" open={mainNavState.open} position="static">
                                <ToolBarEl open={mainNavState.open}>
                                    <IconButtonEl
                                        aria-label="open drawer"
                                        onClick={() => handleDrawerIcon()}
                                        edge="start"
                                        isMobile={globalState.isMobile}
                                        showHamburgerOverride={hamburgerOverride}
                                    >
                                        <MenuIcon />
                                    </IconButtonEl>
                                    <CustomBox>
                                        <Button
                                            color="secondary"
                                            href={backToUrl + `/collections/products`}
                                            aria-label="shop"
                                            variant="contained"
                                        >
                                            Shop
                                        </Button>
                                        {/* {displayLiveButton()} */}
                                    </CustomBox>
                                </ToolBarEl>
                            </AppBarEl>
                        </DontPrint>
                        <ErrorComponentCustom showNotMember={props.showNotMember} />
                        <ContentArea component={Grid} item xl lg md sm xs={12}>
                            {props.children}
                        </ContentArea>
                    </ContentParent>
                </MainContentGrid>
            </MainContent>
        </RootContent>
    );
}
