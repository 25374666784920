import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { styled } from "@mui/material/styles";
import { ClickAwayListener, Popper, MenuList, MenuItem, Typography, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material//ExpandMore";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import ProfileIcon from "./../ProfileIcon/ProfileIcon";

const UserBlockContainer = styled("div")(({ theme }) => ({
    width: "100%",
    display: "flex",
    background: "transparent",
    color: theme.palette.common.white,
    padding: theme.spacing(0, 0.75),
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0),
    },
}));

const ButtonContainer = styled("div")(({ theme }) => ({
    width: "100%",
    margin: theme.spacing(0, "auto"),
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
        margin: 0,
        justifyContent: "flex-start",
    },
}));

const UserBlockTrigger = styled(Button, { shouldForwardProp: (prop) => prop !== "menuOpen" })(
    ({ theme, menuOpen }) => ({
        width: "100%",
        backgroundColor: "transparent",
        color: theme.palette.common.white,
        display: "flex",
        justifyContent: "flex-start",
        padding: 0,
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0),
            margin: theme.spacing(0),
            minWidth: 0,
            justifyContent: "flex-start",
        },
        ...(!menuOpen && {
            paddingLeft: theme.spacing(1.5),
        }),
    }),
);

const ButtonArrowContainer = styled("div")(({ theme }) => ({
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    lineHeight: "1rem",
    alignItems: "center",
    overflow: "hidden",
    overflowWrap: "break-word",
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
        flex: "inherit",
    },
}));

const ButtonText = styled("span")(({ theme }) => ({
    maxWidth: "100%",
    padding: theme.spacing(0, 1.25),
    textAlign: "left",
    overflowWrap: "break-word",
    color: theme.palette.common.white,
    fontSize: "13px",
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0, 0.25, 0, 1.25),
    },
}));

const ExpandArrowIcon = styled(ExpandMoreIcon)(({ theme }) => ({
    color: theme.palette.common.white,
    fontSize: 22,
}));

const PopperMenu = styled(Popper)(({ theme }) => ({
    width: "100%",
    zIndex: 1200,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.blue_dark,
    margin: theme.spacing(0.25, 0, 0),
    [theme.breakpoints.down("md")]: {
        width: "96%",
        margin: theme.spacing(0.25, "auto", 0),
    },
}));

const PopperList = styled(MenuList)(({ theme }) => ({
    boxSizing: "border-box",
    overflow: "hidden",
    padding: 0,
}));

const PopperListHeaderContainer = styled("div")(({ theme }) => ({
    padding: "10px",
    display: "flex",
    alignContent: "flex-start",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: theme.palette.common.blue_light,
    color: theme.palette.common.white,
}));

const headerItemMixin = (theme) => ({
    "&&": {
        padding: "0",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "100%",
        [theme.breakpoints.down("md")]: {
            minHeight: 14,
        },
    },
});

const PopperListHeaderItems = styled(MenuItem)(({ theme }) => ({
    ...headerItemMixin(theme),
}));

const PopperListHeaderItemsSmall = styled(MenuItem)(({ theme }) => ({
    ...headerItemMixin(theme),
    fontSize: "12px",
    lineHeight: "12px",
}));

const PopperListItemsContainer = styled(Typography)(({ theme }) => ({
    width: "100%",
    padding: theme.spacing(1.25),
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
}));

const PopperListItems = styled(MenuItem)(({ theme }) => ({
    padding: theme.spacing(1.25),
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "flex-start",
    lineHeight: "15px",
    fontSize: "15px",
    textTransform: "capitalize",
    width: "100%",
    whiteSpace: "normal",
}));

const PopperExitIcon = styled(ExitToAppIcon)(({ theme }) => ({
    marginRight: theme.spacing(0.75),
}));

const UserBlock = (props) => {
    const { user } = useSelector((state) => state.auth);
    const [open, setOpen] = useState(false);
    const anchorRef = React.useRef(null);
    const containerRef = React.useRef(null);
    const history = useHistory();
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleSalesClick = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
        props.setView.setView({ ...props.view, sales: true, admin: false, practitioner: false });
        history.push("/reps/practitioners");
    };

    const handleAdminClick = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
        props.setView.setView({ ...props.view, admin: true, practitioner: false, sales: false });
        history.push("/admin/learning/section");
    };

    const handlePractitionerClick = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
        props.setView.setView({ ...props.view, admin: false, practitioner: true, sales: false });
        history.push("/dashboard");
    };

    const deleteCookie = (name) => {
        document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    };

    const handleLogout = () => {
        let backToUrl = `https://www.cellcore.com`;
        if (process.env.REACT_APP_ENVIRONMENT === "production") {
            backToUrl = `https://www.cellcore.com`;
        } else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
            backToUrl = `https://staging.cellcore.com`;
        } else if (process.env.REACT_APP_ENVIRONMENT === "development") {
            backToUrl = `https://www.cellcore.com`;
        }
        setOpen(!open);
        deleteCookie("cellcore_logged_in");
        deleteCookie("cellcore_uuid");
        deleteCookie("cellcore_shop_multi");
        window.location.href = backToUrl + `/account/logout/`;
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    //CLOSES DROP DOWN USER MENU SELECTON ON MAIN NAV CLOSURE
    useEffect(() => {
        if (!props.open) {
            setOpen(false);
        }
    }, [props.open]);

    return (
        <UserBlockContainer ref={containerRef}>
            <ButtonContainer>
                <UserBlockTrigger
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={() => handleToggle()}
                    open={props.open}
                    menuOpen={props.open}
                >
                    <ProfileIcon
                        iconData={user}
                        size="mid"
                        background="purple"
                        changeProfileUser={props.changeProfileUser}
                    ></ProfileIcon>
                    {props.open && (
                        <ButtonArrowContainer>
                            <ButtonText>{user?.name}</ButtonText>
                            <ExpandArrowIcon />
                        </ButtonArrowContainer>
                    )}
                </UserBlockTrigger>
                <PopperMenu
                    placement="bottom-start"
                    anchorEl={anchorRef.current}
                    role={undefined}
                    container={containerRef.current}
                    open={open}
                >
                    <ClickAwayListener onClickAway={handleClose}>
                        <PopperList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            <PopperListHeaderContainer>
                                <PopperListHeaderItems>{user.name}</PopperListHeaderItems>
                                <PopperListHeaderItemsSmall>{user.email}</PopperListHeaderItemsSmall>
                            </PopperListHeaderContainer>
                            {(user?.access === "5" && user?.status === "3") ||
                            (user?.access === "6" && user?.status === "3") ||
                            (user?.access === "4" && user?.status === "3") ||
                            (user?.access === "4" && user?.status === "2") ||
                            (user?.access === "7" && user?.status === "3") ? (
                                <PopperListItemsContainer>
                                    <PopperListItems onClick={(e) => handlePractitionerClick(e)}>
                                        Practitioner Dashboard
                                    </PopperListItems>
                                </PopperListItemsContainer>
                            ) : null}
                            {(user?.access === "5" && user?.status === "3") ||
                            (user?.access === "7" && user?.status === "3") ? (
                                <PopperListItemsContainer>
                                    <PopperListItems onClick={(e) => handleAdminClick(e)}>
                                        Admin Dashboard
                                    </PopperListItems>
                                </PopperListItemsContainer>
                            ) : null}
                            {(user?.access === "6" && user?.status === "3") ||
                            (user?.access === "4" && user?.status === "3") ||
                            (user?.access === "4" && user?.status === "2") ||
                            (user?.access === "7" && user?.status === "3") ? (
                                <PopperListItemsContainer>
                                    <PopperListItems onClick={(e) => handleSalesClick(e)}>
                                        Sales Dashboard
                                    </PopperListItems>
                                </PopperListItemsContainer>
                            ) : null}
                            <PopperListItemsContainer onClick={(e) => handleLogout(e)}>
                                <PopperExitIcon></PopperExitIcon>
                                Log Out Of Cellcore
                            </PopperListItemsContainer>
                        </PopperList>
                    </ClickAwayListener>
                </PopperMenu>
            </ButtonContainer>
        </UserBlockContainer>
    );
};

export default UserBlock;
