import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TopBarInNav from "../../../../common/SideBar/TopBarInNav";
import { getAssessments } from "../AdminFormCalls";
import { assessmentFlagAction, changeFlagAction, viewStateAction } from "../toolsAdminSlice";

const CustomNavArea = styled("div")(({ theme }) => ({
    height: "100%",
    overflow: "hidden",
    backgroundColor: "#FFFFFF",
    paddingTop: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
        paddingTop: 0,
    },
}));
const CustomInsideNav = styled("nav")(({ theme }) => ({
    width: "100%",
    maxHeight: "calc(100vh - 64px)",
    overflowX: "scroll",
    [theme.breakpoints.down("md")]: {
        maxHeight: "calc(100vh - 4px)",
    },
}));
const CustomList = styled(List)({
    width: "100%",
    paddingTop: 0,
});
const CustomAccordion = styled(Accordion)({
    margin: "0",
    boxShadow: "0px 1px 1px -1px rgb(0 0 0 / 14%), 0px 1px 1px 0px rgb(0 0 0 / 14%)",
    paddingBottom: 16,
});
const CustomAccordionDetails = styled(AccordionDetails)({
    padding: 0,
    flexDirection: "column",
});
const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    color: theme.palette.common.text_gray,
    fontWeight: "700",
    margin: "0",
    minHeight: "34px !important",
    padding: "0 10px !important",
    "& div": {
        margin: "8px 0 !important",
    },
}));
const CustomListItem = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(0, 2),
    marginBottom: 0,
    cursor: "pointer",
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    color: theme.palette.common.text_gray,
    [theme.breakpoints.down("md")]: {
        margin: "4px 0",
    },
    "&:hover": {
        backgroundColor: theme.palette.common.blue,
        color: theme.palette.common.white,
    },
}));
const CustomListItemText = styled(ListItemText)({
    lineHeight: "1rem",
    margin: "4px 0",
});

/**
 * This is a custom sidebar for the Tools Admin section of this application.
 * It was necessary to build out its own so it could be dynamic as you add more forms to the list.
 *
 * @param {*} props
 * @author conorcook7
 */
const SideBarToolsAdmin = (props) => {
    const { viewState, assessmentFlag } = useSelector((state) => state.assessmentAdmin);
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(1);
    const [assessments, setAssessments] = useState();

    useEffect(() => {
        let isSubscribed = true;
        (async () => {
            const results = await getAssessments();
            if (isSubscribed) {
                results && setAssessments(results);
                if (assessmentFlag) {
                    setSelected(assessmentFlag);
                }
            }
        })();
        return () => {
            isSubscribed = false;
            setSelected(1);
            setAssessments();
        };
    }, [viewState]);

    const handleListClick = (index) => {
        dispatch(assessmentFlagAction(index));
        dispatch(changeFlagAction(true));
        dispatch(
            viewStateAction({
                ...viewState,
                activeView: 1,
                activeQuestion: null,
                activeSection: null,
            }),
        );
        setSelected(index);
    };

    return (
        <CustomNavArea>
            <CustomInsideNav>
                <TopBarInNav title={"Tools Admin"} backToMain={props.backToMain}></TopBarInNav>
                <CustomAccordion defaultExpanded={true} square={true} onClick={() => props.onSecondaryNavClick()}>
                    <CustomAccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                        <ExpandMoreIcon></ExpandMoreIcon>
                        <span>Assessments</span>
                    </CustomAccordionSummary>
                    <CustomAccordionDetails>
                        <CustomList>
                            {assessments?.map((form, index) => {
                                return (
                                    <CustomListItem
                                        key={index}
                                        onClick={() => handleListClick(form.id)}
                                        sx={
                                            form.id === selected
                                                ? {
                                                      backgroundColor: "common.blue",
                                                      color: "common.white",
                                                      fontWeight: 300,
                                                  }
                                                : {}
                                        }
                                    >
                                        <CustomListItemText>{form.desc}</CustomListItemText>
                                    </CustomListItem>
                                );
                            })}
                        </CustomList>
                    </CustomAccordionDetails>
                </CustomAccordion>
            </CustomInsideNav>
        </CustomNavArea>
    );
};
export default SideBarToolsAdmin;
