import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    knownPatients: [],
    assessments: {
        allAssessments: [],
    },
    thyroid: {
        assessmentData: {
            all: [],
            questions: [],
            labNotes: [],
        },
        assessments: {
            all: [],
            unique: [],
        },
        currentPatient: {
            user_id: "",
            email: "",
            name: "",
            first_name: "",
            last_name: "",
            shopify_id: "",
        },
    },
};

export const toolsSlice = createSlice({
    name: "tools",
    initialState,
    reducers: {
        setAssessmentData: (state, action) => {
            if (action.payload.all) {
                state.thyroid.assessmentData.all = action.payload.all;
            }
            if (action.payload.questions) {
                state.thyroid.assessmentData.questions = action.payload.questions;
            }
            if (action.payload.labNotes) {
                state.thyroid.assessmentData.labNotes = action.payload.labNotes;
            }
        },
        setAssessments: (state, action) => {
            if (action.payload.all) {
                state.assessments.allAssessments = action.payload.all;
            }
            if (action.payload.key) {
                state[action.payload.key].assessments.all = action.payload.specific;
                state[action.payload.key].assessments.unique = action.payload.unique;
            }
            if (action.payload.knownPatients) {
                state.knownPatients = action.payload.knownPatients;
            }
            if (action.payload.currentPatient) {
                state.thyroid.currentPatient = action.payload.currentPatient;
            }
        },
        changeCurrentThyroidPatient: (state, action) => {
            state.thyroid.currentPatient = action.payload;
        },
    },
});

export const { changeCurrentThyroidPatient, setAssessments, setAssessmentData } = toolsSlice.actions;

export default toolsSlice.reducer;

export const { toolsStore } = toolsSlice.actions;
