import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box
    }
    body {
        transition: all 0.50s linear;
        margin: 0;
        font-family: "gotham";
    }

    img, picture { max-width: 100%; }

    a {
        text-decoration: none;
        cursor: pointer;
    }

    [data-show-live="false"] { display: none !important; }
    [data-fade-live] { transition: opacity .4s; }
    [data-fade-live="0"] { opacity: 0; }
    [data-fade-live="1"] { opacity: 1; }
`;

export default GlobalStyle;
