import React from "react";

import { styled } from "@mui/material/styles";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Avatar from "@mui/material/Avatar";

const InitialsContainer = styled("div", { shouldForwardProp: (prop) => prop !== "background" || prop !== "type" })(
    ({ theme, background, type }) => ({
        color: theme.palette.common.white,
        position: "relative",
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.common[background],
        textTransform: "uppercase",
        [theme.breakpoints.down("md")]: {
            marginRight: 4,
        },
    }),
);

const AvatarEl = styled(Avatar, { shouldForwardProp: (prop) => prop !== "size" && prop !== "background" })(
    ({ theme, size, background }) => ({
        "&&": {
            cursor: "pointer",
            backgroundColor: theme.palette.common[background],
            color: theme.palette.common.white,
            ...(size === "xl" && {
                width: 220,
                height: 220,
                margin: theme.spacing(2, "auto"),
                fontSize: "84px",
                lineHeight: "84px",
                fontWeight: 700,
                [theme.breakpoints.down("md")]: {
                    width: "100%",
                    height: "100%",
                    margin: theme.spacing(1, "auto"),
                    fontSize: "54px",
                    lineHeight: "54px",
                },
            }),
        },
    }),
);

const EditIcon = styled(PhotoCameraIcon)(({ theme }) => ({
    position: "absolute",
    bottom: -10,
    right: -10,
    color: theme.palette.common.grey_dark,
    backgroundColor: theme.palette.common.grey_light,
    opacity: 0.8,
    padding: "10%",
    borderRadius: "50%",
}));

const ProfileIcon = (props) => {
    const getInitials = (d) => {
        if (d && "first_name" in d && d.first_name?.length > 0 && "last_name" in d && d.last_name?.length > 0) {
            return d.first_name?.charAt(0) + d.last_name?.charAt(0);
        }
        if (d && "family_name" in d && d.family_name?.length > 0 && "given_name" in d && d.given_name?.length > 0) {
            return d.given_name?.charAt(0) + d.family_name?.charAt(0);
        }
        //RETURN NULL HERE AS A FALL BACK, AVATAR COMPONENT WILL GO TO ALT TEXT NEXT FOR INITIALS AS FALLBACK
        return null;
    };
    const handleEditAndProfileChange = () => {
        if (props.changeProfileUser && props.returnUserDetails) {
            props.changeProfileUser(
                props.returnUserDetails(props.iconData?.user_id ? props.iconData.user_id : props.iconData.uuid),
            );
        }
        if (props.type === "edit") {
            props.openModal("PROFILE_PICTURE");
        }
    };

    return (
        <>
            {props.type === "edit" ? (
                <InitialsContainer onClick={() => handleEditAndProfileChange()}>
                    <AvatarEl
                        size={props.size}
                        background={props.background}
                        src={props?.iconData?.picture}
                        alt={`${props.iconData?.first_name} ${props.iconData?.last_name} Avatar`}
                    >
                        {getInitials(props.iconData)}
                    </AvatarEl>
                    <EditIcon sx={{ color: "gray" }} />
                </InitialsContainer>
            ) : (
                <AvatarEl
                    size={props.size}
                    background={props.background}
                    src={props.iconData?.picture}
                    alt={`${props.iconData?.first_name} ${props.iconData?.last_name} Avatar`}
                    onClick={() => handleEditAndProfileChange()}
                >
                    {getInitials(props.iconData)}
                </AvatarEl>
            )}
        </>
    );
};

export default ProfileIcon;
