import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    openAddSuccess: false,
    openEditSuccess: false,
    flag: false,
};

export const resourceAdminSlice = createSlice({
    name: "resourceAdmin",
    initialState,
    reducers: {
        changeOpenAddSuccess: (state, action) => {
            state.openAddSuccess = action.payload;
        },
        changeOpenEditSuccess: (state, action) => {
            state.openEditSuccess = action.payload;
        },
        changeFlag: (state, action) => {
            state.flag = action.payload;
        },
    },
});

export const { changeOpenAddSuccess, changeOpenEditSuccess, changeFlag } = resourceAdminSlice.actions;

export default resourceAdminSlice.reducer;
