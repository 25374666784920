import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    routes: {},
};

export const routeSlice = createSlice({
    name: "routes",
    initialState,
    reducers: {
        changeRoutes: (state, action) => {
            state.routes = action.payload;
        },
    },
});

export const { changeRoutes } = routeSlice.actions;

export default routeSlice.reducer;
