import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    changeMessageWindowSelected,
    changeNewMessagesCount,
    changeNewMessagesCountAndSelected,
    updateChatSelected,
    showMessagesToggle,
    deleteFromSideBar,
    updateKnownChatUsersAndConversations,
    resetIndividualUnreadMessages,
    updateUnreadByConversation,
    resetPinnedMessages,
} from "../communitySlice";

import * as communityUtils from "./../communityUtilities";

import { useLazyQuery, useMutation } from "@apollo/client"; //GraphQL
import {
    searchMessages,
    getSavedItems,
    getCurrentMessageViolations,
    deleteConversation,
    updateConversationMember,
    messagesUnread,
} from "../calls";

import TopBarInNav from "./../../../common/SideBar/TopBarInNav";

import { styled } from "@mui/material/styles";
import { Accordion, AccordionSummary, AccordionDetails, Icon, List, ListItem } from "@mui/material";
import Badge from "@mui/material/Badge";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const NavArea = styled("div")(({ theme }) => ({
    "&&": {
        height: "100%",
        overflow: "hidden",
        backgroundColor: theme.palette.common.white,
        paddingTop: theme.spacing(8),
        [theme.breakpoints.down("md")]: {
            paddingTop: 0,
        },
    },
}));

const InsideNav = styled("nav")(({ theme }) => ({
    "&&": {
        width: "100%",
        maxHeight: "calc(100vh - 64px)",
        overflowX: "scroll",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
            width: 0,
            height: 0,
        },
        [theme.breakpoints.down("md")]: {
            maxHeight: "calc(100vh - 4px)",
        },
    },
}));

const AccordionEl = styled(Accordion)(({ theme }) => ({
    "&&&": {
        marginTop: theme.spacing(1.5),
        boxShadow: "0px 1px 1px -1px rgb(0 0 0 / 14%), 0px 1px 1px 0px rgb(0 0 0 / 14%)",
        paddingBottom: theme.spacing(2),
    },
}));

const AccordionSummaryEl = styled(AccordionSummary)(({ theme }) => ({
    "&&&": {
        color: theme.palette.text.main,
        fontWeight: 700,
        marginTop: "0",
        minHeight: 24,
        padding: theme.spacing(0, 1),
        alignItems: "center",
        "& div": {
            margin: theme.spacing(1, 0),
        },
    },
}));

const AccordionDetailsEl = styled(AccordionDetails)(({ theme }) => ({
    "&&": {
        padding: 0,
    },
}));

const AccordionList = styled(List)(({ theme }) => ({
    "&&": {
        width: "100%",
        paddingTop: 0,
        "&::before": {
            content: "none",
        },
    },
}));

const ListItemEl = styled(ListItem, { shouldForwardProp: (prop) => prop !== "selected" })(({ theme, selected }) => ({
    "&&": {
        padding: theme.spacing(0.75, 2.5, 0.75, 2),
        marginBottom: 0,
        cursor: "pointer",
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        color: theme.palette.text.dark,
        transition: theme.transitions.create(["background-color", "color", "font-weight"], {
            easing: theme.transitions.easing.easeOut,
            duration: ".4s",
        }),
        "&:hover": {
            backgroundColor: theme.palette.common.blue,
            color: theme.palette.common.white,
            "& svg": {
                display: "block !important",
            },
        },
        ...(selected && {
            backgroundColor: theme.palette.common.blue,
            color: theme.palette.common.white,
            fontWeight: 300,
        }),
        ...(!selected && {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.text.dark,
        }),
        [theme.breakpoints.down("md")]: {
            margin: "0",
            padding: theme.spacing(0.5, 1.5, 0.75),
        },
    },
}));

const ListImage = styled("img")(({ theme }) => ({
    "&&": {
        width: 22,
        height: 22,
        marginRight: 8,
    },
}));

const ListHash = styled("span")(({ theme }) => ({
    "&&": {
        marginRight: 6,
        fontWeight: 500,
    },
}));

const ListIcon = styled(Icon)(({ theme }) => ({
    "&&": {
        marginRight: 0,
        minWidth: 30,
        fontSize: "1.3rem",
    },
}));

const ListItemTitle = styled("p", { shouldForwardProp: (prop) => prop !== "boldItem" })(({ theme, boldItem }) => ({
    "&&": {
        margin: "0",
        fontSize: "1rem",
        overflow: "hidden",
        ...(boldItem && {
            fontWeight: 500,
        }),
        ...(!boldItem && {
            fontWeight: 300,
        }),
    },
}));

const ListItemTitleSpan = styled("span", { shouldForwardProp: (prop) => prop !== "boldItem" })(
    ({ theme, boldItem }) => ({
        "&&": {
            display: "flex",
            alignContent: "center",
            alignItems: "center",
        },
    }),
);

const TitleContainer = styled("span")(({ theme }) => ({
    "&&": {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
    },
}));

const TitleTagSpan = styled("span", { shouldForwardProp: (prop) => prop !== "isSelected" })(
    ({ theme, isSelected }) => ({
        "&&": {
            margin: theme.spacing(0, 0, 0, 0.75),
            padding: theme.spacing(0.35, 0.75, 0.35),
            backgroundColor: theme.palette.common.blue_dark,
            color: theme.palette.common.white,
            borderRadius: "10px",
            fontSize: "8px",
            lineHeight: "8px",
            fontWeight: 600,
            transition: theme.transitions.create(["background-color", "color"], {
                easing: theme.transitions.easing.easeOut,
                duration: "0.4s",
            }),
            ...(isSelected && {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.common.blue_dark,
            }),
        },
    }),
);

const DirectMessagesName = styled("span")(({ theme }) => ({
    "&&": {
        flex: 1,
        textTransform: "capitalize",
        lineHeight: "1",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
}));

const HighlightOffIconEl = styled(HighlightOffIcon, { shouldForwardProp: (prop) => prop !== "showDeleteIcon" })(
    ({ theme, showDeleteIcon }) => ({
        "&&": {
            position: "absolute",
            top: "50%",
            right: 4,
            transform: "translate(0, -50%)",
            fontSize: "1rem !important",
            color: theme.palette.common.grey,
            zIndex: 5,
            display: "none",
            ...(showDeleteIcon && {
                display: "none",
            }),
            ...(!showDeleteIcon && {
                display: "flex",
            }),
        },
    }),
);

const BadgeEl = styled(Badge)(({ theme }) => ({
    "&&": {
        width: "100%",
    },
    "&& span": {
        top: 10,
    },
}));

export default function SideBarChat(props) {
    const dispatch = useDispatch();

    const globalState = useSelector((state) => state.global);
    const communityState = useSelector((state) => state.community);
    const auth = useSelector((state) => state.auth);

    const [unreadMessages, setUnreadMessages] = useState([]);
    const [mentionedMessages, setMentionedMessages] = useState([]);
    const [mentionedMessagesCount, setMentionedMessagesCount] = useState(0);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const [savedMessages, setSavedMessages] = useState([]);
    const [savedMessagesCount, setSavedMessagesCount] = useState(0);
    const [reportedMessages, setReportedMessages] = useState([]);
    const [reportedMessagesCount, setReportedMessagesCount] = useState(0);
    const showDeleteIcon = false;
    const [sectionSelected, setSectionSelected] = useState({});
    const listEndRef = useRef(null);

    //UTILITY FUNCTIONS
    const testIcon = (string) => {
        if (string) {
            if (string.length > 1 && (string.indexOf(".jpg") >= 0 || string.indexOf(".png") >= 0)) {
                return "image";
            } else if (string.length === 1 && string.indexOf("#") >= 0) {
                return "hash";
            } else if (string.length > 1 && (string.indexOf(".jpg") < 0 || string.indexOf(".png") < 0)) {
                return "icon";
            }
        }
    };

    const systemCheck = (selectedObj, previouslySelectedObj) => {
        //CHECKS TO SEE WHAT SECTION OF THE COMMUNITY USER IS IN, IF IT'S A 'SYSTEM' SECTION (MENTIONS, SAVED, ETC), GETS THE MESSAGES FOR THAT SECTION
        if (previouslySelectedObj) {
            dispatch(
                changeNewMessagesCount({
                    section: previouslySelectedObj.section,
                    channel: previouslySelectedObj.channel,
                    unread_messages: 0,
                }),
            );
        }
        const selected = selectedObj ? selectedObj : communityState.selected;
        if (selected?.channel?.toLowerCase() === "mentions") {
            getMentionedMessages(); //THIS CALL WILL UPDATE COMMUNITYSTATE AND CHANGE DATA IN CHAT WINDOW ON UPDATE FOR @ MENTIONS
            dispatch(updateUnreadByConversation({}));
            setMentionedMessagesCount(mentionedMessagesCount + 1);
        } else if (selected?.channel?.toLowerCase() === "saved messages") {
            getSavedMessages(); //GETS SAVED MESSAGES FOR CHAT WINDOW
            dispatch(updateUnreadByConversation({}));
            setSavedMessagesCount(savedMessagesCount + 1);
        } else if (selected?.channel?.toLowerCase() === "reported messages") {
            getReportedMessages(); //GETS REPORTED MESSAGES FOR CHAT WINDOW
            dispatch(updateUnreadByConversation({}));
            setReportedMessagesCount(reportedMessagesCount + 1);
        } else if (selected?.channel?.toLowerCase() === "new message feed") {
            getUnreadMessages(); //GETS REPORTED MESSAGES FOR CHAT WINDOW
            dispatch(resetPinnedMessages());
            setUnreadMessagesCount(unreadMessagesCount + 1);
        }
    };

    const [updateConversationCall] = useMutation(updateConversationMember);

    const handleChangeWindow = (e) => {
        if (e.nativeEvent?.srcElement?.nodeName !== "svg" && e.nativeEvent?.srcElement?.nodeName !== "path") {
            if (communityState.selected.channel !== e.currentTarget.dataset.channel) {
                dispatch(
                    showMessagesToggle({
                        boolean: false,
                        sideBarSelected: true,
                    }),
                );
                const selectedObj = {
                    section: e.currentTarget.dataset.section,
                    channel: e.currentTarget.dataset.channel,
                    conversation_id: parseInt(e.currentTarget.dataset.conversationId),
                    messageType: e.currentTarget.dataset.messageType,
                    showExternal: e.currentTarget.dataset.showExternal === "true" ? true : false,
                    description: e.currentTarget.dataset.description,
                };
                const previouslySelected = JSON.parse(JSON.stringify(communityState.selected));
                const previouslySelectedObj = {
                    section: previouslySelected.section,
                    channel: previouslySelected.channel,
                    conversation_id: parseInt(previouslySelected.conversation_id),
                    unread_messages: 0,
                    description: previouslySelected.description ? previouslySelected.description : ``,
                };
                setSectionSelected(selectedObj);
                if (selectedObj.messageType === "system") {
                    if (!selectedObj.showExternal) {
                        systemCheck(selectedObj, previouslySelectedObj);
                    } else if (selectedObj.showExternal) {
                        switchWindow(selectedObj, previouslySelectedObj);
                    }
                } else {
                    dispatch(updateUnreadByConversation({}));
                    switchWindow(selectedObj, previouslySelectedObj);
                }
                props.onSecondaryNavClick();
            }
        }
    };

    // BRING THIS BACK IN WHEN API CALL IS FIXED (WHEN SWITCHING CHANNELS DOESNT ERROR WITH updateConversationMember) - Here and in community index.js
    useEffect(() => {
        let conversation_id = 0;
        if (
            sectionSelected?.conversation_id >= 0 &&
            communityState.nav?.unreadMessages?.[sectionSelected?.conversation_id]?.unread_messages > 0
        ) {
            conversation_id = sectionSelected.conversation_id;
        } else if (Object.keys(communityState.navigateToThisMessage).length > 0) {
            conversation_id = communityState.navigateToThisMessage.conversation_id;
        }
        if (conversation_id > 0) {
            updateConversationCall({
                variables: {
                    conversation_id: conversation_id,
                    user_id: props.userData.user_id,
                    unread_messages: 0,
                },
            })
                .then((data) => {
                    dispatch(
                        resetIndividualUnreadMessages({
                            conversation_id: data.data.updateConversationMember.conversation_id,
                        }),
                    );
                })
                .catch((error) => {
                    console.error(error, {
                        errorMessage: error,
                        sectionSelected: sectionSelected,
                        conversation_id: conversation_id,
                        user_id: props.userData.user_id,
                        unreadMessages: communityState.nav.unreadMessages,
                    });
                });
        }
    }, [sectionSelected, communityState.navigateToThisMessage]);

    const checkAccessLevel = (section) => {
        //CHECKS IF ADMIN LEVEL ACCESS IS REQUIRED TO SEE A SECTION
        if (section.adminRequired === true && props.isAdmin) {
            return true;
        } else if (section.adminRequired === false || !Object.keys(section).includes("adminRequired")) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        //CHECK IF IN A 'SYSTEM' AREA OF THE COMMUNITY, IF SO, GET MESSAGES FOR THAT AREA
        systemCheck();
    }, []);

    const switchWindow = (switchTo, previouslySelectedObj) => {
        //SWITCHES WINDOW THROUGH COMMUNITYSTATE
        if (!previouslySelectedObj) {
            const previouslySelected = JSON.parse(JSON.stringify(communityState.selected));
            previouslySelectedObj = {
                section: previouslySelected.section,
                channel: previouslySelected.channel,
                conversation_id: parseInt(previouslySelected.conversation_id),
                unread_messages: 0,
                description: previouslySelected.description ? previouslySelected.description : ``,
            };
        }
        if (switchTo.conversation_id >= 0) {
            switchTo.unread_messages = 0;
            if (switchTo.conversation_id) {
                if (switchTo.conversation_id > 0) {
                    const selected =
                        communityState.selected.conversation_id >= 1
                            ? communityState.selected
                            : {
                                  ...communityState.defaultChannelOnLoad,
                                  section: "channels",
                                  channel: communityState.defaultChannelOnLoad.topic,
                                  conversation_id: parseInt(communityState.defaultChannelOnLoad.conversation_id),
                                  unread_messages: 0,
                                  description: communityState.defaultChannelOnLoad.description
                                      ? communityState.defaultChannelOnLoad.description
                                      : ``,
                              };
                    dispatch(
                        changeNewMessagesCountAndSelected({
                            newMessages: selected,
                            selected: switchTo,
                            showMessages: true,
                            conversation_id: switchTo.conversation_id ? switchTo.conversation_id : 0,
                        }),
                    );
                } else {
                    console.error("Does this ever fire? Get rid of this Else statement if not.");
                    dispatch(updateChatSelected(switchTo));
                }
            } else if (switchTo.showExternal) {
                dispatch(updateChatSelected(switchTo));
            }
        }
    };

    useEffect(() => {
        const usersList = communityState.nav.communityNavBar.sideBar.sections.direct_messages.children;
        if (usersList) {
            const knownUsersArr = [];
            const knownConversations = [];
            for (let c in usersList) {
                const members = usersList[c].conversation_members;
                if (members) {
                    members.forEach((m) => {
                        knownUsersArr.push(m);
                    });
                    knownConversations.push(members);
                }
            }
            knownUsersArr.push({
                user: props.userData,
                user_id: props.userData.user_id,
            });
            const knownUsers = [...new Map(knownUsersArr.map((item) => [item["user_id"], item])).values()];
            const newUsersObj = {};
            knownUsers.forEach((user) => {
                if (!newUsersObj[user.user_id]) {
                    newUsersObj[user.user_id] = user.user;
                }
            });
            dispatch(
                updateKnownChatUsersAndConversations({
                    users: newUsersObj,
                    conversations: knownConversations,
                }),
            );
        }
    }, [communityState.nav.communityNavBar.sideBar.sections.direct_messages]);

    //UNREAD MESSAGES FEED FUNCTIONS AND CALLS
    const [unreadMessagesCall, { data: foundUnreadMessages }] = useLazyQuery(messagesUnread, {
        variables: {
            user_id: auth.user.user_id,
            conversation_ids: communityState.nav.activeChannels,
            search: `@` + props.userData.user_id + `@`,
            page: 0,
            size: 160,
        },
    });
    const getUnreadMessages = () => {
        unreadMessagesCall();
    };
    useEffect(() => {
        if (communityState.unreadCallCount > 0) {
            unreadMessagesCall();
        }
    }, [communityState.unreadCallCount]);

    const checkMessageForShowConditions = (message) => {
        if (!message) console.error("no message", message);
        if (message?.text?.length <= 0 && message.attachments.length === 0 && message.reactions.length === 0) {
            return false;
        }
        if (message.status > 0) {
            return true;
        }
    };

    useEffect(() => {
        if (foundUnreadMessages?.messagesUnread?.rows) {
            const filteredByStatus = foundUnreadMessages.messagesUnread.rows.filter(function (m) {
                return checkMessageForShowConditions(m) && m.type !== "thread";
            });
            let unreadMessagesByConvos = {};
            filteredByStatus.forEach((message) => {
                const foundConversation = communityState.nav.conversationsByUser.filter(function (c) {
                    return c.id === message.conversation_id;
                });
                if (foundConversation.length > 0) {
                    if (unreadMessagesByConvos[foundConversation[0].topic]) {
                        unreadMessagesByConvos[foundConversation[0].topic].push(message);
                    } else {
                        unreadMessagesByConvos[foundConversation[0].topic] = [message];
                    }
                }
            });
            let unreadSorted = {};
            for (let c in unreadMessagesByConvos) {
                const convo = unreadMessagesByConvos[c];
                const sortConvosInternal = communityUtils.sortChannelMessages(convo, null);
                if (Object.keys(unreadSorted).includes(c)) {
                    unreadSorted[c].messages = convo;
                    unreadSorted[c].sortedByDate = sortConvosInternal;
                    unreadSorted[c].conversation_id = convo[0] ? convo[0].conversation_id : -1;
                } else {
                    unreadSorted[c] = {
                        messages: convo,
                        sortedByDate: sortConvosInternal,
                        conversation_id: convo[0] ? convo[0].conversation_id : -1,
                    };
                }
            }
            setUnreadMessages(filteredByStatus);
            dispatch(updateUnreadByConversation(unreadSorted));
        } else {
            setUnreadMessages([]);
        }
    }, [foundUnreadMessages, unreadMessagesCount]);
    useEffect(() => {
        dispatch(
            changeMessageWindowSelected({
                children: unreadMessages,
                selected: sectionSelected,
            }),
        );
    }, [unreadMessages]);

    //MENTIONS FUNCTIONS AND CALLS
    const [mentionedMessagesCall, { data: foundMentionedMessages }] = useLazyQuery(searchMessages, {
        variables: {
            user_id: auth.user.user_id,
            conversation_ids: communityState.nav.activeChannels,
            search: `@` + props.userData.user_id + `@`,
            page: 0,
            size: 100,
        },
    });
    const getMentionedMessages = () => {
        mentionedMessagesCall();
    };
    useEffect(() => {
        if (foundMentionedMessages?.searchMessages) {
            const filteredByStatus = foundMentionedMessages.searchMessages.rows.filter(function (m) {
                return m.status > 0;
            });
            setMentionedMessages(filteredByStatus);
        } else {
            setMentionedMessages([]);
        }
    }, [foundMentionedMessages]);
    useEffect(() => {
        dispatch(
            changeMessageWindowSelected({
                children: mentionedMessages,
                selected: sectionSelected,
            }),
        );
    }, [mentionedMessages, mentionedMessagesCount]);

    //SAVED MESSAGES FUNCTIONS AND CALLS
    const [savedMessagesCall, { data: foundSavedMessages }] = useLazyQuery(getSavedItems, {
        variables: {
            user_id: props.userData.user_id,
        },
    });
    const getSavedMessages = () => {
        savedMessagesCall();
    };
    useEffect(() => {
        if (
            foundSavedMessages &&
            foundSavedMessages.messageSavedItems &&
            foundSavedMessages.messageSavedItems.length > 0
        ) {
            const filteredByStatus = foundSavedMessages.messageSavedItems.filter(function (m) {
                return m.status > 0;
            });
            setSavedMessages(filteredByStatus);
        } else {
            setSavedMessages([]);
        }
    }, [foundSavedMessages]);
    useEffect(() => {
        dispatch(
            changeMessageWindowSelected({
                children: savedMessages,
                selected: sectionSelected,
            }),
        );
    }, [savedMessages, savedMessagesCount]);

    //VIOLATION - REPORTED MESSAGES FUNCTIONS AND CALLS
    const [reportedMessagesCall, { data: foundReportedMessages }] = useLazyQuery(getCurrentMessageViolations, {
        variables: {
            status: [5],
        },
    });
    const getReportedMessages = () => {
        reportedMessagesCall();
    };
    useEffect(() => {
        if (
            foundReportedMessages &&
            foundReportedMessages.messageViolations &&
            foundReportedMessages.messageViolations.length > 0
        ) {
            const filteredByStatus = foundReportedMessages.messageViolations.filter(function (m) {
                return m.status > 0;
            });
            setReportedMessages(filteredByStatus);
        } else {
            setReportedMessages([]);
        }
    }, [foundReportedMessages]);
    useEffect(() => {
        dispatch(
            changeMessageWindowSelected({
                children: reportedMessages,
                selected: sectionSelected,
            }),
        );
    }, [reportedMessages, reportedMessagesCount]);

    const [conversationDeleteCall] = useMutation(deleteConversation);
    const deleteThisConversation = (e) => {
        const convoId = e.target.closest("li").dataset.conversationId;
        if (convoId) {
            conversationDeleteCall({
                variables: {
                    id: parseInt(convoId),
                },
            }).then((data) => {
                dispatch(deleteFromSideBar(convoId));
                const previouslySelected = JSON.parse(JSON.stringify(communityState.selected));
                switchWindow(communityState.defaultChannelOnLoad, previouslySelected);
            });
        }
    };

    const createTitleTag = (original, tagTitle, isSelected) => {
        return (
            <TitleContainer>
                {original}
                <TitleTagSpan isSelected={isSelected}>{tagTitle}</TitleTagSpan>
            </TitleContainer>
        );
    };

    const checkForRestrictedViewing = (child, isAdmin, childName, section) => {
        let restrictedObj = {
            showChild: false,
            name: childName,
        };
        if (child.conversation_id) {
            //IF IS A DM, SHOW AUTOMATICALLY
            restrictedObj.showChild = true;
            return restrictedObj;
        }
        const topic = child.topic ? child.topic : null;
        if (topic) {
            const foundConversation = Object.values(communityState.nav.conversationsByUser).filter((c) => {
                return c.id === child.id;
            });
            //IF CONVERSATION IS A BASIC GROUP ONE FROM THE API
            if (foundConversation && foundConversation.length > 0) {
                const permissions = foundConversation[0].permissions;
                if (permissions) {
                    if (permissions.restrictViewingToAdmin && isAdmin) {
                        restrictedObj.showChild = true;
                        restrictedObj.name = createTitleTag(
                            childName,
                            `Admin Only`,
                            childName === communityState.selected.channel,
                        );
                    }
                    //IF IS NOT ADMIN AND CHANNEL IS RESTRICTED TO ADMINS
                    if (permissions.restrictViewingToAdmin && !isAdmin) {
                        restrictedObj.showChild = false;
                    }
                    //IF NOT RESTRICTED VIEWING RETURN TRUE
                    if (!permissions.restrictViewingToAdmin) {
                        restrictedObj.showChild = true;
                    }
                    if (isAdmin) {
                        restrictedObj.showChild = true;
                    }
                }
            }
            //IF CONVERSATION IS ONE MADE BY DEVS LIKE - REPORTED MESSAGES, MENTIONS, ADMIN TOOLS, ETC.
            else if (child.conversation_id === 0) {
                if (section.adminRequired && isAdmin) {
                    restrictedObj.showChild = true;
                }
                if (!section.adminRequired) {
                    restrictedObj.showChild = true;
                }
            }
        }
        return restrictedObj;
    };

    return (
        <NavArea>
            <InsideNav key={communityState.nav.communityNavBar.sideBar}>
                <TopBarInNav data={props} backToMain={props.backToMain}></TopBarInNav>
                {Object.keys(communityState.nav.communityNavBar.sideBar.sections).map((sectionName, index) => {
                    const section = communityState.nav.communityNavBar.sideBar.sections[sectionName];
                    if (checkAccessLevel(section)) {
                        return (
                            <AccordionEl defaultExpanded={true} square={true} key={communityState.selected + index}>
                                <AccordionSummaryEl aria-controls="panel1a-content" id={section.title}>
                                    <ExpandMoreIcon></ExpandMoreIcon>
                                    <span>{section.title}</span>
                                </AccordionSummaryEl>
                                <AccordionDetailsEl>
                                    {section && (
                                        <AccordionList>
                                            {section.children &&
                                                Object.keys(section.children).map((childName, childindex) => {
                                                    const child = section.children[childName];
                                                    const restrictedObj = checkForRestrictedViewing(
                                                        child,
                                                        props.isAdmin,
                                                        childName,
                                                        section,
                                                    );
                                                    if (
                                                        childName &&
                                                        childName.trim().length &&
                                                        restrictedObj.showChild
                                                    ) {
                                                        if ("is_archived" in child && child.is_archived === true) {
                                                            return null;
                                                        }
                                                        const idToString = child.id
                                                            ? child.id.toString()
                                                            : child.conversation_id.toString();
                                                        return (
                                                            <ListItemEl
                                                                key={index + childindex}
                                                                onClick={(e) => handleChangeWindow(e)}
                                                                data-channel={childName}
                                                                data-section={sectionName}
                                                                data-conversation-id={
                                                                    child.conversation_id || child.conversation_id === 0
                                                                        ? child.conversation_id
                                                                        : child.id
                                                                }
                                                                data-message-type={section.messageType}
                                                                selected={childName === communityState.selected.channel}
                                                                data-show-external={child.showExternal}
                                                                data-description={child.description}
                                                            >
                                                                <BadgeEl
                                                                    color="info"
                                                                    max={50}
                                                                    badgeContent={
                                                                        communityState.nav?.unreadMessages[idToString]
                                                                            ?.unread_messages
                                                                    }
                                                                >
                                                                    {testIcon(child.icon) === "image" && (
                                                                        <ListImage src={child.icon} alt={childName} />
                                                                    )}
                                                                    {testIcon(child.icon) === "hash" && (
                                                                        <ListHash>{child.icon}</ListHash>
                                                                    )}
                                                                    {testIcon(child.icon) === "icon" && (
                                                                        <ListIcon>{child.icon?.toLowerCase()}</ListIcon>
                                                                    )}
                                                                    <ListItemTitle
                                                                        boldItem={
                                                                            Object.keys(
                                                                                communityState.nav.unreadMessages,
                                                                            ).includes(idToString) &&
                                                                            communityState.nav.unreadMessages[
                                                                                idToString
                                                                            ].unread_messages > 0
                                                                        }
                                                                    >
                                                                        {sectionName === "channels" && (
                                                                            <ListItemTitleSpan>
                                                                                #{restrictedObj.name}{" "}
                                                                                {globalState.showDevTools && (
                                                                                    <span>&nbsp;- {child.id}</span>
                                                                                )}
                                                                            </ListItemTitleSpan>
                                                                        )}
                                                                        {sectionName !== "channels" && (
                                                                            <DirectMessagesName>
                                                                                {childName.trim()}
                                                                            </DirectMessagesName>
                                                                        )}
                                                                    </ListItemTitle>
                                                                    {sectionName === "direct_messages" && (
                                                                        <HighlightOffIconEl
                                                                            onClick={(e) => deleteThisConversation(e)}
                                                                            showDeleteIcon={!showDeleteIcon}
                                                                        ></HighlightOffIconEl>
                                                                    )}
                                                                </BadgeEl>
                                                            </ListItemEl>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                        </AccordionList>
                                    )}
                                </AccordionDetailsEl>
                                {section.title === "Direct Messages" && <div ref={listEndRef}></div>}
                            </AccordionEl>
                        );
                    } else {
                        return null;
                    }
                })}
            </InsideNav>
        </NavArea>
    );
}
