import { gql } from "@apollo/client"; //GraphQL

export const getUser = gql`
    query ($id: String!) {
        user(id: $id) {
            id
            uuid
            first_name
            last_name
            type
            status
            ts
            updated_ts
            parent_id
            city
            state
            country
            company
            website
            marketing
            practice_type
            office_email
            picture
            user_metadata {
                last_active
            }
            user_info {
                conversation_id
                last_activity
            }
        }
    }
`;

export const usersByData = gql`
    query ($search: String!, $page: Int!, $size: Int!) {
        usersByData(search: $search, page: $page, size: $size) {
            count
            rows {
                id
                uuid
                first_name
                last_name
                type
                status
                ts
                updated_ts
                parent_id
                city
                state
                country
                company
                website
                marketing
                practice_type
                office_email
                picture
                user_metadata {
                    last_active
                }
                user_info {
                    conversation_id
                    last_activity
                }
            }
        }
    }
`;

export const conversation = gql`
    query ($id: Int!, $user_id: String!) {
        conversation(id: $id, user_id: $user_id) {
            id
            is_channel
            is_group
            is_dm
            creator
            topic
            is_archived
            is_private
            num_members
            parent_id
            ts
            status
            notes {
                message
                referenceNumber
            }
            permissions
            order
            is_read_only
            event_type
            description
            is_default
            user {
                id
                uuid
                first_name
                last_name
                type
                status
                ts
                updated_ts
                parent_id
                city
                state
                country
                company
                website
                office_email
                picture
            }
            conversation_members {
                id
                user_id
                last_active
                unread_messages
                user {
                    id
                    uuid
                    first_name
                    last_name
                    type
                    status
                    ts
                    updated_ts
                    parent_id
                    city
                    state
                    country
                    company
                    website
                    office_email
                    picture
                }
            }
        }
    }
`;

export const conversationsByUser = gql`
    query ($user_id: String!) {
        conversationByUser(user_id: $user_id) {
            id
            is_channel
            is_group
            is_dm
            topic
            is_archived
            is_private
            is_read_only
            ts
            status
            notes {
                message
                referenceNumber
            }
            permissions
            order
            description
            is_default
            conversation_members {
                id
                user_id
                last_active
                unread_messages
                status
                ts
                user {
                    id
                    uuid
                    first_name
                    last_name
                    email
                    status
                    picture
                }
            }
            user {
                id
                uuid
                first_name
                last_name
                status
            }
        }
    }
`;

export const conversationCreated = gql`
    subscription ($user_id: String!, $practitioner_id: String) {
        conversationCreated(user_id: $user_id, practitioner_id: $practitioner_id) {
            conversation {
                id
                is_channel
                is_group
                is_dm
                topic
                is_archived
                is_private
                is_read_only
                status
                notes {
                    message
                    referenceNumber
                }
                permissions
                order
                event_type
                is_default
                user {
                    id
                    uuid
                    first_name
                    last_name
                    email
                }
                conversation_members {
                    id
                    user_id
                    unread_messages
                    status
                    user {
                        id
                        uuid
                        first_name
                        last_name
                        email
                        status
                        picture
                    }
                }
            }
        }
    }
`;

export const getMessages = gql`
    query ($user_id: String!, $conversation_id: Int!, $page: Int!, $size: Int!) {
        messages(user_id: $user_id, conversation_id: $conversation_id, page: $page, size: $size) {
            count
            rows {
                edited
                id
                user_id
                conversation_id
                parent_id
                type
                text
                ts
                status
                replies
                last_replied_date
                is_saved
                user_ids
                pinned
                moved
                transfers {
                    id
                    conversation_id
                    message_id
                    parent_id
                    ts
                }
                user {
                    id
                    uuid
                    first_name
                    last_name
                    email
                    status
                    type
                    ts
                    city
                    state
                    country
                    company
                    website
                    marketing
                    practice_type
                    office_email
                    picture
                }
                reactions {
                    id
                    conversation_id
                    message_id
                    user_id
                    action
                    status
                }
                tagged {
                    id
                    user_id
                    status
                    tagged_user {
                        id
                        uuid
                        email
                        first_name
                        last_name
                    }
                }
                attachments {
                    id
                    conversation_id
                    message_id
                    type
                    name
                    title
                    parent_message_id
                    parrent_conversation_id
                    filetype
                    size
                    url
                    thumb_360
                    thumb_80
                    status
                    ts
                }
                message_violations {
                    id
                    message_id
                    reviewed_by
                    reported_by
                    text
                    violation_type
                    comment
                    status
                    reported_user {
                        id
                        uuid
                        first_name
                        last_name
                        email
                        status
                        ts
                    }
                }
            }
        }
    }
`;

export const getThreads = gql`
    query ($conversation_ids: [Int], $message_id: Int) {
        threads(conversation_ids: $conversation_ids, message_id: $message_id) {
            id
            user_id
            conversation_id
            parent_id
            type
            text
            ts
            replies
            status
            pinned
            user {
                id
                uuid
                first_name
                last_name
                email
                status
                type
                ts
                picture
            }
            reactions {
                id
                conversation_id
                message_id
                user_id
                action
                status
            }
            tagged {
                id
                user_id
                status
                tagged_user {
                    id
                    uuid
                    email

                    first_name
                    last_name
                }
            }
            attachments {
                id
                conversation_id
                message_id
                type
                name
                title
                parent_message_id
                parrent_conversation_id
                filetype
                size
                url
                thumb_360
                thumb_80
                status
                ts
            }
        }
    }
`;

//This subscrption describes data published to the client for messages added to a given chatroomId.

export const messageUpdated = gql`
    subscription ($conversationIds: [Int]!, $conversationId: Int!) {
        messageUpdated(conversationIds: $conversationIds, conversationId: $conversationId) {
            message {
                id
                user_id
                conversation_id
                parent_id
                type
                text
                status
                replies
                ts
                event_type
                pinned
                edited
                moved
                user {
                    id
                    uuid
                    first_name
                    last_name
                    email
                    status
                    picture
                }
                reactions {
                    id
                    conversation_id
                    message_id
                    user_id
                    action
                    status
                }
                attachments {
                    id
                    conversation_id
                    message_id
                    type
                    name
                    title
                    parent_message_id
                    parrent_conversation_id
                    filetype
                    size
                    url
                    thumb_360
                    thumb_80
                    status
                    ts
                }
            }
        }
    }
`;

export const createNewConversationMember = gql`
    mutation (
        $conversation_id: Int!
        $user_id: String
        $last_active: DateTime
        $last_message_id: Int
        $unread_messages: Int
        $status: Int
    ) {
        createConversationMember(
            conversation_id: $conversation_id
            user_id: $user_id
            last_active: $last_active
            last_message_id: $last_message_id
            unread_messages: $unread_messages
            status: $status
        ) {
            id
            conversation_id
            last_active
            ts
            unread_messages
        }
    }
`;

export const updateConversation = gql`
    mutation (
        $id: Int!
        $topic: String
        $is_archived: Boolean
        $is_private: Boolean
        $is_read_only: Boolean
        $permissions: String
        $order: Int
        $description: String
        $is_default: Boolean
    ) {
        updateConversation(
            id: $id
            topic: $topic
            is_archived: $is_archived
            is_private: $is_private
            is_read_only: $is_read_only
            permissions: $permissions
            order: $order
            description: $description
            is_default: $is_default
        ) {
            id
            topic
            is_archived
            is_private
            is_read_only
            permissions
            order
            status
            event_type
            is_default
        }
    }
`;

export const updateConversationMember = gql`
    mutation (
        $conversation_id: Int!
        $user_id: String!
        $last_active: DateTime
        $last_message_id: Int
        $unread_messages: Int
        $status: Int
    ) {
        updateConversationMember(
            conversation_id: $conversation_id
            user_id: $user_id
            last_active: $last_active
            last_message_id: $last_message_id
            unread_messages: $unread_messages
            status: $status
        ) {
            conversation_id
            user_id
            unread_messages
            status
        }
    }
`;

export const deleteConversationMember = gql`
    mutation ($id: Int!, $status: Int) {
        deleteConversationMember(id: $id, status: $status) {
            id
            status
        }
    }
`;

export const removeConversationMember = gql`
    mutation ($conversation_id: Int!, $user_id: String!) {
        removeConversationMember(conversation_id: $conversation_id, user_id: $user_id)
    }
`;

export const sendNewMessage = gql`
    mutation (
        $conversation_id: Int!
        $type: MessageTypeEnum
        $text: String
        $user_id: String
        $is_archived: Boolean
        $is_private: Boolean
        $parent_id: Int
    ) {
        createMessage(
            conversation_id: $conversation_id
            type: $type
            text: $text
            user_id: $user_id
            is_archived: $is_archived
            is_private: $is_private
            parent_id: $parent_id
        ) {
            id
            text
            ts
            parent_id
            user {
                id
                uuid
                first_name
                last_name
            }
        }
    }
`;

export const updateMessage = gql`
    mutation (
        $id: Int!
        $conversation_id: Int!
        $user_id: String
        $type: MessageTypeEnum
        $text: String
        $is_archived: Boolean
        $is_private: Boolean
        $parent_id: Int
        $status: Int
        $pinned: Int
        $action: MessageActionEnum
    ) {
        updateMessage(
            id: $id
            conversation_id: $conversation_id
            user_id: $user_id
            type: $type
            text: $text
            is_archived: $is_archived
            is_private: $is_private
            parent_id: $parent_id
            status: $status
            pinned: $pinned
            action: $action
        ) {
            id
            conversation_id
            text
            ts
            edited
            pinned
            moved
            status
            user {
                id
                uuid
                first_name
                last_name
            }
        }
    }
`;

export const sendNewReaction = gql`
    mutation ($conversation_id: Int!, $message_id: Int!, $user_id: String, $action: String, $status: Int!) {
        createReaction(
            conversation_id: $conversation_id
            message_id: $message_id
            user_id: $user_id
            action: $action
            status: $status
        ) {
            id
            status
        }
    }
`;

export const reactionUpdated = gql`
    subscription ($conversationId: Int!) {
        reactionUpdated(conversationId: $conversationId) {
            reaction {
                id
                action
                user_id
                conversation_id
                message_id
                status
            }
        }
    }
`;

export const updateReaction = gql`
    mutation ($id: Int!, $conversation_id: Int!, $message_id: Int!, $user_id: String, $status: Int!) {
        updateReaction(
            id: $id
            conversation_id: $conversation_id
            message_id: $message_id
            user_id: $user_id
            status: $status
        ) {
            status
        }
    }
`;

export const deleteReaction = gql`
    mutation ($id: Int!) {
        deleteReaction(id: $id)
    }
`;

export const attachmentCreated = gql`
    subscription ($conversationId: Int!) {
        attachmentCreated(conversationId: $conversationId) {
            attachment {
                id
                conversation_id
                message_id
                type
                name
                title
                parent_message_id
                parrent_conversation_id
                filetype
                size
                url
                thumb_360
                thumb_80
                status
            }
        }
    }
`;

export const createConversation = gql`
    mutation (
        $is_channel: Boolean!
        $is_group: Boolean!
        $is_dm: Boolean!
        $creator: String!
        $topic: String
        $is_archived: Boolean
        $is_private: Boolean
        $is_read_only: Boolean
        $conversation_members: [String]
        $permissions: String
        $order: Int
        $description: String
    ) {
        createConversation(
            is_channel: $is_channel
            is_group: $is_group
            is_dm: $is_dm
            creator: $creator
            topic: $topic
            is_archived: $is_archived
            is_private: $is_private
            is_read_only: $is_read_only
            conversation_members: $conversation_members
            permissions: $permissions
            order: $order
            description: $description
        ) {
            id
            status
            is_dm
            is_channel
            is_group
            is_read_only
            parent_id
            conversation_members {
                user_id
                user {
                    id
                    uuid
                    first_name
                    last_name
                }
            }
            event_type
        }
    }
`;

export const deleteConversation = gql`
    mutation ($id: Int!) {
        deleteConversation(id: $id)
    }
`;

export const conversationMembers = gql`
    query ($conversation_id: Int!) {
        conversation_members(conversation_id: $conversation_id) {
            id
            conversation_id
            user_id
            user {
                id
                uuid
                first_name
                last_name
                email
                status
                ts
                updated_ts
            }
        }
    }
`;

export const sendNewAttachment = gql`
    mutation (
        $conversation_id: Int!
        $message_id: Int!
        $type: String
        $name: String!
        $title: String
        $parent_message_id: Int
        $parrent_conversation_id: Int
        $mimetype: String!
        $filetype: String!
        $size: Int!
        $url: String!
        $thumb_64: String
        $thumb_80: String
        $thumb_360: String
    ) {
        createAttachment(
            conversation_id: $conversation_id
            message_id: $message_id
            type: $type
            name: $name
            title: $title
            parent_message_id: $parent_message_id
            parrent_conversation_id: $parrent_conversation_id
            mimetype: $mimetype
            filetype: $filetype
            size: $size
            url: $url
            thumb_64: $thumb_64
            thumb_80: $thumb_80
            thumb_360: $thumb_360
        ) {
            id
            conversation_id
            message_id
            type
            name
            title
            parent_message_id
            mimetype
            filetype
            size
            url
            status
            ts
        }
    }
`;

export const getLinksMetadata = gql`
    query ($targetUrls: [String!]) {
        urlsMetadata(targetUrls: $targetUrls) {
            data {
                ogUrl
                ogTitle
                ogImage {
                    url
                    width
                    height
                    type
                }
                ogLocale
                ogDescription
                charset
                requestUrl
                success
            }
        }
    }
`;

export const deleteMessage = gql`
    mutation ($id: Int!) {
        deleteMessage(id: $id) {
            id
            status
        }
    }
`;

export const createSavedItem = gql`
    mutation ($conversation_id: Int!, $message_id: Int!, $user_id: String, $status: Int) {
        createSavedItem(
            conversation_id: $conversation_id
            message_id: $message_id
            user_id: $user_id
            status: $status
        ) {
            status
            id
            message_id
            conversation_id
            user_id
        }
    }
`;

export const updateSavedItem = gql`
    mutation ($id: Int!, $conversation_id: Int!, $message_id: Int!, $user_id: String, $status: Int) {
        updateSavedItem(
            id: $id
            conversation_id: $conversation_id
            message_id: $message_id
            user_id: $user_id
            status: $status
        ) {
            status
        }
    }
`;

export const updateSavedItemStatus = gql`
    mutation ($message_id: Int!, $user_id: String, $status: Int) {
        updateSavedItemStatus(message_id: $message_id, user_id: $user_id, status: $status) {
            status
        }
    }
`;

export const deleteSavedItem = gql`
    mutation ($id: Int!) {
        deleteSavedItem(id: $id) {
            status
        }
    }
`;

export const getSavedItems = gql`
    query ($user_id: String!) {
        messageSavedItems(user_id: $user_id) {
            id
            user_id
            conversation_id
            text
            is_archived
            is_private
            parent_id
            status
            ts
            user_ids
            replies
            is_saved
            pinned
            user {
                id
                uuid
                first_name
                last_name
                email
                status
                ts
                picture
            }
            reactions {
                id
                conversation_id
                message_id
                user_id
                action
                status
            }
            tagged {
                id
                user_id
                status
                tagged_user {
                    id
                    uuid
                    email

                    first_name
                    last_name
                }
            }
            attachments {
                id
                conversation_id
                message_id
                type
                name
                title
                parent_message_id
                parrent_conversation_id
                filetype
                size
                url
                thumb_360
                thumb_80
                status
                ts
            }
            event_type
        }
    }
`;

export const searchMessages = gql`
    query ($user_id: String, $conversation_ids: [Int]!, $search: String!, $page: Int!, $size: Int!) {
        searchMessages(
            user_id: $user_id
            conversation_ids: $conversation_ids
            search: $search
            page: $page
            size: $size
        ) {
            count
            rows {
                edited
                id
                user_id
                conversation_id
                parent_id
                type
                text
                ts
                status
                replies
                user_ids
                is_private
                pinned
                moved
                transfers {
                    id
                    conversation_id
                    message_id
                    parent_id
                    ts
                }
                user {
                    id
                    uuid
                    first_name
                    last_name
                    email
                    status
                    ts
                    city
                    state
                    country
                    company
                    website
                    marketing
                    practice_type
                    office_email
                    picture
                }
                reactions {
                    id
                    conversation_id
                    message_id
                    user_id
                    action
                    status
                }
                tagged {
                    id
                    user_id
                    status
                    tagged_user {
                        id
                        uuid
                        email

                        first_name
                        last_name
                    }
                }
                attachments {
                    id
                    conversation_id
                    message_id
                    type
                    name
                    title
                    parent_message_id
                    parrent_conversation_id
                    filetype
                    size
                    url
                    thumb_360
                    thumb_80
                    status
                    ts
                }
            }
        }
    }
`;

export const getCurrentMessageViolations = gql`
    query ($user_id: String, $status: [Int]) {
        messageViolations(user_id: $user_id, status: $status) {
            id
            conversation_id
            user_id
            text
            is_archived
            is_private
            ts
            status
            is_saved
            replies
            parent_id
            pinned
            user {
                id
                uuid
                first_name
                last_name
                email
                status
                picture
            }
            reactions {
                id
                conversation_id
                message_id
                user_id
                action
                status
            }
            tagged {
                id
                user_id
                status
                tagged_user {
                    id
                    uuid
                    email
                    first_name
                    last_name
                }
            }
            attachments {
                id
                conversation_id
                message_id
                type
                name
                title
                parent_message_id
                parrent_conversation_id
                filetype
                size
                url
                thumb_360
                thumb_80
                status
                ts
            }
            message_violations {
                id
                message_id
                reviewed_by
                reported_by
                text
                violation_type
                comment
                status
                reported_user {
                    id
                    uuid
                    first_name
                    last_name
                    email
                    status
                    ts
                }
            }
        }
    }
`;

export const getMessageViolation = gql`
    query ($id: Int!) {
        message_violation(id: $id) {
            id
            message_id
            reviewed_by
            reported_by
            text
            comment
            violation_type
            status
        }
    }
`;

export const createMessageViolation = gql`
    mutation ($message_id: Int!, $reported_by: String!, $comment: String, $violation_type: String, $status: Int) {
        createMessageViolation(
            message_id: $message_id
            reported_by: $reported_by
            comment: $comment
            violation_type: $violation_type
            status: $status
        ) {
            id
            message_id
            reviewed_by
            reported_by
            text
            comment
            violation_type
            status
        }
    }
`;

export const updateMessageViolation = gql`
    mutation (
        $id: Int!
        $reviewed_by: String!
        $text: String
        $comment: String
        $violation_type: String
        $status: Int
    ) {
        updateMessageViolation(
            id: $id
            reviewed_by: $reviewed_by
            text: $text
            comment: $comment
            violation_type: $violation_type
            status: $status
        ) {
            id
            message_id
            reviewed_by
            reported_by
            text
            comment
            violation_type
            status
        }
    }
`;

export const updateMessageViolations = gql`
    mutation ($message_id: Int!, $reviewed_by: String!, $status: Int) {
        updateMessageViolations(message_id: $message_id, reviewed_by: $reviewed_by, status: $status) {
            message_id
            reviewed_by
            reported_by
            text
            comment
            violation_type
            status
            reported_user {
                uuid
                first_name
                last_name
                email
                status
                ts
            }
        }
    }
`;

export const deleteMessageViolation = gql`
    mutation ($id: Int!) {
        deleteMessageViolation(id: $id)
    }
`;

export const messagesUnread = gql`
    query ($user_id: String!, $page: Int!, $size: Int!) {
        messagesUnread(user_id: $user_id, page: $page, size: $size) {
            totalPages
            currentPage
            count
            rows {
                edited
                id
                user_id
                conversation_id
                parent_id
                type
                text
                ts
                status
                replies
                user_ids
                is_private
                pinned
                moved
                transfers {
                    id
                    conversation_id
                    message_id
                    parent_id
                    ts
                }
                user {
                    id
                    uuid
                    first_name
                    last_name
                    email
                    status
                    ts
                    city
                    state
                    country
                    company
                    website
                    marketing
                    practice_type
                    office_email
                    picture
                }
                reactions {
                    id
                    conversation_id
                    message_id
                    user_id
                    action
                    status
                }
                tagged {
                    id
                    user_id
                    status
                    tagged_user {
                        id
                        uuid
                        email
                        first_name
                        last_name
                    }
                }
                attachments {
                    id
                    conversation_id
                    message_id
                    type
                    name
                    title
                    parent_message_id
                    parrent_conversation_id
                    filetype
                    size
                    url
                    thumb_360
                    thumb_80
                    status
                    ts
                }
            }
        }
    }
`;
