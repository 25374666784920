import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { resetCommunity } from "./../../features/community/communitySlice";
import { toggleHideOptionalNav } from "./../../common/globalSlice/globalSlice";

import { styled } from "@mui/material/styles";
import { List, ListItemButton, Icon } from "@mui/material";

const NavLinkEl = styled(NavLink, { shouldForwardProp: (prop) => prop !== "selected" })(({ theme, selected }) => ({
    "&&": {
        display: "flex",
        margin: "0 0 8px",
        padding: theme.spacing(1, 2),
        overflow: "hidden",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(1.25, 3.75),
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: 6,
            padding: theme.spacing(1.25, 3),
        },
        [theme.breakpoints.up("xs")]: {
            padding: theme.spacing(0.75, 3),
        },
        ...(selected && {
            backgroundColor: theme.palette.common.white,
        }),
    },
}));

const ListItemElement = styled(ListItemButton, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    "&&": {
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        boxSizing: "border-box",
        padding: theme.spacing(0, 0.25),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            justifyContent: "flex-start",
        }),
        ...(!open && {
            justifyContent: "center",
        }),
    },
}));

const ListItemIcon = styled(Icon, { shouldForwardProp: (prop) => prop !== "open" && prop !== "selected" })(
    ({ theme, open, selected }) => ({
        "&&": {
            fontSize: "1.5rem",
            marginRight: 14,
            color: theme.palette.common.white,
            [theme.breakpoints.down("md")]: {
                fontSize: "1.8rem",
                marginRight: theme.spacing(1),
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "1.6rem",
            },
            ...(!open && {
                marginRight: theme.spacing(0),
            }),
            ...(selected && {
                color: theme.palette.common.blue_dark,
            }),
        },
    }),
);

const ListItemTitleContainer = styled("div", { shouldForwardProp: (prop) => prop !== "open" && prop !== "selected" })(
    ({ theme, open, selected }) => ({
        width: "100%",
        display: "flex",
        whiteSpace: "pre-wrap",
        color: theme.palette.common.white,
        position: "relative",
        ...(!open && {
            display: "none",
        }),
    }),
);

const ListItemTitle = styled("span", { shouldForwardProp: (prop) => prop !== "open" && prop !== "selected" })(
    ({ theme, open, selected }) => ({
        whiteSpace: "pre-wrap",
        color: theme.palette.common.white,
        fontSize: "16px",
        [theme.breakpoints.down("md")]: {
            fontSize: "14px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "13px",
        },
        ...(!open && {
            display: "none",
        }),
        ...(selected && {
            color: theme.palette.common.black,
        }),
    }),
);

export default function NavLinkElement(props) {
    const auth = useSelector((state) => state.auth);
    const globalState = useSelector((state) => state.global);
    const [routes, setRoutes] = useState([]);
    const history = useHistory();
    const dispatchState = useDispatch();
    const [selected, setSelected] = useState(``);
    const [isContentAdmin, setIsContentAdmin] = useState(
        parseInt(auth.user.access) === 5 && parseInt(auth.user.status) === 3 ? true : false,
    );

    const { user } = props;

    const checkRoute = (e) => {
        e.preventDefault();
        const linkPath = new URL(e.currentTarget.href).pathname;
        const linkPathEnd = linkPath.replace("/", "");
        const linkEndArr = window.location.pathname.split("/");
        const linkEnd = linkEndArr[linkEndArr.length - 1];
        if (linkEnd !== linkPathEnd) {
            //IS NOT ON SAME PAGE, CHANGE PAGE
            if (window.location.pathname.indexOf("/community") >= 0) {
                dispatchState(resetCommunity());
            } else {
                if (e?.currentTarget?.href && e.currentTarget.href.replace(/[^-]/g, "").length <= 3) {
                    dispatchState(toggleHideOptionalNav(true));
                }
            }
            history.push(linkPath);
        }
        return "";
    };

    useEffect(() => {
        for (let v in props.view) {
            const view = props.view[v];
            if (view === true) {
                if (v === "practitioner") {
                    setRoutes(props.routes.practRoutes);
                } else if (v === "admin") {
                    setRoutes(props.routes.adminRoutes);
                } else if (v === "sales") {
                    setRoutes(props.routes.salesRoutes);
                } else if (v === "patient") {
                    setRoutes(props.routes.patientRoutes);
                } else {
                    //DEFAULT ROUTES
                    setRoutes(props.routes.practRoutes);
                }
            }
        }
    }, [props.view]);

    useEffect(() => {
        setSelected(globalState.nav.secondaryNavBar.title);
    }, [globalState.nav.secondaryNavBar]);

    const checkSelected = (title) => {
        if (title && selected && title.toLowerCase() === selected.toLowerCase()) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        const userIsContentAdmin =
            user["https://cellcore.com/access"] === "5" && user["https://cellcore.com/status"] === "3" ? true : false;
        setIsContentAdmin(userIsContentAdmin);
    }, []);

    const checkAccessLevel = (route) => {
        //CHECKS IF ADMIN LEVEL ACCESS IS REQUIRED TO SEE A SECTION
        if (route.noContentAdmin === true && isContentAdmin) {
            return true;
        } else if (route.noContentAdmin === false || !Object.keys(route).includes("noContentAdmin")) {
            return false;
        }
        return false;
    };

    return (
        <List onClick={props.navCheckState}>
            {routes.map((route, index) => {
                let path = route.path;
                if (route.main_menu && checkAccessLevel(route) === false) {
                    return (
                        <NavLinkEl
                            selected={checkSelected(route.menu_title)}
                            key={index}
                            onClick={checkRoute}
                            to={path}
                        >
                            <ListItemElement disableRipple open={props.open}>
                                {route.menu_icon.indexOf("zmdi") === -1 && (
                                    <ListItemIcon selected={checkSelected(route.menu_title)} open={props.open}>
                                        {route.menu_icon}
                                    </ListItemIcon>
                                )}
                                <ListItemTitleContainer open={props.open} selected={checkSelected(route.menu_title)}>
                                    <ListItemTitle open={props.open} selected={checkSelected(route.menu_title)}>
                                        {route.menu_title}
                                    </ListItemTitle>
                                    {/* {checkSelected(route.menu_title) && secondaryMenuExists && (
                                        <ArrowIcon secondNavState={props.secondNavState.open}></ArrowIcon>
                                    )} */}
                                </ListItemTitleContainer>
                            </ListItemElement>
                        </NavLinkEl>
                    );
                }
                return null;
            })}
        </List>
    );
}
