import React from "react";
import ReactDOM from "react-dom";
// import ReactGA from "react-ga";
import App from "./app/App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
// import { Auth0Provider } from "@auth0/auth0-react";
// import { useHistory } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";

import { ApolloClient, ApolloLink, HttpLink, split, InMemoryCache, ApolloProvider } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/link-ws";
import LoginLoading from "./app/LoginLoading";

// Font Loading
import "./index.css";

//THIS GA CODE IS FOR STAGING ENVIRON ONLY
// const TRACKING_ID = "UA-106059202-6"; //Goolge Analytics Tracking Id
// ReactGA.initialize(TRACKING_ID, {
//     debug: true,
// });

const httpLink = new HttpLink({
    uri: "https://cellcore-community.azurewebsites.net/graphql",
});

const communityAuthLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("token");
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            token: `Bearer ${token}`,
        },
    };
});

const wsLink = new WebSocketLink({
    uri: `wss://cellcore-community.azurewebsites.net/graphql`,
    options: {
        reconnect: true,
    },
});

const terminatingLink = split(
    ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    communityAuthLink.concat(httpLink),
);

const link = ApolloLink.from([terminatingLink]);

const cache = new InMemoryCache(false);

const defaultOptions = {
    watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
    },
    query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    },
};

const client = new ApolloClient({
    link,
    cache,
    defaultOptions,
});

// const Auth0ProviderWithHistory = ({ children }) => {
//     const history = useHistory();

//     const onRedirectCallback = (appState) => {
//         history.push(appState?.returnTo || window.location.pathname);
//     };
//     const renderProvider = () => {
//         if (process.env.REACT_APP_ENVIRONMENT === "development") {
//             return (
//                 //LEVING DEV PROVIDER IN FOR WHEN NEEDED.
//                 <Auth0Provider
//                     domain="dev-09bex96s.us.auth0.com"
//                     clientId="5xagxL4eK1rj9mZQItnOPYPTMPTYLX0I"
//                     redirectUri={window.location.origin}
//                     onRedirectCallback={onRedirectCallback}
//                 >
//                     {children}
//                 </Auth0Provider>
//             );
//         } else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
//             return (
//                 <Auth0Provider
//                     domain="dev-09bex96s.us.auth0.com"
//                     clientId="5xagxL4eK1rj9mZQItnOPYPTMPTYLX0I"
//                     redirectUri={window.location.origin}
//                     onRedirectCallback={onRedirectCallback}
//                 >
//                     {children}
//                 </Auth0Provider>
//             );
//         } else if (process.env.REACT_APP_ENVIRONMENT === "production") {
//             return (
//                 <Auth0Provider
//                     domain="secure.cellcore.com"
//                     clientId="vofeu9bxW626OThh9xJHMnBTh5Br0aq0"
//                     redirectUri={window.location.origin}
//                     onRedirectCallback={onRedirectCallback}
//                 >
//                     {children}
//                 </Auth0Provider>
//             );
//         } else if (process.env.REACT_APP_ENVIRONMENT === "review") {
//             return (
//                 <Auth0Provider
//                     domain="secure.cellcore.com"
//                     clientId="vofeu9bxW626OThh9xJHMnBTh5Br0aq0"
//                     redirectUri={window.location.origin}
//                     onRedirectCallback={onRedirectCallback}
//                 >
//                     {children}
//                 </Auth0Provider>
//             );
//             // return (
//             //     <Auth0Provider
//             //         domain="dev-09bex96s.us.auth0.com"
//             //         clientId="5xagxL4eK1rj9mZQItnOPYPTMPTYLX0I"
//             //         redirectUri={window.location.origin}
//             //         onRedirectCallback={onRedirectCallback}
//             //     >
//             //         {children}
//             //     </Auth0Provider>
//             // );
//         } else {
//             return (
//                 <Auth0Provider
//                     domain="secure.cellcore.com"
//                     clientId="vofeu9bxW626OThh9xJHMnBTh5Br0aq0"
//                     redirectUri={window.location.origin}
//                     onRedirectCallback={onRedirectCallback}
//                 >
//                     {children}
//                 </Auth0Provider>
//             );
//         }
//     };

//     return renderProvider();
// };

ReactDOM.render(
    <Router>
        <Provider store={store}>
            {/* <Auth0ProviderWithHistory> */}
            <ApolloProvider client={client}>
                <Route path="/" component={LoginLoading} />
                <App />
            </ApolloProvider>
            {/* </Auth0ProviderWithHistory> */}
        </Provider>
    </Router>,

    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
