import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import learningAdmin from "../features/learning-center/admin/learningAdminSlice";
import toolsAdminReducer from "../features/tools/admin/toolsAdminSlice";
import resourceAdminReducer from "../features/resources/admin/resourceAdminSlice";
import researchAdminReducer from "../features/research/admin/researchAdminSlice";
import leadsReducer from "../features/leads/leadsSlice";
import practitionersReducer from "../features/practitioners/practitionersSlice";
import blogAdminReducer from "../features/blogs/admin/blogAdminSlice";
import communityReducer from "../features/community/communitySlice";
import accountNotesReducer from "../common/AccountNotes/accountNotesSlice";
import globalReducer from "../common/globalSlice/globalSlice";
import toolsReducer from "../common/toolsSlice/toolsSlice";
import salesReportReducer from "../features/sales-reports/components/salesReportSlice";
import customerReducer from "../features/customers/customerProfile/components/CustomerSlice";
import learningReducer from "../features/learning-center/components/LearningSlice";
import routeReducer from "../common/utils/RouteSlice";

// import { Auth0Client } from "@auth0/auth0-spa-js";

export const store = configureStore({
    reducer: {
        // Add each new reducer as a new key/value pair
        auth: authReducer,
        adminLearning: learningAdmin,
        assessmentAdmin: toolsAdminReducer,
        resourceAdmin: resourceAdminReducer,
        researchAdmin: researchAdminReducer,
        leads: leadsReducer,
        practitioners: practitionersReducer,
        blogAdmin: blogAdminReducer,
        community: communityReducer,
        accountNotes: accountNotesReducer,
        global: globalReducer,
        tools: toolsReducer,
        salesReports: salesReportReducer,
        practitionersCustomers: customerReducer,
        learningCenterData: learningReducer,
        routeData: routeReducer,
    },
});
