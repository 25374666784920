import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import dateConverter from "../../common/utils/dateConverter";
import { Card, CardContent, CardActions, CardMedia, Typography } from "@mui/material";
import cellcoreLogo from "../../assets/imgs/C_Logo.jpg";
import { Link } from "react-router-dom";
import theme from "../../theme/CustomTheme";

const CustomTimeStamp = styled(Typography)(({ theme }) => ({
    color: "#c8c8c8",
    marginBottom: "10px",

    a: {
        color: "#c8c8c8",
        "&:hover": {
            color: theme.palette.common.blue_light,
        },
    },
}));

const CustomTitle = styled("a")(({ theme }) => ({
    color: "#000000",
    "&:hover": {
        color: theme.palette.common.blue_light,
    },
}));

const CustomLink = styled(Link)(({ theme }) => ({
    color: "#000000",
    "&:hover": {
        color: theme.palette.common.blue_light,
    },
}));

const CustomLinkText = styled("a")(({ theme }) => ({
    color: theme.palette.common.white,
    marginTop: theme.spacing(1.5),
    display: "block",
}));

const CustomCardActions = styled(CardActions)(({ theme }) => ({
    width: "100%",
    bottom: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "end",
    borderRadius: "7px",
    background: theme.palette.common.blue_light,
    "&:hover": {
        background: theme.palette.common.blue_light,
        color: theme.palette.common.white,
        "& $link": {
            color: theme.palette.common.white,
        },
    },
}));

const CustomResearchLink = styled("a")(({ theme }) => ({
    color: "grey",
    margin: "0 0 0 5px",
    "&:hover": {
        color: theme.palette.common.blue_light,
        cursor: "pointer",
    },
}));

const CustomArticleLink = styled(Link)(({ theme }) => ({
    color: "grey",
    margin: "0 0 0 5px",
    "&:hover": {
        color: theme.palette.common.blue_light,
        cursor: "pointer",
    },
}));

const CustomHr = styled("hr")({
    backgroundImage: "linear-gradient(to left, transparent, #ccc, transparent)",
    height: "1px",
    border: "none",
});

const CustomSelectedTag = styled("div")(({ theme }) => ({
    color: theme.palette.common.blue_light,
    border: `2px solid ${theme.palette.common.blue_light}`,
    padding: "5px",
    fontSize: "12px",
    display: "inline-block",
    backgroundColor: theme.palette.common.white,
    margin: "10px 5px 0 0",
}));

const CustomTag = styled("div")(({ theme }) => ({
    color: "#c8c8c8",
    border: "2px solid #c8c8c8",
    padding: "5px",
    fontSize: "12px",
    display: "inline-block",
    backgroundColor: theme.palette.common.white,
    margin: "10px 5px 0 0",
}));

const IFrameDiv = styled("div")({
    height: "25vh",

    [theme.breakpoints.up("lg")]: {
        width: "100%",
        height: "30vh",
    },
});

const CustomIframe = styled("iframe")({
    backgroundColor: "rgba(0,0,0,0)",
    width: "fit-content ",
    height: "100%",
    border: "none",

    [theme.breakpoints.up("lg")]: {
        width: "100%",
    },
});

//default image if image is broken
const changePicture = (img) => {
    img.target.src = cellcoreLogo;
};

const CardComponent = (props) => {
    const [desc, setDesc] = useState();

    const formattedDesc = () => {
        let new_desc = props.description.split("|");

        let new_display = new_desc.map((line) => {
            return line;
        });

        return new_display;
    };

    useEffect(() => {
        setDesc(formattedDesc());
    }, []);

    let video = `https://player.vimeo.com/video/${props.video_id}`;

    let props_ts = dateConverter(props.ts);

    //card_style
    const CustomCard = styled(Card, { shouldForwardProp: (prop) => prop !== "type" })(({ theme, type }) => ({
        "&&": {
            overflow: "hidden",
            ...(type === "resources" && {
                boxShadow: "0 3px 5px rgb(0 0 0 / 0.2)",
                margin: theme.spacing(1),
                borderRadius: "0px 0px 7px 7px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
            }),
            ...(type === "article" && {
                boxShadow: "none",
                margin: theme.spacing(1),
                backgroundColor: "rgb(255, 0, 0, 0)",
                display: "flex",
                [theme.breakpoints.down("lg")]: {
                    flexDirection: "column",
                    margin: theme.spacing(1, 0),
                },
                [theme.breakpoints.down("md")]: {
                    width: "100%",
                },
            }),
            ...(type === "live-qa" && {
                boxShadow: "none",
                margin: theme.spacing(1, 0, 0),
                backgroundColor: "rgb(255, 0, 0, 0)",
                display: "flex",
                [theme.breakpoints.down("lg")]: {
                    flexDirection: "column",
                },
                [theme.breakpoints.down("md")]: {
                    width: "100%",
                },
            }),
            ...(type === "research" && {
                width: "80%",
                boxShadow: "none",
                margin: theme.spacing(1),
            }),
        },
    }));

    //card_content
    const CustomCardContent = styled(CardContent, { shouldForwardProp: (prop) => prop !== "type" })(
        ({ theme, type }) => ({
            "&&": {
                width: "100%",
                height: "100%",
                ...(type === "resources" && {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                }),
                ...(type === "article" && {
                    display: "block",
                    backgroundColor: "white",
                    marginLeft: theme.spacing(1),
                    height: "fit-content",
                    borderRadius: 5,
                    boxShadow:
                        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                    [theme.breakpoints.down("lg")]: {
                        marginLeft: 0,
                        borderRadius: 0,
                    },
                }),
                ...(type === "live-qa" && {
                    display: "block",
                    backgroundColor: "white",
                    marginLeft: theme.spacing(1),
                    width: "50%",
                    height: "fit-content",
                    borderRadius: 5,
                    boxShadow:
                        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                    [theme.breakpoints.down("lg")]: {
                        width: "100%",
                        marginLeft: 0,
                    },
                }),
                ...(type === "research" && {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }),
            },
        }),
    );

    const CustomCardMediaLink = styled("a", { shouldForwardProp: (prop) => prop !== "type" })(({ theme, type }) => ({
        "&&": {
            display: "block",
        },
    }));

    //card_image
    const CustomCardMedia = styled(CardMedia, { shouldForwardProp: (prop) => prop !== "type" })(({ theme, type }) => ({
        "&&": {
            ...(type === "resources" && {
                width: "100%",
            }),
            ...(type === "article" && {
                minWidth: "20vw",
                width: "20vw",
                maxHeight: "15vw",
                [theme.breakpoints.down("lg")]: {
                    width: "100%",
                    maxHeight: "100%",
                },
            }),
            ...(type === "live-qa" && {
                minWidth: "30vw",
                width: "30vw",
                maxHeight: "15vw",
                [theme.breakpoints.down("md")]: {
                    width: "100%",
                },
            }),
        },
    }));

    const CustomCardLinkContainer = styled("div", { shouldForwardProp: (prop) => prop !== "type" })(
        ({ theme, type }) => ({
            "&&": {
                width: "100%",
                marginTop: "auto",
            },
        }),
    );

    const makeTagArray = () => {
        let props_tags_arr = props.tags.split(",");
        let props_tag_box = props.tag_box;

        let prop_tags = props_tags_arr.map((element, index) =>
            props_tag_box.includes(element) ? (
                <CustomSelectedTag key={index} data-tag={element}>
                    {element}
                </CustomSelectedTag>
            ) : (
                <CustomTag key={index} data-tag={element}>
                    {element}
                </CustomTag>
            ),
        );

        return prop_tags;
    };

    return (
        <CustomCard type={props.type}>
            {props.type !== "research" &&
                (props.type === "live-qa" ? (
                    <IFrameDiv>
                        <CustomIframe title={props.title} src={video} />
                    </IFrameDiv>
                ) : props.url ? (
                    <CustomCardMediaLink
                        href={props.url}
                        target={props.type !== "article" ? `_blank` : ``}
                        rel="noreferrer"
                        type={props.type}
                    >
                        <CustomCardMedia
                            component="img"
                            image={
                                props.img ||
                                "https://res.cloudinary.com/microbe/image/upload/f_auto/cellcore/cellcore-mark.png"
                            }
                            alt={props.title}
                            loading="lazy"
                            onError={changePicture}
                        />
                    </CustomCardMediaLink>
                ) : (
                    <CustomCardMedia
                        component="img"
                        loading="lazy"
                        image={
                            props.img ||
                            "https://res.cloudinary.com/microbe/image/upload/f_auto/cellcore/cellcore-mark.png"
                        }
                        alt={props.title}
                        onError={changePicture}
                        type={props.type}
                    />
                ))}
            <CustomCardContent type={props.type}>
                {props.type === "article" ? (
                    <Typography variant="h5" component="div">
                        <CustomLink to={props.url?.length > 0 ? props.url : `/learningcenter/articles/${props.id}`}>
                            {props.title}
                        </CustomLink>
                    </Typography>
                ) : props.type === "live-qa" ? (
                    <Typography variant="h5" component="div">
                        <CustomLink to={`/learningcenter/liveqa/${props.id}`}>{props.title}</CustomLink>
                    </Typography>
                ) : (
                    <Typography variant="h5" component="div">
                        <CustomTitle href={props.url} target="_blank" rel="noreferrer">
                            {props.title}
                        </CustomTitle>
                    </Typography>
                )}
                <CustomTimeStamp variant="body2">
                    {props.type === "research" ? (
                        <>
                            {props_ts} /{" "}
                            <a href={props.url} target="_blank" rel="noreferrer">
                                <u>Source</u>
                            </a>
                        </>
                    ) : (
                        props_ts
                    )}
                </CustomTimeStamp>
                {props.type === "resources" ? (
                    <CustomCardLinkContainer>
                        <Typography variant="body2">{props.description}</Typography>
                        <CustomLinkText href={props.url} target="_blank" rel="noreferrer">
                            <CustomCardActions>{props.link_text}</CustomCardActions>
                        </CustomLinkText>
                    </CustomCardLinkContainer>
                ) : (
                    <Typography variant="body1">
                        {props.type === "article" ? (
                            <>
                                {props.description}{" "}
                                <CustomArticleLink
                                    to={props.url?.length > 0 ? props.url : `/learningcenter/articles/${props.id}`}
                                >
                                    <u>Read More...</u>
                                </CustomArticleLink>
                            </>
                        ) : props.type === "live-qa" || props.type === "webinars" || props.type === "additional" ? (
                            desc ? (
                                desc[0].length < 240 ? (
                                    desc[0]
                                ) : (
                                    desc[0].slice(0, 239).concat("...")
                                )
                            ) : (
                                ""
                            )
                        ) : (
                            <>
                                {props.description}{" "}
                                <CustomResearchLink href={props.url} target="_blank" rel="noreferrer">
                                    <u>Read More...</u>
                                </CustomResearchLink>
                            </>
                        )}
                    </Typography>
                )}
                {props.tags && props.tags.length > 0 && <div>{makeTagArray()}</div>}
            </CustomCardContent>

            {props.type === "research" && <CustomHr />}
        </CustomCard>
    );
};

export default CardComponent;
